import { t } from 'i18next';
import { FaRegUser } from 'react-icons/fa';
import { FaDollarSign } from 'react-icons/fa6';
import { Trans } from 'react-i18next';
import { PiNewspaperClipping } from 'react-icons/pi';
import { Skeleton } from '@mui/material';
import { Card } from '../../../../components/Reports/Card';
import { InformationBubble } from '../../../../components/Reports/InformationBubble';
import styles from './styles.module.scss';
import { DebtBoxes } from '../DebtBoxes';
import { ContributorBaseData } from '../../../../@types/activeDebt/reports';
import reportStyles from '../../reports.module.scss';

type GeneralInfoProps = {
  data: ContributorBaseData;
};

export function GeneralInfo(props: GeneralInfoProps) {
  document.body.classList.remove('darkModeTheme');

  const debtIcon = () => {
    return (
      <div className={styles.iconDebt}>
        <FaDollarSign />
      </div>
    );
  };

  return (
    !props.data ? <Skeleton height={400}/>
      : (
        <div className={styles.container}>
          <InformationBubble statement={true} contributorName={props.data.name} totalBalance={props.data.total_balance}/>
          <div className={styles.cards}>
            <Card
              iconTitle={<FaRegUser />}
              largeClass={reportStyles.col6}
              title={t('reports.fullStatement.contributorData.title')}
            >
              <div className={styles.cardContent}>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.name'
                    components={{ b: <b /> }}
                    values={{
                      contributorName: props.data.name,
                    }}
                  />
                </span>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.document'
                    components={{ b: <b /> }}
                    values={{
                      document: props.data.document,
                    }}
                  />
                </span>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.address'
                    components={{ b: <b /> }}
                    values={{
                      address: props.data.address,
                    }}
                  />
                </span>
              </div>
            </Card>
            <Card
              iconTitle={debtIcon()}
              largeClass={reportStyles.col6}
              title={t('reports.fullStatement.activeDebtInfo.title')}
            >
              <div className={styles.cardContent} data-cy="contributor-data">
                <span>
                  <Trans
                    i18nKey='reports.fullStatement.activeDebtInfo.content'
                    components={{ b: <b /> }}
                  />
                </span>
                <span className={styles.subContent}>
                  <Trans
                    i18nKey='reports.fullStatement.activeDebtInfo.subContent'
                    components={{ b: <b /> }}
                  />
                </span>
              </div>
            </Card>
          </div>
          <div className={styles.mainCard}>
            <Card
              iconTitle={<PiNewspaperClipping />}
              largeClass={reportStyles.col12}
              title={t('reports.fullStatement.activeDebtInfo.myDebt')}
            >
              <div className={styles.cardContent} data-cy="acitve-debt-info">
                <div className={styles.receiptPills}>
                  {props.data.receipt_types.map((rt: any) => {
                    return (
                      <span data-cy="receipt-pills">{rt.description}</span>
                    );
                  })}
                </div>
                <DebtBoxes
                  totalBalance={props.data.total_balance}
                  totalCDAs={props.data.inscriptions_count}
                  openInscriptions={props.data.active_inscriptions}
                  negotiationInscriptions={props.data.negotiated_inscriptions}
                />
              </div>
            </Card>
          </div>
        </div>
      )
  );
}
