import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Grid, Skeleton } from '@mui/material';
import { FaCircle } from 'react-icons/fa';
import { t } from 'i18next';
import { Contributor } from '../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import Format from '../../../helpers/format';
import Show from '../../../components/Show';

type CdasExtractType = {
  contributor?: Contributor;
  loading?: boolean;
  showExtincts?: boolean;
};

export function CdasExtract(props: CdasExtractType) {
  const loadData = async () => {
    if (props.contributor?.inscriptions_ids) {
      const result = await DebtBoxService.getCdasExtractByInscriptions(props.contributor.inscriptions_ids);
      return result;
    }
    return null;
  };

  const {
    refetch, data, isFetching,
  } = useQuery({
    queryKey: ['cdasExtract'],
    queryFn: loadData,
    enabled: false,
  });

  useEffect(() => {
    if (props.contributor?.inscriptions_ids) {
      refetch();
    }
  }, [props.contributor, refetch]);

  const renderSumValues = (type: 'active' | 'extinct') => {
    const numbers = type === 'active'
      ? [data?.total_opened, data?.total_in_negotiation, data?.total_overdue]
      : [data?.total_settled, data?.total_canceled, data?.total_prescribed, data?.total_redeemed];
    const total = Format.sumArray(numbers);
    return Format.currency(total);
  };

  return (
    <div
      className={styles.container}
    >
      {isFetching
        ? <Skeleton height={250}/>
        : !data
          ? <span className={styles.noContent}>{t('debtBox.results.cdasExtract.noResult')}</span>
          : (<Grid
            container
            columns={{ xs: 1, sm: 12, md: 12 }}
            className={styles.content}
            >
              <Grid
                item xs={1} sm={12} md={props.showExtincts ? 6 : 12}
              >
                <div className={styles.cdasContainer}>
                  <div className={styles.header}>
                    <span className={styles.qtyCdas}>{data.total_actives} CDAs ativas</span>
                    <span className={styles.summary}>{t('debtBox.results.cdasExtract.active.total')}</span>
                    <span className={styles.total}>{Format.currency(data.total_opened_balance)}</span>
                  </div>

                  <div className={styles.details}>
                    <div className={styles.data}>
                      <span className={styles.label}>
                        <FaCircle className={styles.opened}/>
                        {t('debtBox.results.cdasExtract.active.opened')}
                      </span>
                      <span className={styles.value}>{Format.currency(data.total_opened)}</span>
                    </div>
                    <div className={styles.data}>
                      <span className={styles.label}>
                        <FaCircle className={styles.negotiation}/>
                        {t('debtBox.results.cdasExtract.active.negotiation')}
                      </span>
                      <span className={styles.value}>{Format.currency(data.total_in_negotiation)}</span>
                    </div>
                    <div className={styles.data}>
                      <span className={styles.label}>
                        <FaCircle className={styles.overdue}/>
                        {t('debtBox.results.cdasExtract.active.overdue')}
                      </span>
                      <span className={styles.value}>{Format.currency(data.total_overdue)}</span>
                    </div>
                    <div className={styles.data}>
                      <span className={styles.label}>
                        <FaCircle className={styles.lost}/>
                        {t('debtBox.results.cdasExtract.active.lost')}
                      </span>
                      <span className={styles.value}>{Format.currency(data.total_lost)}</span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Show if={!!props.showExtincts}>
                <Grid
                  item xs={1} sm={12} md={6}
                >
                  <div className={styles.cdasContainer}>
                    <div className={styles.header}>
                      <span className={styles.qtyCdas}>{data.total_extinct} CDAs extintas</span>
                      <span className={styles.summary}>{t('debtBox.results.cdasExtract.extinct.total')}</span>
                      <span className={styles.total}>{renderSumValues('extinct')}</span>
                    </div>

                    <div className={styles.details}>
                      <div className={styles.data}>
                        <span className={styles.label}>
                          <FaCircle />
                          {t('debtBox.results.cdasExtract.extinct.settled')}
                        </span>
                        <span className={styles.value}>{Format.currency(data.total_settled)}</span>
                      </div>
                      <div className={styles.data}>
                        <span className={styles.label}>
                          <FaCircle />
                          {t('debtBox.results.cdasExtract.extinct.canceled')}
                        </span>
                        <span className={styles.value}>{Format.currency(data.total_canceled)}</span>
                      </div>
                      <div className={styles.data}>
                        <span className={styles.label}>
                          <FaCircle />
                          {t('debtBox.results.cdasExtract.extinct.prescribed')}
                        </span>
                        <span className={styles.value}>{Format.currency(data.total_prescribed)}</span>
                      </div>
                      <div className={styles.data}>
                        <span className={styles.label}>
                          <FaCircle />
                          {t('debtBox.results.cdasExtract.extinct.redeemed')}
                        </span>
                        <span className={styles.value}>{Format.currency(data.total_redeemed)}</span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Show>
            </Grid>
          )}
    </div>
  );
}
