import { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.scss';
import { ButtonSize, ButtonType } from '../../@types/button';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon?: ReactNode;
  size?: ButtonSize;
  textCenter?: boolean;
  backgroundColor?: string;
  round?: boolean;
  shadow?: boolean;
  buttonType?: ButtonType;
  disabled?: boolean;
  className?: string;
  selected?: boolean;
  w100?: boolean;
  badge?: number;
  dataCy?: string;
}

export function Button(props: ButtonProps) {
  return (
    <button
      {...props}
      className={`${styles.container} ${props.className} ${props.w100 ? styles.w100 : ''}`}
      data-cy={props.dataCy}
    >
      <div
        style={ { backgroundColor: props.backgroundColor } }
        className={ `
          ${styles.button}
          ${props.size ? styles[props.size] : styles.main}
          ${props.textCenter ? styles.textCenter : ''}
          ${props.round ? styles.round : ''}
          ${props.buttonType === 'primary' ? styles.primary : ''}
          ${props.buttonType === 'default' ? styles.default : ''}
          ${props.buttonType === 'danger' ? styles.danger : ''}
          ${props.buttonType === 'accent' ? styles.accent : ''}
          ${props.disabled ? styles.disabled : ''}
          ${props.shadow ? styles.shadow : ''}
          ${props.selected ? styles.selected : ''}
          ${props.badge && styles.notification}`
        }
      >
        { props.icon }
        <span>{ props.title }</span>
        {props.badge && <span className={styles.badge}>{props.badge}</span>}
      </div>
    </button>
  );
}
