import Axios from '../config/apiAgilis';

export default class GeneralService {
  public static async changePassword(data: any) {
    return Axios.post('/api/registrations/change_password', data).then((res) => res);
  }

  public static async changePicture(data: any) {
    const formData = new FormData();
    formData.append('crop_x', '7');
    formData.append('crop_y', '7');
    formData.append('crop_w', '50');
    formData.append('crop_h', '50');
    formData.append('profile_picture', data);
    return Axios.post(
      '/api/registrations/profile_picture',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((res) => res.data);
  }

  public static async deletePicture() {
    return Axios.delete('/api/registrations/profile_picture').then((res) => res.data);
  }

  public static async getNotifications() {
    return Axios.get('/api/notifications').then((res) => res);
  }

  public static async getAllNotifications() {
    return Axios.get('/api/notifications/all').then((res) => res);
  }

  public static async updateNotifications(id?: number, read?: boolean) {
    return Axios.put('/api/notifications', { id, read }).then((res) => res);
  }

  public static async createTramitNotifications(procedure_ids?: number[], to_individual_id?: number) {
    return Axios.post('/api/notifications/tramit', { procedure_ids, to_individual_id }).then((res) => res);
  }

  public static async getDownloadDocument(documentId: number) {
    return Axios.get(`/api/shared/documents/${documentId}`)
      .then((res) => res.data);
  }
}
