import { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { FaTimes, FaRegQuestionCircle } from 'react-icons/fa';
import { format, parse, isWeekend } from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { PickersDayProps } from '@mui/lab';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { maskDate } from '../../helpers/masks';

type InputDateProps = {
  dataCy?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  tooltip?: string;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  fixedValue?: boolean;
  onChange: (value: string) => void;
  disabledHolidays?: boolean;
};

export function InputDate(props: InputDateProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(props.value || '');
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.fixedValue) {
      setValue(props.value || '');
    }
  }, [props.value]);

  const handleOutsideClick = (event: MouseEvent) => {
    const isOutsideComponent = !event.composedPath().some((el: any) => el === componentRef.current);
    const isOutsidePicker = !event.composedPath().some((el: any) => el.classList && el.classList.contains('MuiCalendarPicker-root'));
    if (isOutsidePicker && isOutsideComponent) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleOnChange = (newValue: string | null) => {
    if (props.fixedValue && props.value && newValue) {
      props.onChange(newValue);
      setValue(props.value);
      setOpen(false);
    } else if (newValue) {
      setValue(newValue);
      props.onChange(newValue);
      setOpen(false);
    } else {
      setValue('');
      props.onChange('');
    }
  };

  const returnDay = (day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>): JSX.Element => {
    const validation = isWeekend(day);

    const { onDaySelect, ...defaultPickersDayProps } = pickersDayProps;

    return (
      <PickersDay
        {...defaultPickersDayProps}
        outsideCurrentMonth={false}
        onDaySelect={validation && !!props.disabledHolidays ? () => { } : onDaySelect}
        className={validation ? styles.disabled : ''}
        day={day}
      />
    );
  };

  const renderInput = (inputRef: any, inputProps: any) => {
    const { placeholder, ...otherInputProps } = inputProps;

    return (
      <div className={styles.wrapper}>
        <input
          data-cy={props.dataCy}
          readOnly={props.readOnly}
          className={styles.input}
          placeholder={props.placeholder || placeholder}
          ref={inputRef}
          {...otherInputProps}
          onClick={() => {
            setOpen(true);
          }}
          required={props.required}
          value={value}
          onChange={(event) => {
            handleOnChange(maskDate(event.target.value));
          }}
        />
        <span hidden={Boolean(!value)} className={styles.icon} onClick={() => handleOnChange(null)}>
          <FaTimes />
        </span>
      </div>
    );
  };

  const renderPicker = () => {
    return (
      <DatePicker
        value={value ? parse(value, 'yyyy-MM-dd', new Date()) : null}
        open={open}
        toolbarTitle={false}
        showToolbar={false}
        renderDay={returnDay}
        disabled={props.disabled}
        onChange={(newValue) => {
          handleOnChange(newValue ? format(newValue, 'dd/MM/yyyy') : null);
        }}
        renderInput={({ inputRef, inputProps }) => (
          renderInput(inputRef, inputProps)
        )}
      />
    );
  };

  return (
    <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
      <Grid className={props.className} ref={componentRef}>
        <div className={styles.label}>
          {props.label && <Label text={props.label} bold required={props.required} />}
          {props.tooltip
            ? (
              <Tooltip title={ props.tooltip }>
                <div className={styles.containerTag}>
                  <FaRegQuestionCircle />
                </div>
              </ Tooltip>
            ) : null}
        </div>
        {renderPicker()}
      </Grid>
    </LocalizationProvider>
  );
}
