import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useRef } from 'react';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import GeneralService from '../../../services/generalService';
import { HomeContext } from '../../../context/homeContext';
import { Avatar } from '../../Avatar';
import { CurrentUser } from '../../../@types/currentUser';

type ChangePictureProps = {
  closeModal: () => void;
};

export function ChangePicture(props: ChangePictureProps) {
  const { addToasts } = useContext(HomeContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const file = useRef<HTMLInputElement | null>(null);

  const handleChangePicture = (e: any) => {
    const data: File = e.target.files[0];
    GeneralService.changePicture(data).then((res) => {
      localStorage.setItem('currentUser', JSON.stringify(res));
      addToasts({
        type: 'success',
        text: t('general.changePicture.changeSuccess'),
      });
      e.target.value = null;
    }).catch(() => {
      e.target.value = null;
      addToasts({
        type: 'error',
        text: t('general.changePicture.changeError'),
      });
    });
  };

  const handleRemovePicture = () => {
    GeneralService.deletePicture().then((res) => {
      localStorage.setItem('currentUser', JSON.stringify(res));
      addToasts({
        type: 'success',
        text: t('general.changePicture.deleteSuccess'),
      });
    }).catch(() => {
      addToasts({
        type: 'error',
        text: t('general.changePicture.deleteError'),
      });
    });
  };

  return (
    <div className={ styles.container }>
      <Grid
      container
      columns={{ xs: 4, sm: 8, md: 8 }}
      spacing={{ xs: 2, md: 2 }}
    >
      <Grid item xs={2} sm={4} md={4}>
      <div className={styles.actions}>
        <p className={styles.text}>{t('general.changePicture.text')}</p>
        <div className={styles.buttons}>
          <Button
            title={
              currentUser.profile_picture
                ? t('general.changePicture.change')
                : t('general.changePicture.add')
            }
            buttonType='primary'
            size='flat'
            className={styles.button}
            onClick={() => (file.current ? file.current.click() : {})}
            round
          />
          <input
            ref={file}
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={handleChangePicture}
          />
          <Button
            title={t('general.changePicture.remove')}
            buttonType='default'
            size='flat'
            className={ `${styles.button} ${currentUser.profile_picture ? '' : styles.visible}` }
            onClick={handleRemovePicture}
            round
          />
        </div>
      </div>
      </Grid>
      <Grid
        item
        xs={2}
        sm={4}
        md={4}
      >
        <div className={styles.avatar}>
          <Avatar
            name={ currentUser.name }
            color={currentUser.color}
            size='xlarge'
            profile_picture={currentUser.profile_picture}
            tooltip
          />
        </div>
      </Grid>
    </Grid>
    </div>
  );
}
