import Format from './format';

export function downloadFile(encodedPdf: string, fileName: string) {
  const blob = Format.base64ToBlob(encodedPdf, 'application/pdf');

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}

export function downloadBlobXls(file: Blob, fileName: string) {
  const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
}
