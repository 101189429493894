import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { FaInfo } from 'react-icons/fa';
import InscriptionService from '../../services/inscriptionService';
import styles from './styles.module.scss';
import { Modal } from '../Modal';
import Format from '../../helpers/format';
import { Table } from '../Table';

type ProtestInfoProps = {
  modalState: boolean;
  inscriptionsId: number;
  openProtestInfoModal: (e: any) => void;
};

export function ProtestInfo(props: ProtestInfoProps) {
  const fetchProtestInfo = async () => {
    if (props.inscriptionsId) {
      const result = await InscriptionService.getProtestInfo(props.inscriptionsId);
      return result;
    }
    return '';
  };

  const {
    data,
  } = useQuery({
    queryKey: [`fetchAddress${props.inscriptionsId}`],
    queryFn: fetchProtestInfo,
    enabled: true,
  });

  return (
    <Modal
      title={t('inscription.protestInfoModal.title')}
      open={props.modalState}
      onClose={(e: any) => props.openProtestInfoModal(e)}
      size='large'
      icon={<FaInfo />}
      key={props.inscriptionsId}
    >
      {data && (
        <Table className={styles.protestContainer} flat={true}>
          <thead>
            <tr>
              <th><span><strong>{t('inscription.protestInfoModal.registryName')}</strong></span></th>
              <th><span><strong>{t('inscription.protestInfoModal.address')}</strong></span></th>
              <th><span><strong>{t('inscription.protestInfoModal.phone')}</strong></span></th>
              <th><span><strong>{t('inscription.protestInfoModal.protocol')}</strong></span></th>
              <th><span><strong>{t('inscription.protestInfoModal.date')}</strong></span></th>
              <th><span><strong>{t('inscription.protestInfoModal.sendDate')}</strong></span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>{data.registry_name}</span></td>
              <td><span>{data.full_address}</span></td>
              <td><span>{data.phone}</span></td>
              <td><span>{data.protocol}</span></td>
              <td><span>{Format.formatResult(data.occurrence_date)}</span></td>
              <td><span>{Format.formatDate(data.sent_date)}</span></td>
            </tr>
          </tbody>
        </Table>
      )}
    </Modal>
  );
}
