import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaChevronDown,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import {
  CssBaseline,
  ThemeProvider,
  createFilterOptions,
  Tooltip,
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material/';
import styles from './styles.module.scss';
import { globalTheme } from './globalTheme';

type SelectFilterProps = {
  id?: string;
  options: OptionType[];
  placeholder: string;
  label?: string;
  tooltip?: string;
  handleSelectedItems?: (itens: number[]) => void;
};

export type OptionType = {
  value: any;
  label: string;
};

export function SelectFilter(props: SelectFilterProps) {
  const { t } = useTranslation();
  const idSelectAll = 'select-all';
  const [quantity, setQuantity] = useState<number>(1);
  const [checkSelected, setCheckSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const filter = createFilterOptions<OptionType>();
  const noOptions = quantity === 0;

  const handleSelected = () => {
    if (props.handleSelectedItems) {
      props.handleSelectedItems(selectedOptions.map((a) => a.value));
    }
  };

  useEffect(() => {
    handleSelected();
  }, [selectedOptions]);

  const handleToggleOption = (selectedOptionsTemp :OptionType[]) => {
    setSelectedOptions(selectedOptionsTemp);
  };

  const handleChange = (event :any, selectedOptionsTemp :OptionType[], reason :string, details :any) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (checkSelected) {
        let result = [];
        result = props.options.filter((el :any) => el.value !== details.option.value);
        handleToggleOption(result);
      } else {
        handleToggleOption(selectedOptionsTemp);
      }
      setCheckSelected(false);
    }
    if (reason === 'clear') {
      setSelectedOptions([]);
    }
  };

  const optionRenderer = (others :any, option :OptionType, { selected } :any) => {
    const optionSelectAll = option.value === idSelectAll;
    const disabled = optionSelectAll && quantity !== props.options.length;
    return (
      <>
        <li {...others} aria-disabled={disabled}>
          <Checkbox
            style={{
              padding: '2px',
              marginRight: '8px',
              color: 'var(--neutral5)',
            }}
            checked={checkSelected ? true : selected}
            disabled={disabled}
          />
          {option.label}
        </li>
        {
          noOptions
            ? <li className={styles.noOptions}>
                {t('general.noOptions')}
              </li>
            : null
        }
      </>
    );
  };
  return (
    <div className={styles.container}>
      <div className={ styles.info }>
        {props.label && <label>{props.label}</label>}
        {props.tooltip
          ? <Tooltip title={ props.tooltip }>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
            </ Tooltip>
          : null}
      </div>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Autocomplete
          popupIcon={<FaChevronDown className={styles.icon} />}
          isOptionEqualToValue={(option, value) => { return option.value === value.value; }}
          limitTags={2}
          multiple
          options={props.options}
          value={selectedOptions}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            setQuantity(filtered.length);
            return [...filtered];
          }}
          onChange={handleChange}
          getOptionLabel={(option) => option.label}
          renderOption={optionRenderer}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder || ''} />
          )}
        />
      </ThemeProvider>
    </div>
  );
}
