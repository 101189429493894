import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { Table } from '../../../components/Table';
import { CollapseHeader } from '../../../components/CollapseHeader';

type PendenciesProps = {
  id: number;
};

export function Pendencies(props: PendenciesProps) {
  const [open, setOpen] = useState(true);
  const loadData = async () => {
    if (props.id) {
      const result = await DebtBoxService.getPaymentRequestPendencies(props.id);
      return result;
    }
    return null;
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: ['paymentRequestPendencies'],
    queryFn: loadData,
  });

  useEffect(() => {
    if (open && !data) {
      refetch();
    }
  }, [open]);

  return (
    <div className={ styles.container }>
      <CollapseHeader
        open={open}
        onClick={() => setOpen(!open)}
        title={t('paymentRequest.pendencies.title')}
      />
      {open && (
        <>
          {(!data && (isLoading || isRefetching)) && (
            <Skeleton height={80} variant='rectangular' />
          )}
          {data && (
            <div className={styles.table}>
              <Table>
                <thead>
                  <tr>
                    <th>{t('paymentRequest.pendencies.number')}</th>
                    <th>{t('paymentRequest.pendencies.id')}</th>
                    <th>{t('paymentRequest.pendencies.total')}</th>
                    <th>{t('paymentRequest.pendencies.dueDate')}</th>
                    <th className={styles.pendency}>{t('paymentRequest.pendencies.type')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.result.map((billet: any) => (
                      <tr>
                        <td>{billet.installments_number_to_sentence_with_ranges}</td>
                        <td>{billet.formatted_number}</td>
                        <td>{billet.amount_to_pay}</td>
                        <td>{billet.installments_due_date}</td>
                        <td className={styles.pendency}>
                          {billet.pendency}
                          <FaExclamationTriangle />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
}
