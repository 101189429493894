import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styles from './styles.module.scss';
import { HomeContext } from '../../../context/homeContext';
import Format from '../../../helpers/format';
import { Contributor, ReceiptTypeSearch } from '../../../@types/activeDebt/debtBox';
import { maskCpfOrCnpj } from '../../../helpers/masks';
import { Tab } from '../../../components/Tab';
import { PaymentRequestGroup } from './PaymentRequestGroup';
import { Documents } from './Documents';
import Show from '../../../components/Show';
import DebtBoxService from '../../../services/debtBoxService';
import { NewPaymentRequest } from '../../../@types/activeDebt/paymentRequest';
import { Negotiation } from './Negotiation';
import { Billets } from './Billets';
import { SelectedInscriptions as SelectedInscriptionsType } from '../Footer';

type NewNegotiationProps = {
  receiptType?: ReceiptTypeSearch;
  contributor?: Contributor;
  reloadData: () => void;
  inscriptionsInfo: SelectedInscriptionsType[];
  loading?: boolean;
};

export function NewNegotiation(props: NewNegotiationProps) {
  const {
    setModal,
    addToasts,
    removeAllInscriptions,
    inscriptionsSelected,
  } = useContext(HomeContext);
  const [selectedTab, setSelectedTab] = useState<'simulation' | 'documents' | 'billets'>('simulation');
  const [installments, setInstallments] = useState<number | undefined>();
  const [inscriptions, setInscriptions] = useState<number[]>([]);

  const getInscriptionsArray = () => {
    return inscriptionsSelected.map((i) => i.id);
  };

  useEffect(() => {
    if (inscriptionsSelected.length > 0) {
      setInscriptions(getInscriptionsArray());
    }
  }, []);

  const loadData = async () => {
    if (props.contributor && props.receiptType && installments) {
      const data: NewPaymentRequest = {
        contributor_id: props.contributor?.id,
        receipt_type_id: props.receiptType?.id,
        payment_groups: [
          {
            number_installments: installments,
            inscriptions_ids: getInscriptionsArray(),
          },
        ],
      };
      const result = await DebtBoxService.createPaymentRequest(data);
      props.reloadData();
      removeAllInscriptions();
      return result;
    }
    return undefined;
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`paymentRequestBillets-${inscriptions.join('-')}`],
    queryFn: loadData,
    enabled: false,
  });

  const handleTotal = () => {
    if (data) {
      return Format.currency(data[0].balance);
    }
    const totalBalance = inscriptionsSelected.reduce(
      (sum, inscription) => sum + parseFloat(inscription.balance.toString()),
      0,
    );
    return Format.currency(totalBalance);
  };

  const handleCreatePaymentRequest = () => {
    refetch();
  };

  const redirectToPaymentRequest = () => {
    if (data) window.open(`/payment_request/${data[0].id}`, '_blank');
  };

  const handleGeneratedBillet = (error?: boolean) => {
    setModal(undefined);
    removeAllInscriptions();
    if (error) {
      addToasts({
        type: 'error',
        text: t('debtBox.newNegotiation.billetError'),
        textLink: t('debtBox.newNegotiation.show'),
        action: () => redirectToPaymentRequest(),
      });
    } else {
      addToasts({
        type: 'success',
        text: t('debtBox.newNegotiation.success'),
        textLink: t('debtBox.newNegotiation.show'),
        action: () => redirectToPaymentRequest(),
      });
    }
  };

  const renderTabs = () => {
    if (selectedTab === 'simulation') {
      return (
        <div className={styles.contentTabs}>
          <Show if={!!data}>
            <Negotiation negotiation={data ? data[0] : undefined} onSubmit={() => setSelectedTab('documents')}/>
          </Show>
          <Show if={isLoading || isRefetching}>
            <Skeleton height={300} variant='rectangular'/>
          </Show>
          <Show if={!data && !isLoading && !isRefetching}>
            <PaymentRequestGroup
              contributorId={props.contributor?.id}
              receiptTypeId={props.receiptType?.id}
              onClick={handleCreatePaymentRequest}
              onChangeInstallments={(value) => setInstallments(value)}
            />
          </Show>
        </div>
      );
    }
    if (selectedTab === 'documents') {
      return (
        <Documents
          id={data && data[0].id}
          installments={installments}
          nextStep={() => setSelectedTab('billets')}
          negotiation={data ? data[0] : undefined}
        />
      );
    }
    if (selectedTab === 'billets') {
      return (
        <Billets
          id={data && data[0].id}
          negotiation={data ? data[0] : undefined}
          closeModal={(err) => handleGeneratedBillet(err)}
        />
      );
    }
    return null;
  };

  return (
    <div className={ styles.container }>
      {/* <SelectedInscriptions inscriptionsInfo={props.inscriptionsInfo}/> */}
      <div className={styles.infoHeader}>
        <Show if={props.inscriptionsInfo.length > 0}>
          <p className={styles.total}>
            {t('debtBox.newNegotiation.modal.total', { value: handleTotal() })}
          </p>
        </Show>
        <p className={styles.contributor}>
          {t(
            'debtBox.newNegotiation.modal.contributor',
            { name: props.contributor?.name, document: maskCpfOrCnpj(props.contributor?.document_contributor || '').mask() },
          )}
        </p>
      </div>
      <div className={styles.tabs}>
        <Tab
          onClick={() => {}}
          tabSelected={selectedTab === 'simulation'}
          title={t('debtBox.newNegotiation.modal.simulation')}
        />
        <Tab
          onClick={() => {}}
          tabSelected={selectedTab === 'documents'}
          title={t('debtBox.newNegotiation.modal.documents')}
        />
        <Tab
          onClick={() => {}}
          tabSelected={selectedTab === 'billets'}
          title={t('debtBox.newNegotiation.modal.billets')}
        />
      </div>
      {renderTabs()}
    </div>
  );
}
