import styles from './styles.module.scss';

type TabProps = {
  title: string;
  onClick: () => void;
  tabSelected: boolean
  disabled?: boolean;
};

export function Tab(props: TabProps) {
  return (
    <div className={styles.container} onClick={props.onClick}>
      <a
        className={
          `
            ${styles.tab} 
            ${props.tabSelected ? styles.selected : ''}
            ${props.disabled ? styles.disabled : ''}
          `
        }>
        {props.title}
      </a>
    </div>
  );
}
