import { ReactComponent as CreatedDam } from '../assets/createdDam.svg';
import { ReactComponent as PaymentRequest } from '../assets/paymentRequest.svg';
import { ReactComponent as AgreementRequested } from '../assets/agreementRequested.svg';
import { ReactComponent as PaidDam } from '../assets/paidDam.svg';

export default function IconSelections(type: string, isLegalAction?: boolean) {
  if (type === 'created_dam') {
    return (<CreatedDam />);
  }
  if (type === 'paid_dam') {
    return (<PaidDam />);
  }
  if (type === 'payment_request' && isLegalAction) {
    return (<AgreementRequested />);
  }
  return (<PaymentRequest />);
}
