import { useState, useEffect, useContext } from 'react';
import { t } from 'i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { RoundButton } from '../../../../../components/RoundButton';
import { Table } from '../../../../../components/Table';
import { Address } from '../../../../../@types/contributor';
import Show from '../../../../../components/Show';
import { getPaginationTitle } from '../../../../../helpers/strings';
import styles from './styles.module.scss';
import { Link } from '../../../../../components/Link';
import GeneralService from '../../../../../services/generalService';
import { downloadFile } from '../../../../../helpers/downloadPDF';
import { HomeContext } from '../../../../../context/homeContext';

type AddressTableProps = {
  addressList: Address[];
  removePagination?: boolean;
};

export function AddressTable(props: AddressTableProps) {
  const { addToasts } = useContext(HomeContext);
  const [data, setData] = useState<Address[]>([]);
  const [currentData, setCurrentData] = useState<Address[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPerPage = 10;

  useEffect(() => {
    if (props.addressList) {
      setData(props.addressList);
      const lastIndex = currentPage * totalPerPage;
      const firstIndex = lastIndex - totalPerPage;
      setCurrentData(props.addressList.slice(firstIndex, lastIndex));
    }
  }, []);

  useEffect(() => {
    if (props.addressList) {
      setCurrentData(props.addressList);
    }
  }, [props.addressList]);

  const handlePage = (event: string) => {
    const totalPages = Math.floor(data.length / totalPerPage) + 1;
    let page = 1;
    if (event === 'prev' && currentPage > 1 && totalPages > 1) {
      page = currentPage - 1;
    } else if (event === 'next' && currentPage < totalPages) {
      page = currentPage + 1;
    }
    const lastIndex = page * totalPerPage;
    const firstIndex = lastIndex - totalPerPage;
    setCurrentData(data.slice(firstIndex, lastIndex));
    setCurrentPage(page);
  };

  const parseOwner = (item: Address) => {
    if (item.individual_name && item.created_at) {
      return `${item.created_at} - ${item.individual_name}`;
    }
    return '';
  };

  const handleDownloadDocument = (id: number) => {
    GeneralService.getDownloadDocument(id)
      .then((res) => {
        downloadFile(res, t('contributorBox.details.address.generateAddressDoc'));
      }).catch((error) => {
        addToasts({
          type: 'error',
          text: error.data.error,
        });
      });
  };

  const parseDoc = (item: Address) => {
    if (item.address_declaration) {
      return (
        <Link
          onClick={() => handleDownloadDocument(item.address_declaration.id)}
        >
          {t('contributorBox.details.address.view')}
        </Link>
      );
    }
    return '';
  };

  return (
    <>
      <Show if={props.addressList.length > 0}>
        {
          !props.removePagination && (
            <div className={`${styles.pagination}`}>
              <span className={`${styles.paginationItem}`}>
                {getPaginationTitle(currentPage, totalPerPage, data.length)}
              </span>
              <RoundButton
                className={ styles.button }
                icon={<FaChevronLeft/>}
                size='small'
                onClick={() => handlePage('prev')}
              />
              <RoundButton
                className={ styles.button }
                icon={<FaChevronRight/>}
                size='small'
                onClick={() => handlePage('next')}
              />
            </div>
          )
        }
        <Table>
          <thead>
            <tr>
              <th>{t('contributorBox.details.address.createdBy')}</th>
              <th>{t('contributorBox.details.address.cep')}</th>
              <th>{t('contributorBox.details.address.street')}</th>
              <th>{t('contributorBox.details.address.number')}</th>
              <th>{t('contributorBox.details.address.complement')}</th>
              <th>{t('contributorBox.details.address.neighborhood')}</th>
              <th>{t('contributorBox.details.address.city')}</th>
              <th>{t('contributorBox.details.address.state')}</th>
              <th>{t('contributorBox.details.address.document')}</th>
              <th>{t('contributorBox.details.address.documentDate')}</th>
            </tr>
          </thead>
          <tbody>
            { currentData
              ? currentData.map((item: Address) => (
                <tr>
                  <td>{parseOwner(item)}</td>
                  <td>{item.cep}</td>
                  <td>{item.street}</td>
                  <td>{item.street_number}</td>
                  <td>{item.address_complement}</td>
                  <td>{item.neighborhood}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{parseDoc(item)}</td>
                  <td>{item.address_declaration?.document_date || t('contributorBox.details.address.noDocument')}</td>
                </tr>
              )) : <></>
            }
          </tbody>
        </Table>
      </Show>
      <Show if={props.addressList.length < 1}>
        <p className={styles.text}>{t('contributorBox.details.address.emptyData')}</p>
      </Show>
    </>
  );
}
