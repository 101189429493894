import { t } from 'i18next';
import {
  FaCalendarAlt, FaFileAlt, FaFlag, FaPaperclip, FaTrashAlt,
  FaUserAlt,
} from 'react-icons/fa';
import { Grid, Skeleton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { Table } from '../../../../components/Table';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import InscriptionService from '../../../../services/inscriptionService';
import { Modal } from '../../../../components/Modal';
import { AttachDocument } from '../../../PaymentRequest/Documents/AttachDocument';
import { AttachedDocument, DocumentsDetails } from '../../../../@types/activeDebt/inscription';
import { HomeContext } from '../../../../context/homeContext';
import Show from '../../../../components/Show';
import { OptionType } from '../../../../@types/config';
import { Link } from '../../../../components/Link';
import GeneralService from '../../../../services/generalService';
import { downloadFile } from '../../../../helpers/downloadPDF';
import DebtBoxService from '../../../../services/debtBoxService';

type DocumentsProps = {
  inscriptionId: number;
  documents: DocumentsDetails[];
  isLoading: boolean;
  refetch: () => void;
  documentTypes: OptionType[];
  showAttachButton?: boolean;
};

export function DocumentList(props: DocumentsProps) {
  const [openAttachDocumentModal, setOpenAttachDocumentModal] = useState(false);
  const { addToasts, setAlert } = useContext(HomeContext);

  const handleAttachDocument = (files: AttachedDocument[]) => {
    InscriptionService.attachInscriptionDocuments(props.inscriptionId, files)
      .then(() => {
        setOpenAttachDocumentModal(false);
        props.refetch();
        addToasts({
          type: 'success',
          text: t('inscription.documents.toast.success'),
        });
      }).catch(() => {
        addToasts({
          type: 'error',
          text: t('inscription.documents.toast.error'),
        });
      });
  };

  const handleDownloadDocument = (document: DocumentsDetails) => {
    GeneralService.getDownloadDocument(document.id)
      .then((res) => {
        downloadFile(res, document.file_name);
      }).catch((error) => {
        addToasts({
          type: 'error',
          text: error.data.error,
        });
      });
  };

  const handleDelete = (documentId: number) => {
    setAlert(undefined);
    if (props.inscriptionId && documentId) {
      DebtBoxService.deleteInscriptionDocument(props.inscriptionId, documentId)
        .then(() => {
          props.refetch();
        }).catch(() => {
          addToasts({
            type: 'error',
            text: t('inscription.documents.toast.deleteError'),
          });
        });
    }
  };

  const handleOpenDeleteAlert = (id: number) => {
    setAlert({
      visible: true,
      text: t('inscription.documents.confirmAlertText'),
      title: t('inscription.documents.confirmAlertTitle'),
      confirmType: 'danger',
      handleConfirm: () => handleDelete(id),
    });
  };

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12}>
        <Show if={props.isLoading}>
          <Skeleton height={80} variant='rectangular' />
        </Show>
        <Show if={props.documents.length === 0 && !props.isLoading}>
          <span className={styles.noDocuments}>
            {t('inscription.documents.noDocuments')}
          </span>
        </Show>
        <Show if={props.documents.length > 0 && !props.isLoading}>
          <Table>
            <thead>
              <tr>
                <th>{t('inscription.documents.documentList.title')}</th>
                <th>{t('inscription.documents.documentList.date')}</th>
                <th>{t('inscription.documents.documentList.type')}</th>
                <th>{t('inscription.documents.documentList.attatchedBy')}</th>
                <th>{t('inscription.documents.documentList.delete')}</th>
              </tr>
            </thead>
            <tbody>
              { props.documents
                ? props.documents.map((document: DocumentsDetails) => (
                  <tr key={document.id}>
                    <td>
                      <Link
                        onClick={() => handleDownloadDocument(document)}
                      >
                        <FaFileAlt/>
                        {document.file_name}
                      </Link>
                    </td>
                    <td>
                      <FaCalendarAlt/> {document.attached_at}
                    </td>
                    <td>
                      <FaFlag/> {document.type}
                    </td>
                    <td>
                      <FaUserAlt/> {document.individual_name}
                    </td>
                    <td className={styles.deleteButton}>
                      <Tooltip title={t('paymentRequest.documents.deleteTooltip')}>
                        <a onClick={() => handleOpenDeleteAlert(document.id)}>
                          <FaTrashAlt />
                        </a>
                      </Tooltip>
                    </td>
                  </tr>
                )) : <></>
              }
            </tbody>
          </Table>
        </Show>
      </Grid>
      <Show if={!!props.showAttachButton}>
        <Grid className={styles.attachmentButton} container justifyContent={'flex-end'}>
          <Button
            title={t('inscription.documents.attachDocument')}
            buttonType='primary'
            onClick={() => setOpenAttachDocumentModal(true)}
            round
          />
        </Grid>
        <Modal
          title={t('paymentRequest.documents.attachDocument.title')}
          open={openAttachDocumentModal}
          onClose={() => setOpenAttachDocumentModal(false)}
          icon={<FaPaperclip />}
          size='large'
        >
          <AttachDocument
            handleFunction={handleAttachDocument}
            documentType={props.documentTypes}
          />
        </Modal>
      </Show>
    </Grid>
  );
}
