import { Contact, Contributor, NewContributorParams } from '../@types/contributor';
import Axios from '../config/api';

export default class ContributorService {
  public static async getContributor(id: string) {
    return Axios.get<Contributor>(`api/contributors/${id}/edit`)
      .then((res) => res.data);
  }

  public static async getNewContributorParams() {
    return Axios.get<NewContributorParams>('api/contributors/new')
      .then((res) => res.data);
  }

  public static async addContributor(contributorData: FormData) {
    return Axios.post('api/contributors/', contributorData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => res);
  }

  public static async getContributorAddress(id: number) {
    return Axios.get('api/contributors/addresses', { params: { id } })
      .then((res) => res.data);
  }

  public static async addAddress(id: number, addressData: FormData) {
    return Axios.post(`api/contributor_addresses/${id}`, addressData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => res);
  }

  public static async addContact(contributorId: number, form: any) {
    return Axios.post(`api/contributor_contacts/${contributorId}`, {
      contributor_contact_histories: form,
    })
      .then((res) => res);
  }

  public static async removeContact(id: number) {
    return Axios.patch<Contact>(`api/contributor_contacts/${id}`)
      .then((res) => res.data);
  }

  public static async updateBasicData(id: number, form: any) {
    return Axios.put(`/api/contributors/${id}`, {
      contributor: form,
    }).then((res) => res);
  }

  public static async generateAddressDocument(data: any) {
    return Axios.post('api/contributors/address_declaration', data)
      .then((res) => res.data);
  }
}
