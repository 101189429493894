import { useContext, useState, useEffect } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FaTrashAlt } from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import InscriptionService from '../../../../../services/inscriptionService';
import { HomeContext } from '../../../../../context/homeContext';
import Show from '../../../../../components/Show';
import { Table } from '../../../../../components/Table';
import Select from '../../../../../components/Select';
import { InputDate } from '../../../../../components/InputData';
import { InputText } from '../../../../../components/InputText';
import { Button } from '../../../../../components/Button';

type ChangeInscriptionsStateProps = {
  onSuccess: () => void;
  onFailed: () => void;
};

export function ChangeInscriptionsState(props: ChangeInscriptionsStateProps) {
  const {
    inscriptionsSelected, removeInscriptions,
  } = useContext(HomeContext);

  const [inscriptionsData, setInscriptionsData] = useState(
    inscriptionsSelected.map((inscription) => ({
      id: inscription.id,
      reason: undefined,
      date: '',
      observation: '',
      state: undefined,
    })),
  );

  const [isFormValid, setIsFormValid] = useState(false);

  const loadData = async () => {
    if (inscriptionsSelected.length > 0) {
      const inscriptionsIds = inscriptionsSelected.map((i) => i.id);
      const result = await InscriptionService.getUpdateStateData(inscriptionsIds);
      return result;
    }
    return null;
  };

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: [`getUpdateStateData-${inscriptionsSelected.map((i) => i.id).join('-')}`],
    queryFn: loadData,
  });

  const validateForm = (dataValid: any) => {
    const isValid = dataValid.every((inscription: any) => (
      inscription.reason !== undefined
      && inscription.state !== undefined
      && inscription.observation && inscription.observation.length >= 17
      && (inscription.state === 30 || (inscription.date && inscription.date !== null))
    ));
    setIsFormValid(isValid);
  };

  const handleChange = (id: number, field: string, value: any) => {
    const updatedInscriptions: any = inscriptionsData.map((inscription) => (
      inscription.id === id
        ? {
          ...inscription,
          [field]: value,
          ...(field === 'state' && {
            reason: value === 30 ? 1 : (value === 1 ? 2 : null),
            date: value === 30 ? '' : inscription.date,
          }),
        }
        : inscription
    ));
    setInscriptionsData(updatedInscriptions);
    validateForm(updatedInscriptions);
  };

  const handleRemoveInscription = (id: number) => {
    const inscription = inscriptionsSelected.find((i) => i.id === id);
    if (inscription) removeInscriptions([inscription]);
  };

  const handleSave = () => {
    const payload = {
      inscriptions: inscriptionsData.map((inscription) => ({
        id: inscription.id,
        reason: inscription.reason,
        date: inscription.state === 30 ? '' : inscription.date,
        observation: inscription.observation,
        state: inscription.state,
      })),
    };
    InscriptionService.updateInscriptionState(payload)
      .then(() => props.onSuccess())
      .catch(() => props.onFailed());
  };

  useEffect(() => {
    validateForm(inscriptionsData);
  }, [inscriptionsData]);

  return (
    <div className={styles.container}>
      <Show if={!!data && !isLoading && !isRefetching}>
        <Table>
          <thead>
            <th>{t('debtBox.results.actions.changeState.cda')}</th>
            <th>{t('debtBox.results.actions.changeState.procedure')}</th>
            <th>{t('debtBox.results.actions.changeState.state')}</th>
            <th>{t('debtBox.results.actions.changeState.newState')}</th>
            <th>{t('debtBox.results.actions.changeState.reason')}</th>
            <th>{t('debtBox.results.actions.changeState.date')}</th>
            <th>{t('debtBox.results.actions.changeState.observation')}</th>
            <th></th>
          </thead>
          {data?.result.map((inscription) => (
            <tbody key={inscription.id}>
              <td>{inscription.cda}</td>
              <td>{inscription.procedure_numbers}</td>
              <td>{inscription.state}</td>
              <td className={styles.selectColumn}>
                <Select
                  options={inscription.states}
                  returnValue
                  onChange={(value) => handleChange(inscription.id, 'state', value)}
                  placeholder={t('debtBox.results.actions.changeState.newStatePlaceholder')}
                />
              </td>
              <td className={styles.selectColumn}>
                <InputText
                  readOnly
                  value={inscriptionsData.find((i) => i.id === inscription.id)?.state ? inscription.reasons.find((r) => r.value === (inscriptionsData.find((i) => i.id === inscription.id)?.state === 30 ? 1 : 2))?.label : ''}
                  placeholder={t('debtBox.results.actions.changeState.reasonPlaceholder')}
                />
              </td>
              <td>
                <InputDate
                  onChange={(date) => handleChange(inscription.id, 'date', date)}
                  disabled={inscriptionsData.find((i) => i.id === inscription.id)?.state === 30}
                  value={inscriptionsData.find((i) => i.id === inscription.id)?.date}
                  fixedValue
                />
              </td>
              <td>
                <InputText
                  onChange={(e) => handleChange(inscription.id, 'observation', e.target.value)}
                  value={inscriptionsData.find((i) => i.id === inscription.id)?.observation}
                  placeholder={t('debtBox.results.actions.changeState.observationPlaceholder')}
                  minLength={17}
                />
              </td>
              <td className={styles.deleteButton}>
                <Show if={inscriptionsSelected.length > 1}>
                  <Tooltip title={t('debtBox.results.actions.changeState.remove')}>
                    <a
                      onClick={() => handleRemoveInscription(inscription.id)}
                    >
                      <FaTrashAlt />
                    </a>
                  </Tooltip>
                </Show>
              </td>
            </tbody>
          ))}
        </Table>

        <div className={styles.observations}>
          <p>{t('debtBox.results.actions.changeState.observations.title')}</p>
          <span>{t('debtBox.results.actions.changeState.observations.obs1')}</span>
          <span>{t('debtBox.results.actions.changeState.observations.obs2')}</span>
        </div>

        <div className={styles.footer}>
          <Button
            title={t('debtBox.results.actions.changeState.submit')}
            onClick={handleSave}
            buttonType='primary'
            round
            disabled={!isFormValid}
          />
        </div>
      </Show>
      <Show if={isLoading || isRefetching}>
        <Skeleton variant='rectangular' height={200}/>
      </Show>
    </div>
  );
}
