import { Grid } from '@mui/material';
import Switch from 'react-switch';
import { t } from 'i18next';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import styles from './styles.module.scss';
import { Button } from '../Button';
import { OptionType, SelectFilter } from '../SelectFilter';
import ReportService from '../../services/reportService';

type CustomReportProps = {
  contributorId: number;
  inscriptionsIds: number[];
  closeModal: () => void;
};

type ParamsType = {
  displayProtest: boolean;
  displayLegalAction: boolean;
  fromModal: boolean;
  receiptTypeIdIn?: any[];
  stateIn?: any[];
};

export type CustomReportVariables = {
  receipt_types: OptionType[];
  states: OptionType[];
};

export function CustomReport(props: CustomReportProps) {
  const [protestedCdas, setProtestedCdas] = useState(false);
  const [legalActionCdas, setLegalActionCdas] = useState(false);
  const [selectedReceiptTypes, setSelectedReceiptTypes] = useState<number[]>([]);
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');

  const loadData = async () => {
    const result = await ReportService.getCustomReportVariables(props.inscriptionsIds, props.contributorId);
    return result;
  };

  const {
    data,
  } = useQuery({
    queryKey: ['customReportParams'],
    queryFn: loadData,
  });

  const handleSubmit = async () => {
    const params: ParamsType = {
      displayProtest: protestedCdas,
      displayLegalAction: legalActionCdas,
      fromModal: true,
    };

    if (selectedReceiptTypes && selectedReceiptTypes.length > 0) {
      params.receiptTypeIdIn = data?.receipt_types.filter((state) => selectedReceiptTypes.includes(state.value));
    }
    if (selectedStates && selectedStates.length > 0) {
      params.stateIn = data?.states.filter((state) => selectedStates.includes(state.value));
    }

    let searchObject = search ? JSON.parse(decodeURIComponent(search)) : {};
    searchObject = { ...searchObject, ...params };
    window.open(`/reports/custom/${props.contributorId}?search=${encodeURIComponent(JSON.stringify(searchObject))}`, '_blank');
  };

  return (
    <div
      className={ styles.container }
    >
      <Grid
        container
        columns={{ xs: 4, sm: 6, md: 6 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={4} sm={5} md={5}>
          <p>{t('debtBox.report.custom.message')}</p>
        </Grid>
        <Grid item xs={4} sm={5} md={5}>
          <p className={styles.label}>
            <Trans
              i18nKey='debtBox.report.custom.receiptType'
              components={{ bold: <strong /> }} />
          </p>
          <SelectFilter
            options={data?.receipt_types || []}
            placeholder={t('debtBox.report.custom.receiptTypeLabel')}
            handleSelectedItems={(item) => setSelectedReceiptTypes(item)}
          />
        </Grid>
        <Grid item xs={4} sm={5} md={5}>
          <p className={styles.label}>
            <Trans
              i18nKey='debtBox.report.custom.currentState'
              components={{ bold: <strong /> }} />
          </p>
          <SelectFilter
            options={data?.states || []}
            placeholder={t('debtBox.report.custom.currentStateLabel')}
            handleSelectedItems={(item) => setSelectedStates(item)}
          />
        </Grid>
        <Grid className={styles.switch} item xs={4} sm={5} md={5}>
          <Switch
            checked={protestedCdas}
            onChange={() => setProtestedCdas(!protestedCdas)}
            offColor='#e8e8e8'
            onColor='#1ECAA1'
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            disabled={false}
            width={32}
          />
          <p className={styles.label}>
            <Trans
              i18nKey='debtBox.report.custom.protested'
              components={{ bold: <strong /> }} />
          </p>
        </Grid>
        <Grid className={styles.switch} item xs={4} sm={5} md={5}>
          <Switch
            checked={legalActionCdas}
            onChange={() => setLegalActionCdas(!legalActionCdas)}
            offColor='#e8e8e8'
            onColor='#1ECAA1'
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            disabled={false}
            width={32}
          />
          <p className={styles.label}>
            <Trans
              i18nKey='debtBox.report.custom.legalAction'
              components={{ bold: <strong /> }} />
          </p>
        </Grid>
        <Grid className={styles.footer} item xs={4} sm={6} md={6}>
          <Button
            dataCy='submit-report-button'
            title={t('debtBox.report.title')}
            buttonType='primary'
            onClick={() => handleSubmit()}
            round
          />
        </Grid>
      </Grid>
    </div>
  );
}
