import Axios from '../config/apiAgilis';
import {
  CurrentUser, CurrentUserToken, CurrentUserLogout,
} from '../@types/currentUser';

export default class AuthService {
  public static async getCurrentUser() {
    return Axios.get<CurrentUser>('api/me/sign_in').then((res) => res.data);
  }

  public static async getCurrentUserToken(user: string, password: string, origin: string) {
    const requestBody = {
      user,
      password,
    };

    const headers = {
      'Origin-App': origin,
    };

    return Axios.post<CurrentUserToken>('api/me/token', requestBody, { headers }).then((res) => res.data);
  }

  public static async logout() {
    return Axios.delete<CurrentUserLogout>('api/me/logout').then((res) => res.data);
  }

  public static async setEmailToRecoverPassword(user: string) {
    const headers = {
      'Origin-App': origin,
    };
    return Axios.post('/api/me/forgot_password', { user }, { headers }).then((res) => res);
  }

  public static async recoverPassword(password: string, passwordConfirmation: string, token: string) {
    const headers = {
      'Origin-App': origin,
    };
    return Axios.put('/api/me/reset_password', {
      password,
      password_confirmation: passwordConfirmation,
      token,
    }, { headers }).then((res) => res);
  }
}
