import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useContext } from 'react';
import { AddressData, AddressDataType } from '../../../Form/AddressData';
import { Button } from '../../../../../components/Button';
import { HomeContext } from '../../../../../context/homeContext';
import styles from './styles.module.scss';
import ContributorService from '../../../../../services/contributorService';

interface FormValues {
  addressData: AddressDataType;
}

type UpdateAddressFormProps = {
  contributorId: number;
  closeModal: () => void;
  refetch: () => void;
};

export function UpdateAddressForm(props: UpdateAddressFormProps) {
  const { addToasts } = useContext(HomeContext);
  const methods = useForm<FormValues>();

  const submit = async (form: any) => {
    const formData = new FormData();

    formData.append('contributor_address[cep]', form.cep);
    formData.append('contributor_address[street]', form.street);
    formData.append('contributor_address[street_number]', form.street_number);
    formData.append('contributor_address[address_complement]', form.address_complement);
    formData.append('contributor_address[neighborhood]', form.neighborhood);
    formData.append('contributor_address[state]', form.state);
    formData.append('contributor_address[city]', form.city);
    formData.append('contributor_address[main]', 'true');

    if (form.document) {
      formData.append('contributor_address[address_declaration_attributes][archive]', form.document);
    }
    formData.append('contributor_address[address_declaration_attributes][document_date]', form.documentDate);

    try {
      const res = await ContributorService.addAddress(props.contributorId, formData);
      if (res.status === 200 || res.status === 201) {
        addToasts({
          type: 'success',
          text: t('contributorBox.details.address.success'),
          widthFull: true,
        });
        props.refetch();
        props.closeModal();
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('contributorBox.details.address.error'),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={methods.handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={{ xs: 2, md: 2 }}
        >
          <Grid item xs={4} sm={12} md={12}>
            <p className={styles.label}>
              <Trans
                i18nKey='contributorBox.details.address.updateText'
                components={{ bold: <strong /> }} />
            </p>
          </Grid>
          <AddressData hideSubtitle />
          <Grid className={styles.footer} item xs={4} sm={12} md={12}>
            <Button
              dataCy={'cypress-address-submit-button'}
              title={t('contributorBox.details.address.update')}
              buttonType='primary'
              round
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
