import { t } from 'i18next';
import logo from '../../../assets/logo-prefeitura.png';

import styles from './styles.module.scss';
import Show from '../../Show';

type ReportHeaderProps = {
  title?: string;
  subtitle?: string;
  type?: 'full' | 'custom' | 'simulation';
  simplifyed?: boolean;
};

export function Header(props: ReportHeaderProps) {
  const redirectPortal = () => {
    window.open('https://portal.pgm.fortaleza.ce.gov.br/', '_blank');
  };

  const handleStyle = (isInfo: boolean) => {
    if (props.type === 'full') {
      return isInfo ? styles.flatInfo : styles.flatTitle;
    }

    if (props.type === 'custom') {
      return isInfo ? styles.info : styles.customHeader;
    }

    return isInfo ? styles.info : styles.titleHeader;
  };
  return (
    <header data-cy='reports-header'>
      <div className={styles.container}>
        <div className={props.simplifyed ? styles.simplifyed : ''}>
          <div className={styles.left}>
            { props.simplifyed
              ? <span>{t('reports.fullStatement.headerAccessTile')}
              : <a onClick={() => redirectPortal()}>{t('reports.fullStatement.portalLink')}</a></span> : <img src={logo} alt="" className={styles.logo} />}
          </div>
          <div className={styles.right}>
            <div className={handleStyle(false)}>
              <div className={styles.dest}>{props.title}</div>
              <Show if={Boolean(props.subtitle)}>
                <div className={styles.sDest}>{props.subtitle}</div>
              </Show>
            </div>
            {!props.simplifyed
              && <div className={handleStyle(true)}>
                <span>{t('reports.header.created_by')}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </header>
  );
}
