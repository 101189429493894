import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FaClipboardList, FaTimes, FaUser } from 'react-icons/fa';
import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import InscriptionService from '../../../services/inscriptionService';
import { Table } from '../../../components/Table';
import { AGILIS_DIGITAL_PROCESSES } from '../../../config/constants';
import styles from './styles.module.scss';
import { Link } from '../../../components/Link';
import Show from '../../../components/Show';
import { Button } from '../../../components/Button';
import { HomeContext } from '../../../context/homeContext';
import { InscriptionProcedures } from '../../../@types/activeDebt/inscription';
import { AssociateProcedure } from '../AssociateProcedure';

type LinkedProceduresProps = {
  inscriptionId: number;
  onChange: (count: number) => void;
};

export function LinkedProcedures(props: LinkedProceduresProps) {
  const { setAlert, setModal, addToasts } = useContext(HomeContext);
  const loadData = async () => {
    if (props.inscriptionId) {
      const result = await InscriptionService.getInscriptionProcedures(props.inscriptionId);
      props.onChange(result.length);
      return result;
    }
    return null;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`inscriptionProcedures-${props.inscriptionId}`],
    queryFn: loadData,
  });

  const dissasociateProcedure = async (document: InscriptionProcedures) => {
    if (data) {
      const procedureParams = {
        procedure_id: document.id,
        procedure_number: document.process_number,
        inscription_id: props.inscriptionId,
      };
      const result = await InscriptionService.dissasociateProcedure(procedureParams);
      if (result) {
        setAlert(undefined);
        refetch();
      }
    }
  };

  const handleAlert = (document: InscriptionProcedures) => {
    if (data) {
      setAlert({
        visible: true,
        handleConfirm: () => dissasociateProcedure(document),
        title: t('inscription.linkedProcedures.dissociateAlert.title'),
        text: (
          <Trans
            data-cy='disassociate-modal'
            className={styles.balance}
            i18nKey={'inscription.linkedProcedures.dissociateAlert.subTitle'}
            components={{ bold: <strong /> }}
            values={{ value: document.process_number }}
          />
        ),
      });
    }
  };

  const onAssociate = (error?: boolean) => {
    setModal(undefined);
    if (error) {
      addToasts({ text: t('inscription.linkedProcedures.modal.error'), type: 'error' });
    } else {
      addToasts({ text: t('inscription.linkedProcedures.modal.success'), type: 'success' });
    }
    refetch();
  };

  const handleAssociateProcedure = () => {
    setModal({
      children: (
        <AssociateProcedure
          id={props.inscriptionId}
          onSuccess={() => onAssociate()}
          onFailed={() => setModal(undefined)}
        />
      ),
      title: t('inscription.linkedProcedures.modal.title'),
      visible: true,
      open: true,
      size: 'small',
      handleClose: () => setModal(undefined),
    });
  };

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={12}>
        {
          (isLoading) && (
            <Skeleton variant='rectangular' height={100}/>
          )
        }
        {
          (data && data.length > 0 && !isLoading) && (
            <Table>
              <thead>
                <tr>
                  <th>{t('inscription.linkedProcedures.number')}</th>
                  <th>{t('inscription.linkedProcedures.flux')}</th>
                  <th>{t('inscription.linkedProcedures.currentSituation')}</th>
                  <th>{t('inscription.linkedProcedures.responsible')}</th>
                  <th>{t('inscription.linkedProcedures.linkedBy')}</th>
                  <th>{t('inscription.linkedProcedures.removeLink')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((document) => (
                    <tr>
                      <td className={styles.individual}>
                        <span>
                          <FaClipboardList />
                          <Link
                            href={`${AGILIS_DIGITAL_PROCESSES}/procedure_box/show_procedure/${document.id}`}
                            target='_blank'
                          >
                            {document.process_number}
                          </Link>
                        </span>
                      </td>
                    <td>
                      {document.flux_name}
                    </td>
                    <td>
                      {document.last_product_generated}
                    </td>
                    <td className={styles.individual}>
                      <span>
                        <FaUser />
                        {document.individual_name}
                      </span>
                    </td>
                    <td className={styles.individual}>
                      <Show if={document.association_responsible}>
                        <span>
                          <FaUser />
                          {document.association_responsible}
                        </span>
                      </Show>
                    </td>
                    <td className={styles.deleteButton}>
                      <a
                        data-cy='data-cy-disassociate-button'
                        onClick={() => handleAlert(document)}
                      >
                        <FaTimes />
                      </a>
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </Table>
          )
        }
        {
          ((!data || data.length < 1) && !isLoading) && (
            <div className={styles.empty}>
              <p>{t('inscription.linkedProcedures.empty')}</p>
            </div>
          )
        }
        <Show if={(!data || data.length < 1) && !isLoading}>
          <div className={styles.attachButton}>
            <Button
              title={t('inscription.linkedProcedures.action')}
              buttonType='primary'
              onClick={handleAssociateProcedure}
              round
            />
          </div>
        </Show>
      </Grid>
    </Grid>
  );
}
