import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import {
  FaChevronDown, FaRegQuestionCircle,
} from 'react-icons/fa';
import { useState } from 'react';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';

type OptionType = {
  value: any;
  label: string;
};

type MultipleSelectProps = {
  dataCy?: string;
  options: OptionType[];
  className?: string;
  classNameInput?: string;
  popperStyle?: string;
  placeholder?: string;
  label?: string;
  tooltip?: string;
  selectedOptions: OptionType[];
  disabled?: boolean;
  defaultValue?: OptionType[];
  onChange?: (value: OptionType[] | undefined) => void;
  required?: boolean;
  returnValue?: boolean;
  urlToUpdate?: (input: string) => Promise<Response[]>;
  labelBold?: boolean;
  onlySelect?: boolean;
  noOptionsMessage?: string;
  disableClearable?: boolean;
};

type Response = {
  label: string;
  value: string | number;
};

export default function MultipleSelect(props: MultipleSelectProps) {
  const [options, setOptions] = useState<OptionType[]>(props.options);
  const [loading, setLoading] = useState(false);

  const onChange = (event: React.ChangeEvent<{}>, value: OptionType[] | undefined) => {
    if (props.onChange) {
      if (props.returnValue && value) {
        props.onChange(value.map((v) => v.value));
      } else {
        props.onChange(value);
      }
    }
  };

  const handleUpdateList = async (value: string) => {
    if (props.urlToUpdate) {
      if (value.length > 2) {
        setLoading(true);
        setOptions([]);
        const response: Response[] = await props.urlToUpdate(value);
        setOptions(response.map((p: Response) => ({ label: p.label, value: p.value })));
        setLoading(false);
      } else {
        setOptions([]);
        setLoading(false);
      }
    }
  };

  const getEmptyMessage = () => {
    if (loading) {
      return 'Buscando...';
    }
    if (props.noOptionsMessage) {
      return props.noOptionsMessage;
    }
    return t('general.emptyOptions');
  };

  const filterOptions = (optionsToSearch: OptionType[], state: FilterOptionsState<OptionType>): OptionType[] => {
    if (props.urlToUpdate) {
      return optionsToSearch;
    }
    const defaultFilterOptions = createFilterOptions<OptionType>();
    return defaultFilterOptions(optionsToSearch, state);
  };

  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={styles.info}>
        {props.label && <Label text={props.label} required={props.required} bold={props.labelBold} />}
        {props.tooltip && (
          <Tooltip title={props.tooltip}>
            <div className={styles.containerTag}>
              <FaRegQuestionCircle />
            </div>
          </Tooltip>
        )}
      </div>
      <Autocomplete
        multiple
        options={props.urlToUpdate ? options : props.options}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        getOptionLabel={(option) => option.label}
        disableListWrap
        popupIcon={props.urlToUpdate ? null : <FaChevronDown size={13} className={styles.popupIcon} />}
        classes={{
          input: `${styles.input} ${styles.inputGroup} ${props.disabled ? styles.disabled : ''} ${props.classNameInput}`,
          inputRoot: styles.inputRoot,
          option: `${styles.option} ${props.dataCy}`,
          popper: `${styles.popper} ${props.popperStyle}`,
          noOptions: styles.noOptions,
          clearIndicator: styles.clearIndicator,
          tag: styles.tag,
        }}
        value={props.selectedOptions}
        onChange={onChange}
        noOptionsText={getEmptyMessage()}
        disableClearable={props.disableClearable}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            disabled={props.disabled}
            required={props.required}
            onChange={(event) => handleUpdateList(event.target.value)}
            inputProps={{
              ...params.inputProps,
              'data-cy': props.dataCy,
            }}
          />
        )}
      />
    </div>
  );
}
