import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useContext } from 'react';
import { Button } from '../../../../../components/Button';
import { ContactData } from '../../../Form/ContactData';
import { HomeContext } from '../../../../../context/homeContext';
import ContributorService from '../../../../../services/contributorService';
import styles from './styles.module.scss';
import { CurrentUser } from '../../../../../@types/currentUser';

interface FormValues {
  contactData: any;
}

type NewContactFormProps = {
  contributorId: number;
  closeModal: () => void;
  refetch: () => void;
};

export function NewContactForm(props: NewContactFormProps) {
  const { addToasts } = useContext(HomeContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const methods = useForm<FormValues>();

  const submit = async (form: any) => {
    const formData = {
      cell_phone: form.cell_phone ?? '',
      telephone: form.telephone ?? '',
      email: form.email ?? '',
      current_contact: true,
      cell_phone_whatsapp: true,
      individual_id: currentUser.id,
    };

    try {
      const res = await ContributorService.addContact(props.contributorId, formData);
      if (res.status === 200 || res.status === 201) {
        addToasts({
          type: 'success',
          text: t('contributorBox.details.contact.success'),
          widthFull: true,
        });
        props.refetch();
        props.closeModal();
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('contributorBox.details.contact.error'),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={methods.handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={{ xs: 2, md: 2 }}
        >
          <Grid item xs={4} sm={12} md={12}>
            <p className={styles.label}>
              <Trans
                i18nKey='contributorBox.details.contact.updateText'
                components={{ bold: <strong /> }} />
            </p>
          </Grid>
          <ContactData hideSubtitle />
          <Grid className={styles.footer} item xs={4} sm={12} md={12}>
            <Button
              dataCy={'cypress-contact-submit-button'}
              title={t('contributorBox.details.contact.addNew')}
              buttonType='primary'
              round
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
