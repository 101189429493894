import {
  createContext,
  FC,
  useState,
} from 'react';
import { HomeContextType } from '../@types/homeContext';
import { Alert, Modal, ToastProps } from '../@types/config';
import { InscriptionsByPaymentGroupResponse } from '../@types/activeDebt/debtBox';
import { checkRestriction } from '../helpers/inscriptionActions';
import { CurrentUser } from '../@types/currentUser';

const contextDefaultValues: HomeContextType = {
  inscriptionsSelected: [],
  addInscription: () => {},
  removeAllInscriptions: () => {},
  removeInscriptions: () => {},
  addInscriptions: () => {},
  addToasts: () => {},
  removeToasts: () => {},
  setModal: () => {},
  setToasts: () => {},
  setAlert: () => {},
  setNotificationsCount: () => {},
  notificationsCount: 0,
  alert: {
    visible: false,
    handleConfirm: () => {},
  },
  toasts: [],
  modal: {
    visible: false,
    handleClose: () => {},
    title: '',
    size: 'medium',
  },
};

export const HomeContext = createContext<HomeContextType>(
  contextDefaultValues,
);

const HomeProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const [modal, setModal] = useState<Modal | undefined>();
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [alert, setAlert] = useState<Alert | undefined>();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [inscriptionsSelected, setInscriptions] = useState<InscriptionsByPaymentGroupResponse[]>([]);

  const addInscription = (inscription: InscriptionsByPaymentGroupResponse) => {
    if (inscriptionsSelected.includes(inscription)) {
      const updatedSelection = inscriptionsSelected.filter((i) => i.id !== inscription.id);
      setInscriptions(updatedSelection);
    } else if (checkRestriction(inscription, inscriptionsSelected, Boolean(currentUser.permission_approve_requirements))) {
      setInscriptions([inscription]);
    } else {
      setInscriptions([...inscriptionsSelected, inscription]);
    }
  };

  const removeAllInscriptions = () => {
    setInscriptions([]);
  };

  const removeInscriptions = (inscriptions: InscriptionsByPaymentGroupResponse[]): void => {
    const inscriptionsToRemove = inscriptions.map((i) => i.id);
    const newArray = inscriptionsSelected.filter((item) => !inscriptionsToRemove.includes(item.id));
    setInscriptions(newArray);
  };

  const addInscriptions = (inscriptions: InscriptionsByPaymentGroupResponse[]): void => {
    const newArray = inscriptionsSelected.filter((item) => !inscriptions.includes(item));
    setInscriptions([...newArray, ...inscriptions]);
  };

  const addToasts = (props: ToastProps) => {
    setToasts([...toasts, props]);
  };

  const removeToasts = () => {
    setToasts([]);
  };

  return (
    <HomeContext.Provider
      value={{
        inscriptionsSelected,
        addInscription,
        removeAllInscriptions,
        modal,
        setModal,
        setAlert,
        alert,
        toasts,
        setToasts,
        addToasts,
        removeToasts,
        notificationsCount,
        setNotificationsCount,
        removeInscriptions,
        addInscriptions,
      }}>
        {children}
    </HomeContext.Provider>
  );
};

export default HomeProvider;
