import { useContext } from 'react';
import { t } from 'i18next';
import { FaTimes } from 'react-icons/fa';
import { Table } from '../../../../../components/Table';
import { Contact } from '../../../../../@types/contributor';
import Show from '../../../../../components/Show';
import styles from './styles.module.scss';
import ContributorService from '../../../../../services/contributorService';
import { HomeContext } from '../../../../../context/homeContext';

type ContactTableProps = {
  contactList: Contact[];
  displayAction: boolean;
  refetch?: () => void;
};

export function ContactTable(props: ContactTableProps) {
  const { addToasts } = useContext(HomeContext);

  const parseOwner = (item: Contact) => {
    return `${item.created_at} - ${item.individual_name}`;
  };

  const parseWhatsapp = (value: boolean) => {
    return value
      ? t('contributorBox.details.contact.whatsapp.yes')
      : t('contributorBox.details.contact.whatsapp.no');
  };

  const parsePhone = (value: string) => {
    if (value) {
      return value.length > 10
        ? value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
        : value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return '';
  };

  const removeItem = async (item: Contact) => {
    try {
      const res = await ContributorService.removeContact(item.id);
      if (!res.current_contact) {
        addToasts({
          type: 'success',
          text: t('contributorBox.details.contact.removeSuccess'),
          widthFull: true,
        });
        if (props.refetch) {
          props.refetch();
        }
      }
    } catch (error) {
      addToasts({
        type: 'error',
        text: t('contributorBox.details.contact.removeError'),
      });
    }
  };

  return (
    <>
      <Show if={props.contactList?.length > 0}>
        <Table>
          <thead>
            <tr>
              <th>{t('contributorBox.details.contact.createdBy')}</th>
              <th>{t('contributorBox.details.contact.email')}</th>
              <th>{t('contributorBox.details.contact.cellphone')}</th>
              <th>{t('contributorBox.details.contact.whatsapp.label')}</th>
              <th>{t('contributorBox.details.contact.telephone')}</th>
              <Show if={props.displayAction}>
                <th className={styles.deleteHeader}>{t('contributorBox.details.contact.delete')}</th>
              </Show>
            </tr>
          </thead>
          <tbody>
            { props.contactList
              ? props.contactList.map((item) => (
                <tr key={item.id}>
                  <td>{parseOwner(item)}</td>
                  <td>{item.email}</td>
                  <td>{parsePhone(item.cell_phone)}</td>
                  <td>{parseWhatsapp(item.cell_phone_whatsapp)}</td>
                  <td>{parsePhone(item.telephone)}</td>
                  <Show if={props.displayAction}>
                    <td
                      className={styles.delete}
                      onClick={() => removeItem(item)}
                      data-testid={`remove-contact-${item.id}`}
                    >
                      <FaTimes />
                    </td>
                  </Show>
                </tr>
              )) : <></>
            }
          </tbody>
        </Table>
      </Show>
      <Show if={props.contactList?.length < 1}>
        <p className={styles.text}>{t('contributorBox.details.contact.emptyData')}</p>
      </Show>
    </>
  );
}
