import { t } from 'i18next';
import imgFooter from '../../../../assets/img-bn-footer.svg';
import styles from './styles.module.scss';

export function PortalFooter() {
  return (
    <div className={styles.bnFooter}>
      <p>
        {t('reports.paymentRequestSimulate.negotiationDescription')}
      </p>
      <img src={imgFooter} alt="" />
    </div>
  );
}
