import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  isValid,
  parse,
  isPast,
  differenceInDays,
} from 'date-fns';
import {
  FaCalculator, FaCalendarAlt, FaClock, FaHistory,
} from 'react-icons/fa';
import { Skeleton, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Table } from '../Table';
import DebtBoxService from '../../services/debtBoxService';
import styles from './styles.module.scss';
import { Modal } from '../Modal';
import { DefaultCalculation } from '../DefaultCalculation';
import { DebtByInscription, Prescriptions } from '../../@types/activeDebt/debtBox';

type DebtProps = {
  inscription: number
};

export function DebtsTable(props: DebtProps) {
  const [openAttachDocumentModal, setOpenAttachDocumentModal] = useState(false);
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [debtId, setDebtId] = useState(0);

  const loadData = async () => {
    if (props.inscription) {
      const result = await DebtBoxService.getDebtsByInscription(props.inscription);
      return result;
    }
    return null;
  };

  const {
    refetch, data, isRefetching, isLoading,
  } = useQuery({
    queryKey: [`debtList-${props.inscription}`],
    queryFn: loadData,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, []);

  const renderPrescriptionItem = (debt: DebtByInscription, icon: any, debtIndex: number) => (
    <div className={styles.prescription}>
      <Tooltip title={debt.status}>
        <div>
          {icon}
        </div>
      </Tooltip>
      <a
        data-cy='data-cy-prescription-item'
        onClick={() => {
          setDebtId(debtIndex);
          setOpenPrescriptionModal(true);
        }}>
          {debt.prescription_date}
      </a>
    </div>
  );

  const renderPrescription = (debt: DebtByInscription, debtIndex: number) => {
    const date = parse(debt.prescription_date, 'dd/MM/yyyy', new Date());
    let className = '';
    if (!isValid(date)) {
      return renderPrescriptionItem(debt, <FaClock />, debtIndex);
    }

    if (isPast(date)) {
      className = styles.past;
    } else {
      const differenceDays = differenceInDays(date, new Date());
      className = differenceDays >= 180 ? styles.future : styles.next;
    }

    return renderPrescriptionItem(debt, <FaClock className={className} />, debtIndex);
  };

  const openDefaultCalcModal = (debtIndex: number) => {
    setOpenAttachDocumentModal(true);
    setDebtId(debtIndex);
  };

  return (
    <div className={styles.tableContainer}>
      {
        (isRefetching || isLoading) && (
          <Skeleton variant='rectangular' height={100}/>
        )
      }

      {
        (data && (!isLoading && !isRefetching)) && (
          <div className={styles.table}>
            <Table>
              <thead>
                <tr>
                  <th className={styles.bold}>
                    {t('debtBox.results.inscriptions.debts.date')}
                  </th>
                  <th className={styles.bold}>
                    {t('debtBox.results.inscriptions.debts.mainValue')}
                  </th>
                  <th className={styles.bold}>
                    {t('debtBox.results.inscriptions.debts.fines')}
                  </th>
                  <th className={styles.leftBorder}>
                    {t('debtBox.results.inscriptions.debts.mainBalance')}
                  </th>
                  <th>
                    {t('debtBox.results.inscriptions.debts.finesBalance')}
                  </th>
                  <th>
                    {t('debtBox.results.inscriptions.debts.monetaryCorrection')}
                  </th>
                  <th>
                    {t('debtBox.results.inscriptions.debts.interestValue')}
                  </th>
                  <th>
                    {t('debtBox.results.inscriptions.debts.moratoriumFines')}
                  </th>
                  <th>
                    {t('debtBox.results.inscriptions.debts.feeValue')}
                  </th>
                  <th className={styles.bold}>
                    {t('debtBox.results.inscriptions.debts.balance')}
                  </th>
                  <th className={`${styles.bold} ${styles.leftBorder}`}>
                    {t('debtBox.results.inscriptions.debts.prescription')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  data.result.map((debt: DebtByInscription, debtIndex) => (
                    <tr>
                      <td className={styles.dark}>{debt.due_date}</td>
                      <td className={styles.dark}>{debt.main_value}</td>
                      <td className={styles.dark}>{debt.fines}</td>
                      <td className={styles.leftBorder}>{debt.main_balance}</td>
                      <td>{debt.fines_balance}</td>
                      <td>{debt.total_monetary_correction}</td>
                      <td>{debt.total_interest_value}</td>
                      <td>{debt.moratorium_fines}</td>
                      <td>{debt.fee_value}</td>
                      <td className={`${styles.dark} ${styles.bold}`}>
                        <span className={styles.balance}>
                          {debt.balance}
                          <Tooltip title={t('debtBox.results.inscriptions.debts.defaultCalculation.title')}>
                            <a
                              className={styles.defaultCalculationIcon}
                              onClick={() => openDefaultCalcModal(debtIndex)}
                              data-cy='data-cy-default-calculation'
                            >
                              <FaCalculator/>
                            </a>
                          </Tooltip>
                        </span>
                      </td>
                      <td className={`${styles.dark} ${styles.leftBorder}`}>{renderPrescription(debt, debtIndex)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            <Modal
              title={t('debtBox.results.inscriptions.debts.defaultCalculation.title')}
              open={openAttachDocumentModal}
              onClose={() => setOpenAttachDocumentModal(false)}
              icon={<FaCalculator/>}
              size='large'
            >
              <DefaultCalculation
                tableData={data?.result[debtId]}
              />
            </Modal>
            <Modal
              title={t('debtBox.results.inscriptions.debts.prescriptionHistory.modal.title')}
              open={openPrescriptionModal}
              onClose={() => setOpenPrescriptionModal(false)}
              icon={<FaHistory/>}
              size='large'
            >
              <Table flat={true}>
                <thead data-cy='data-cy-prescription-table'>
                  <tr>
                    <th>{t('debtBox.results.inscriptions.debts.prescriptionHistory.modal.table.date')}</th>
                    <th>{t('debtBox.results.inscriptions.debts.prescriptionHistory.modal.table.action')}</th>
                    <th>{t('debtBox.results.inscriptions.debts.prescriptionHistory.modal.table.reason')}</th>
                    <th>{t('debtBox.results.inscriptions.debts.prescriptionHistory.modal.table.description')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.result[debtId]?.prescriptions.map((prescription: Prescriptions) => (
                    <tr>
                      <td><FaCalendarAlt className={styles.calendarIcon}/> {prescription.date}</td>
                      <td>{prescription.action_i18n}</td>
                      <td>{prescription.reason_i18n}</td>
                      <td>{prescription.description ? prescription.description : '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal>
          </div>
        )
      }
    </div>
  );
}
