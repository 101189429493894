import { t } from 'i18next';
import { FaPlus, FaUser } from 'react-icons/fa';
import { useContext } from 'react';
import { Button } from '../../../components/Button';
import { HomeContext } from '../../../context/homeContext';
import styles from './styles.module.scss';
import { Form } from '../Form';

export function NewContributorButton() {
  const { setModal } = useContext(HomeContext);

  const openNewContributorModal = () => {
    setModal({
      visible: true,
      icon: <FaUser />,
      title: t('contributorBox.form.title'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'large',
      children: <Form closeModal={() => setModal(undefined)}/>,
    });
  };

  return (
    <div className={ styles.container }>
      <Button
        title={t('contributorBox.new')}
        icon={ <FaPlus /> }
        textCenter
        round
        shadow
        onClick={() => openNewContributorModal()}
        className={styles.newProcedureButton}
        w100
      />
    </div>
  );
}
