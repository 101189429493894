import { ReactNode } from 'react';
import styles from './styles.module.scss';
import Show from '../Show';

export type TableItem = {
  label: string;
  value: ReactNode;
};

type TableDataProps = {
  items: TableItem[];
  singleColumnTable?: boolean;
};

export function TableData(props: TableDataProps) {
  return (
    <div className={ styles.container }>
      <table className={`${styles.dataTable} ${props.singleColumnTable ? styles.singleColumn : styles.multiColumn}`}>
        <tbody>
          {props.items.map((row) => (
            <tr>
              <Show if={!props.singleColumnTable}>
                <td className={styles.labelColumn}>{row.label}</td>
              </Show>
              <td className={styles.valueColumn}>{row.value}</td>
            </tr>
          ))
          }
        </tbody>
      </table>
    </div>
  );
}
