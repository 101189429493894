import { useLocation, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { Breadcumb } from '../../components/Breadcumb';
import DebtBoxService from '../../services/debtBoxService';
import { Data } from './Data';
import { GeneratedBillets } from './GeneratedBillets';
import { Subtitle } from '../../components/Subtitle';
import { PaidBillets } from './PaidBillets';
import { UnpaidBillets } from './UnpaidBillets';
import { Pendencies } from './Pendencies';
import { Tabs } from '../../components/Tabs';
import { PaymentRequestDetailsResponse } from '../../@types/activeDebt/debtBox';
import { Documents } from './Documents';
import { Title } from '../../components/Title';
import { ModalToCancel } from '../DebtBoxSearch/PaymentGroups/PaymentGroup/ModalToCancel';
import { HomeContext } from '../../context/homeContext';
import { ToastProps } from '../../@types/config';
import { DropdownButton, ItemType } from '../../components/DropdownButton';

export function PaymentRequest() {
  const [reloadId, setReloadId] = useState<number | undefined>();
  const [openCancelNegotiationModal, setOpenCancelNegotiationModal] = useState(false);
  const { addToasts } = useContext(HomeContext);
  const { pathname } = useLocation();
  const { id } = useParams();

  const loadData = async () => {
    if (id) {
      const result = await DebtBoxService.getPaymentRequestDetails(parseInt(id, 10));
      return result;
    }
    return null;
  };

  const {
    data, isRefetching, isLoading, refetch,
  } = useQuery({
    queryKey: ['paymentRequest'],
    queryFn: loadData,
  });

  const handleUpdateDocumentList = () => {
    refetch();
    setReloadId(Math.random());
  };

  const handleCancelPaymentRequest = () => {
    setOpenCancelNegotiationModal(true);
  };

  const handleNegotiationCanceled = (success: boolean) => {
    let toast: ToastProps = { type: 'success', text: t('debtBox.cancelNegotiation.success') };
    if (!success) toast = { type: 'error', text: t('debtBox.cancelNegotiation.fail') };
    addToasts(toast);
    setOpenCancelNegotiationModal(false);
    refetch();
  };

  const refetchData = () => {
    refetch();
  };

  const handleExtract = async () => {
    if (data && data.contributor !== undefined) {
      const params = {
        inscriptionIds: data.display_inscriptions.map((item) => item.id),
      };
      window.open(`/reports/custom/${data.contributor.id}?search=${encodeURIComponent(JSON.stringify(params))}`, '_blank');
    }
  };

  const handleActionItems = (): ItemType[] => {
    return [
      {
        label: t('debtBox.results.actions.extract.label'),
        onClick: () => handleExtract(),
        display: true,
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.cancelPaymentRequest'),
        onClick: () => handleCancelPaymentRequest(),
        display: true,
        enabled: data?.can_cancel_agreement,
      },
    ];
  };

  const renderTabs = (dataRender: PaymentRequestDetailsResponse) => {
    const tabs = [
      {
        id: 1,
        title: t('paymentRequest.data.title'),
        content: <Data data={dataRender} refetchData={refetchData} />,
      },
      {
        id: 2,
        title: t('paymentRequest.documents.title'),
        content: data && <Documents data={data} handleUpdateList={handleUpdateDocumentList} />,
      },
    ];
    return <Tabs tabs={tabs} />;
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={styles.title}>
          <Grid item xs={3} sm={6}>
            <Title text={t('paymentRequest.title')} />
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)} />
          </Grid>
        </Grid>
      </Grid>

      <div>
        <Grid item xs={12} className={styles.content}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={10}>
              {(!data && (isLoading || isRefetching)) && (
                <Skeleton height={350} variant='rectangular' />
              )}
              {(data && id) && (
                <div>
                  <h2 className={styles.number}>Nº {data.code}</h2>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={2} className={styles.action}>
              <DropdownButton
                title={t('debtBox.results.actions.label')}
                items={handleActionItems()}
                enabled
              />
            </Grid>
          </Grid>

          {data && id && (
            <div>
              {renderTabs(data)}

              <Subtitle text={t('paymentRequest.installmentHeader')} />
              <Pendencies id={parseInt(id, 10)} />
              <PaidBillets
                id={parseInt(id, 10)}
                count={`${data.number_installments_paid}/${data.number_installments}`}
              />
              <UnpaidBillets
                id={parseInt(id, 10)}
                count={`${data.number_installments - data.number_installments_paid}/${data.number_installments}`}
                onGenerate={() => setReloadId(Math.random())}
                reloadId={reloadId}
              />
              <GeneratedBillets id={parseInt(id, 10)} reloadId={reloadId} />
            </div>
          )}
        </Grid>
      </div>
      <ModalToCancel
        open={openCancelNegotiationModal}
        onClose={() => setOpenCancelNegotiationModal(false)}
        id={data?.id}
        code={data?.code}
        onSuccess={() => handleNegotiationCanceled(true)}
        onFail={() => handleNegotiationCanceled(false)}
      />
    </div>
  );
}
