import { useQuery } from '@tanstack/react-query';
import { FaSpinner } from 'react-icons/fa';
import { t } from 'i18next';
import { Button } from '../../../../components/Button';
import styles from './styles.module.scss';
import { downloadFile } from '../../../../helpers/downloadPDF';
import DebtBoxService from '../../../../services/debtBoxService';

type GenerateBilletButtonProps = {
  id: number;
  installmentNumbers?: number[];
  onGenerate: () => void;
};

export function GenerateBilletButton(props: GenerateBilletButtonProps) {
  const loadData = async () => {
    if (props.installmentNumbers) {
      const res = await DebtBoxService.getBilletByPaymentRequest(props.id, props.installmentNumbers);
      downloadFile(res, `boleto-gerado${props.installmentNumbers?.join('-')}.pdf`);
      props.onGenerate();
    }
  };

  const {
    refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`generateBillet-${props.installmentNumbers?.join('-')}`],
    queryFn: loadData,
    enabled: false,
  });

  const handleGenerateBillet = async () => {
    refetch();
  };

  return (
    <Button
      title={t('paymentRequest.unpaidBillets.generateBillets')}
      onClick={handleGenerateBillet}
      buttonType='primary'
      icon={(isLoading || isRefetching) && <FaSpinner className={styles.spinner}/>}
      disabled={
        (props.installmentNumbers?.length === 0) || isLoading || isRefetching
      }
      round
    />
  );
}
