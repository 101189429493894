import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { CollapseHeader } from '../../../components/CollapseHeader';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { Table } from '../../../components/Table';

type PaidBilletsProps = {
  id: number;
  count: string;
};

export function PaidBillets(props: PaidBilletsProps) {
  const [open, setOpen] = useState(true);

  const loadData = async () => {
    if (props.id) {
      const result = await DebtBoxService.getPaymentRequestPaidBillets(props.id);
      return result;
    }
    return null;
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: ['paymentRequestPaidBillets'],
    queryFn: loadData,
  });

  useEffect(() => {
    if (open && !data) {
      refetch();
    }
  }, [open]);

  const renderDueDateList = (dates: any[]) => {
    return (
      dates.map((d) => (
        <div>
          <span>{`${d.number} - ${d.due_date}`}</span>
          <br/>
        </div>
      ))
    );
  };

  return (
    <div className={ styles.container }>
      <CollapseHeader
        open={open}
        onClick={() => setOpen(!open)}
        title={t('paymentRequest.paidBillets.title')}
        count={props.count}
      />
      {open && (
        <>
          {(!data && (isLoading || isRefetching)) && (
            <Skeleton height={80} variant='rectangular' />
          )}
          {data && (
            <div className={styles.table}>
              <Table>
                <thead>
                  <tr>
                    <th>{t('paymentRequest.paidBillets.number')}</th>
                    <th>{t('paymentRequest.paidBillets.id')}</th>
                    <th>{t('paymentRequest.paidBillets.mainValue')}</th>
                    <th>{t('paymentRequest.paidBillets.fine')}</th>
                    <th>{t('paymentRequest.paidBillets.monetaryCorrection')}</th>
                    <th>{t('paymentRequest.paidBillets.interestValue')}</th>
                    <th>{t('paymentRequest.paidBillets.moratoriumFines')}</th>
                    <th>{t('paymentRequest.paidBillets.moratoriumFinesInstallment')}</th>
                    <th>{t('paymentRequest.paidBillets.fee')}</th>
                    <th>{t('paymentRequest.paidBillets.discount')}</th>
                    <th>{t('paymentRequest.paidBillets.total')}</th>
                    <th>{t('paymentRequest.paidBillets.installmentDueDate')}</th>
                    <th>{t('paymentRequest.paidBillets.dueDate')}</th>
                    <th>{t('paymentRequest.paidBillets.paidAt')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((billet) => (
                      <tr>
                        <td>{billet.installments_number_to_sentence_with_ranges}</td>
                        <td>{billet.dam_formatted_number}</td>
                        <td>{billet.main_value}</td>
                        <td>{billet.fines}</td>
                        <td>{billet.total_monetary_correction_value}</td>
                        <td>{billet.total_interest_value}</td>
                        <td>{billet.moratorium_fines}</td>
                        <td>{billet.installment_moratorium_fines}</td>
                        <td>{billet.fee}</td>
                        <td>{billet.total_discounted}</td>
                        <td>{billet.amount_to_pay}</td>
                        <td>{
                          Array.isArray(billet.display_installments_due_date.installments_due_date) ? (
                            renderDueDateList(billet.display_installments_due_date.installments_due_date)
                          ) : billet.display_installments_due_date.installments_due_date
                        }</td>
                        <td>{billet.due_date}</td>
                        <td>{billet.paid_at}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
}
