import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { InputText } from '../../../../components/InputText';
import { Subtitle } from '../../../../components/Subtitle';
import AddressService from '../../../../services/addressService';
import { InputFile } from '../../../../components/InputFile';
import { InputDate } from '../../../../components/InputData';
import Show from '../../../../components/Show';

export type AddressDataType = {
  cep: string;
  street: string;
  street_number: string;
  address_complement: string;
  neighborhood: string;
  state: string;
  city: string;
  document: any;
  documentDate: string;
};

type AddressDataProps = {
  hideSubtitle?: boolean;
};

export function AddressData(props: AddressDataProps) {
  const {
    control, setValue, setError, register,
  } = useFormContext();

  const handleCepChange = async (event: any) => {
    const cepValue = event.target.value.replace(/\D/g, '');
    setValue('cep', cepValue);
    if (cepValue.length === 8) {
      try {
        const {
          logradouro, complemento, bairro, localidade, uf,
        } = await AddressService.getAddressFromCep(cepValue);
        setValue('street', logradouro);
        setValue('address_complement', complemento);
        setValue('neighborhood', bairro);
        setValue('city', localidade);
        setValue('state', uf);
      } catch (error) {
        setError('cep', { type: 'custom', message: t('general.cepError') });
      }
    }
  };

  return (
    <>
      <Show if={!props.hideSubtitle}>
        <Grid
          item
          xs={4}
          sm={12}
          md={12}
        >
          <Subtitle text={t('contributorBox.form.address.label')} />
        </Grid>
      </Show>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="cep"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-cep-input-text'}
              label={t('contributorBox.form.address.cep')}
              placeholder={t('contributorBox.form.address.cepPlaceholder')}
              mask={'99999-999'}
              onChange={handleCepChange}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={8}
      >
        <Controller
          control={control}
          name="street"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-street-input-text'}
              label={t('contributorBox.form.address.addressField')}
              placeholder={t('contributorBox.form.address.addressPlaceholder')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="street_number"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-street-number-input-text'}
              label={t('contributorBox.form.address.number')}
              placeholder={t('contributorBox.form.address.numberPlaceholder')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={8}
      >
        <Controller
          control={control}
          name="address_complement"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-street-complement-input-text'}
              label={t('contributorBox.form.address.complement')}
              placeholder={t('contributorBox.form.address.complementPlaceholder')}
              labelBold
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="neighborhood"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-neighborhood-input-text'}
              label={t('contributorBox.form.address.neighborhood')}
              placeholder={t('contributorBox.form.address.neighborhoodPlaceholder')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-state-input-text'}
              label={t('contributorBox.form.address.state')}
              placeholder={t('contributorBox.form.address.state')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-city-input-text'}
              label={t('contributorBox.form.address.city')}
              placeholder={t('contributorBox.form.address.city')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="document"
          render={({ field }) => (
            <InputFile
              {...field}
              label={t('contributorBox.form.address.addressDocument')}
              labelBold
              register={register}
              setValue={setValue}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name="documentDate"
          render={({ field }) => (
            <InputDate
              {...field}
              dataCy={'cypress-inputdate-documentdate'}
              label={t('contributorBox.form.address.documentDate')}
              placeholder={t('contributorBox.form.address.datePlaceholder')}
            />
          )}
        />
      </Grid>
    </>
  );
}
