import { Grid } from '@mui/material';
import { useContext } from 'react';
import { Toast } from '../Toast';
import styles from './styles.module.scss';
import { HomeContext } from '../../context/homeContext';

export function ToastsList() {
  const { toasts } = useContext(HomeContext);

  return (
    <div className={styles.toast}>
      <Grid>
        {toasts.map((toast: any) => (
          <Toast
            key={toast.id}
            type={toast.type}
            collapse
            text={toast.text}
            isCloseToast={toast.isCloseToast === undefined ? true : toast.isCloseToast}
            textLink={toast.textLink}
            action={toast.action}
            open
          />
        ))}
      </Grid>
    </div>
  );
}
