import { ContributorSearch } from '../../../@types/activeDebt/debtBox';
import Format from '../../../helpers/format';
import styles from './styles.module.scss';

type SearchResultsProps = {
  result?: ContributorSearch;
};

export function SearchResults(props: SearchResultsProps) {
  return props.result ? (
    <div className={ styles.container }>
      <div>
        <span className={styles.title}>Nº de contribuintes: </span>
        <span>{props.result.total_contributors}</span>
      </div>
      <div>
        <span className={styles.title}>Nº de inscrições encontradas: </span>
        <span>{props.result.inscriptions_count}</span>
      </div>
      <div>
        <span className={styles.title}>Valor total das inscrições: </span>
        <span>{Format.currency(props.result.total_balance)}</span>
      </div>
      <div>
        <span className={styles.title}>Valor total dos requerimentos: </span>
        <span>{Format.currency(props.result.total_balance_requeriments)}</span>
      </div>
    </div>
  ) : <></>;
}
