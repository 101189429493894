import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  FormControl,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  FaChevronDown, FaRegQuestionCircle,
} from 'react-icons/fa';
import { useEffect, useState } from 'react';
import MenuSelect from '@mui/material/Select';
import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';

type SelectProps = {
  dataCy?: string;
  options: OptionType[];
  className?: string;
  classNameInput?: string;
  popperStyle?: string;
  placeholder?: string;
  label?: string;
  tooltip?: string;
  value?: OptionType;
  disabled?: boolean;
  defaultValue?: OptionType;
  onChange?: (value: OptionType | null) => void;
  required?: boolean;
  returnValue?: boolean;
  urlToUpdate?: (input: string) => Promise<any>;
  labelBold?: boolean;
  onlySelect?: boolean;
  noOptionsMessage?: string;
  disableClearable?: boolean;
};

type OptionType = {
  value: any,
  label: string,
};

type Response = {
  label: string;
  value: string | number;
};

export default function Select(props: SelectProps) {
  const [text, setText] = useState('');

  const onChange = (el: any, val: any) => {
    if (props.onChange) {
      if (props.returnValue) {
        props.onChange(val?.value);
      } else {
        props.onChange(val);
      }
    }
  };

  const loadData = async () => {
    if (props.urlToUpdate) {
      if (text.length > 2) {
        const result: Response[] = await props.urlToUpdate(text);
        return result;
      }
      return undefined;
    }
    return [];
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`searchToSelect-${text}`],
    queryFn: loadData,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [text]);

  const handleUpdateList = async (value: string) => {
    if (props.urlToUpdate) {
      setText(value);
    }
  };

  const handleChange = (event: any) => {
    if (props.onChange) {
      if (props.returnValue) {
        props.onChange(event.target.value);
      } else {
        props.onChange(event);
      }
    }
  };

  const getEmptyMessage = () => {
    if (isLoading || isRefetching) {
      return 'Buscando';
    }
    if (props.noOptionsMessage) {
      return props.noOptionsMessage;
    }
    return t('general.emptyOptions');
  };

  const filterOptions = (optionsToSearch: OptionType[], state: FilterOptionsState<OptionType>) => {
    if (props.urlToUpdate) {
      return optionsToSearch;
    }
    const defaultFilterOptions = createFilterOptions<OptionType>();
    return defaultFilterOptions(optionsToSearch, state);
  };

  return (
    <div className={`${styles.container} ${props.className}`}>
      {
        props.onlySelect
          ? (
            <FormControl
              className={styles.inputSelect}
              data-cy={props.dataCy}
              variant={'standard'}
              size="small"
              fullWidth
            >
              <MenuSelect
                onChange={handleChange}
                disableUnderline
                defaultValue={props.options[0]?.value}
                IconComponent={(iconProps: any) => (
                  <div className={styles.customIcon}>
                    <FaChevronDown {...iconProps} />
                  </div>
                )}
                renderValue={(value: string) => {
                  const optionLabel = props.options.find((option) => option.value === value)?.label;
                  return <span className={styles.optionCustom}>{optionLabel}</span>;
                }}
                classes={{
                  select: styles.select,
                }}
              >
                {
                  props.options.map((option, index) => (
                    <MenuItem
                      value={option.value}
                      className={styles.optionCustom}
                      key={(Math.random() + index).toString(36).substring(1)}
                    >
                        {option.label}
                    </MenuItem>
                  ))
                }
              </MenuSelect>
            </FormControl>
          ) : (
            <>
              <div className={ styles.info }>
                {props.label && <Label text={props.label} required={props.required} bold={props.labelBold} />}
                {props.tooltip
                  ? <Tooltip title={ props.tooltip }>
                      <div className={styles.containerTag}>
                        <FaRegQuestionCircle />
                      </div>
                    </ Tooltip>
                  : null}
              </div>
              <Autocomplete
                options={props.urlToUpdate ? data || [] : props.options}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                getOptionLabel={(option) => option.label}
                disableListWrap
                popupIcon={props.urlToUpdate ? null : <FaChevronDown size={13} className={styles.popupIcon}/>}
                classes={{
                  input: `${styles.input} ${styles.inputGroup} ${props.disabled ? styles.disabled : ''} ${props.classNameInput}`,
                  inputRoot: styles.inputRoot,
                  option: `${styles.option} ${props.dataCy}`,
                  popper: `${styles.popper} ${props.popperStyle}`,
                  noOptions: styles.noOptions,
                  clearIndicator: styles.clearIndicator,
                }}
                value={ props.value }
                onChange={onChange}
                noOptionsText={getEmptyMessage()}
                disableClearable={props.disableClearable}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    required={props.required}
                    onChange={(event) => handleUpdateList(event.target.value)}
                    inputProps={{
                      ...params.inputProps,
                      'data-cy': props.dataCy,
                    }}
                  />
                )}
              />
            </>
          )
      }
    </div>
  );
}
