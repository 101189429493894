import { Grid, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { InputText } from '../InputText';
import { Button } from '../Button';
import Select from '../Select';
import { OptionType } from '../../@types/config';
import DebtBoxService from '../../services/debtBoxService';
import { HomeContext } from '../../context/homeContext';
import styles from './styles.module.scss';
import { Subtitle } from '../Subtitle';
import { RadioGroup } from '../RadioGroup';
import Show from '../Show';

type RepresentativeFormProps = {
  negotiationId: number;
  closeRepresentativeModal: () => void;
  idDocument: (id: number) => void;
};

export function RepresentativeForm(props: RepresentativeFormProps) {
  const { addToasts } = useContext(HomeContext);
  const [representative, setRepresentantive] = useState<OptionType | undefined>();
  const { handleSubmit, control, setValue } = useForm();
  const [representativeType, setRepresentativeType] = useState<OptionType[]>([{ label: '', value: 0 }]);
  const [showForm, setShowForm] = useState(false);

  const negotiationLink = () => {
    window.open(`/payment_request/${props.negotiationId}`);
  };

  const submit = (form: any) => {
    const representativeForm = { ...form, id: representative ? representative.value : '' };
    const formData = {
      payment_request_id: props.negotiationId,
      payment_request_representative: representativeForm,
    };
    if (formData) {
      DebtBoxService.negotiationRepresentative(formData)
        .then((response) => {
          if (response) {
            props.idDocument(response.document_id);
          }
          addToasts({
            type: 'success',
            text: <span>{t('debtBox.newNegotiation.representativeModal.toast.success')} <a className={styles.negotiationLink} onClick={negotiationLink}>{t('debtBox.newNegotiation.representativeModal.toast.link')}</a></span>,
            widthFull: true,
          });
          props.closeRepresentativeModal();
        })
        .catch(() => {
          addToasts({
            type: 'error',
            text: t('debtBox.newNegotiation.representativeModal.toast.error'),
            widthFull: true,
          });
        });
    }
  };

  const getRepresentativeData = async () => {
    if (representative) {
      DebtBoxService.getRepresentativeData(representative.value)
        .then((response) => {
          if (response) {
            setValue('name', response.name);
            setValue('rg', response.rg);
            setValue('cpf', response.cpf);
            setValue('cep', response.cep);
            setValue('street', response.street);
            setValue('street_number', response.street_number);
            setValue('complement', response.address_complement);
            setValue('neighborhood', response.neighborhood);
            setValue('city', response.city);
            setValue('state', response.state);
            setValue('email', response.email);
            setValue('phone', response.phone);
          }
        });
    }
    return {};
  };

  const gerRepresentativeType = async () => {
    DebtBoxService.getRepresentativeType()
      .then((response) => {
        setRepresentativeType(response.data.negotiator);
      });
  };

  useEffect(() => {
    gerRepresentativeType();
    if (representative) {
      getRepresentativeData();
    }
  }, [representative]);

  const showRepresentativeForm = (val: any) => {
    setShowForm(true);
    setRepresentantive(val || undefined);
  };

  return (
    <form className={styles.representativeForm} onSubmit={handleSubmit(submit)}>
      <Subtitle text={t('debtBox.newNegotiation.representativeModal.form.title')} />
      <Grid
        className={styles.container}
        container
        columns={{ xs: 4, sm: 12, md: 12 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={4} sm={12} md={12}>
          <Controller
            control={control}
            name="negotiator"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                dataCy={'applicant-type'}
                notDefaultOptions
                label={t('debtBox.newNegotiation.representativeModal.form.type')}
                options={representativeType || []}
                onChange={(e) => onChange(e?.target.value ?? '')}
                value={value}
                noRow
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={6} md={6}>
          <Controller
            control={control}
            name="id"
            render={() => (
              <Select
                label={t('debtBox.newNegotiation.representativeModal.form.representative')}
                placeholder={t('debtBox.newNegotiation.representativeModal.form.representativePh')}
                options={[]}
                value={representative}
                urlToUpdate={DebtBoxService.getRepresentativeList}
                onChange={(val) => showRepresentativeForm(val || undefined)}
                dataCy="search-representative"
                labelBold
              />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Tooltip title={t('debtBox.newNegotiation.representativeModal.form.tooltip')}>
            <div><FaPlusCircle className={styles.newRepresentative} onClick={() => setShowForm(true)}/></div>
          </Tooltip>
        </Grid>
        <Show if={showForm}>
          <Grid className={styles.sections} xs={4} sm={12} md={12}>
            <Subtitle text={t('debtBox.newNegotiation.representativeModal.form.data')} />
          </Grid>
          <Grid item xs={4} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.namePh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.name')}
                  dataCy={'cypress-contributor-name-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="rg"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.rgPh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.rg')}
                  dataCy={'cypress-contributor-rg-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="cpf"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.cpfPh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.cpf')}
                  dataCy={'cypress-contributor-cpf-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.emailPh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.email')}
                  dataCy={'cypress-contributor-email-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.phonePh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.phone')}
                  dataCy={'cypress-contributor-phone-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid className={styles.sections} xs={4} sm={12} md={12}>
            <Subtitle text={t('debtBox.newNegotiation.representativeModal.form.address')} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="cep"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.postalCodePh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.postalCode')}
                  dataCy={'cypress-contributor-cep-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="street"
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.streetPh')}
                  label={t('debtBox.newNegotiation.representativeModal.form.street')}
                  dataCy={'cypress-contributor-street-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Controller
              control={control}
              name="street_number"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('debtBox.newNegotiation.representativeModal.form.streetNumber')}
                  dataCy={'cypress-contributor-street_number-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="complement"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('debtBox.newNegotiation.representativeModal.form.complement')}
                  dataCy={'cypress-contributor-complement-input-text'}
                  labelBold
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="neighborhood"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('debtBox.newNegotiation.representativeModal.form.neighborhood')}
                  dataCy={'cypress-contributor-neighborhood-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('debtBox.newNegotiation.representativeModal.form.city')}
                  dataCy={'cypress-contributor-city-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              control={control}
              name={'state'}
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder={t('debtBox.newNegotiation.representativeModal.form.state')}
                  label={t('contributorBox.form.basic.name')}
                  dataCy={'cypress-contributor-state-input-text'}
                  labelBold
                  required
                />
              )}
            />
          </Grid>
        </Show>
        <Grid className={styles.submit} item xs={12} sm={12} md={12}>
          <Button
            dataCy='submit-applicant-form'
            title={t('debtBox.newNegotiation.representativeModal.form.save')}
            buttonType='primary'
            size='flat'
            type='submit'
            round
          />
        </Grid>
      </Grid>
    </form>
  );
}
