export default {
  translations: {
    general: {
      concluded: 'Concluído',
      confirm: 'Confirmar',
      edit: 'Editar',
      destroy: 'Excluir',
      requeried: 'Campo obrigatório',
      noOptions: 'Sem resultados',
      emptyOptions: 'Sem opções',
      changePassword: 'Alterar senha',
      yes: 'Sim',
      no: 'Não',
      cancel: 'Cancelar',
      tryAgain: 'Tentar novamente',
      refresh: 'Atualizar',
      save: 'Salvar',
      dashboard: 'Meu dashboard',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      cep: 'CEP inválido',
      cepError: 'Não foi possivel obter o endereço',
      date: 'A data deve ser maior ou igual à data de hoje',
      invalidDate: 'Data inválida',
      darkMode: 'Modo escuro',
      lightMode: 'Modo claro',
      message: {
        emptyList: 'Nenhum processo foi encontrado na sua caixa.',
        label: 'Aplique sua pesquisa no Acervo da PGM',

      },
      password: {
        current: 'Senha atual',
        new: 'Nova senha',
        newPlaceholder: 'Digite a nova senha',
        confirmation: 'Confirmação',
        confirmationPlaceholder: 'Confirme a nova senha',
        validation: 'Não corresponde com a nova senha',
        requeried: 'Campo obrigatório',
        success: 'Senha alterada com sucesso',
        error: 'Erro ao alterar senha. Verifique se a senha atual está correta.',
      },
      login: {
        password: 'Senha',
        passwordPlaceholder: 'Sua senha',
        username: 'Usuário',
        usernamePlaceholder: 'Seu e-mail ou CPF',
        signIn: 'Acessar',
        forgotPassword: 'Esqueceu a senha?',
        contact: 'Dúvidas? <a href="https://wa.me/558589260327?agilis=true" target="_blank">Fale conosco</a>',
        footer: 'Desenvolvido pela Procuradoria Geral do Município de Fortaleza (PGM). 2022.',
        slogan: 'Sua procuradoria digital, simples e ágil',
        emptyFields: 'Digite um usuário e senha',
        forgotPasswordModal: {
          emailInstructions: 'Você receberá um e-mail com instruções para troca de senha.',
          email: 'Digite seu e-mail',
          emailPlaceholder: 'Seu e-mail ou usuário',
          submit: 'Enviar',
          error: 'Falha ao buscar o usuário',
        },
        recoverPassword: {
          title: 'Troque sua senha',
          newPassword: 'Nova senha',
          newPasswordPlaceholder: 'Digite sua nova senha',
          confirmPassword: 'Confirmar senha',
          submit: 'Salvar nova senha',
          differentPassword: 'As senhas devem ser iguais',
          emailSend: 'Aguarde, em instantes você irá receber o e-mail para troca de senha.',
          error: 'Algo deu errado',
          success: 'Senha alterada com sucesso, <a href="/">clique aqui</a> para fazer o login',
        },
      },
      toast: {
        warning: 'Aviso',
        info: 'Info',
        success: 'Sucesso',
        error: 'Erro',
      },
      logout: {
        label: 'Sair do Ágilis',
        text: 'Deseja realmente sair do Ágilis',
      },
      changePicture: {
        title: 'Alterar foto do perfil',
        text: 'Uma foto no seu perfil ajuda a outros colaboradores a reconhecerem você.',
        add: 'Adicionar foto de perfil',
        change: 'Mudar foto de perfil',
        remove: 'Remover foto de perfil',
        deleteError: 'Erro ao remover foto de perfil. Tente novamente mais tarde.',
        deleteSuccess: 'Foto de perfil removida com sucesso',
        changeError: 'Erro ao alterar foto de perfil. Tente novamente mais tarde.',
        changeSuccess: 'Foto de perfil alterada com sucesso',
      },
      modalOutSideConfirmationAlert: {
        title: 'Deseja realmente sair?',
        text: 'As ações realizadas neste andamento serão perdidas',
      },
    },
    topbar: {
      rightItems: {
        user_name: 'Nome do Usuário',
      },
    },
    sidebar: {
      modules: {
        title: 'Módulos',
        systems: 'Outros sistemas',
        active_debt: {
          title: 'Dívida ativa',
          home: 'Homescreen',
          requeriment: {
            title: 'Requerimento',
            inscribe: 'Inscrever requerimento',
            request_analysis: 'Solicitar análise',
            register: 'Cadastrar requerimento',
          },
          inscription: {
            title: 'Inscrições',
            consult_advanced_debit: 'Consultar avançada de débito',
            extract_debit: 'Extrato de débitos',
            statement_responsibility: 'Emitir declaração de responsabilidade',
            extinguish_suspend_credit: 'Extinguir/suspender crédito',
            ownership_change: 'Alteração de titularidade',
            suspension_extinction_requests: 'Solicitações de suspensão/extinção',
            migration_registered_debts: 'Migração de dívidas inscritas',
            approve_migration_registered_debts: 'Aprovar migração de dívidas inscritas',
            retroactive_calculation: 'Cálculo retroativo',
          },
          protest: {
            title: 'Protesto',
            request_protest: 'Solicitar protesto',
            abandonment_cancellation: 'Desistência/cancelamento',
          },
          judgment: {
            title: 'Ajuizamento',
            request: 'Solicitar ajuizamento',
            forward: 'Encaminhar ajuizamento',
          },
          management: {
            title: 'Gestão',
            active_debt_diagnosis: 'Diagnóstico da dívida ativa',
            active_debt_diagnosis_phase: 'Diagnóstico da dívida ativa por fase',
            active_debt_diagnosis_range: 'Diagnóstico da dívida ativa por faixa de valor',
            geolocation_contributors: 'Geolocalização dos contribuintes',
            geolocation_contributors_regions: 'Geolocalização dos contribuintes por bairros/regionais',
            overview_municipal_active_debt: 'Visão geral da dívida ativa municipal',
            overview_prodat: 'Visão geral da dívida ativa (PRODAT)',
            overview_installment_management: 'Visão gerencial de parcelamentos',
            collection_management: 'Gestão da arrecadação',
            overview_judgment: 'Visão gerencial do ajuizamento',
            overview_protest: 'Visão gerencial do protesto',
            evolution_debt_stock: 'Evolução do estoque da dívida',
            overview_phases_debt_inscriptions: 'Visão das fases das inscrições dos débitos',
          },
          reports: {
            title: 'Relatórios',
            extract_inscriptions: 'Extrato de inscrições',
            general_collection: 'Arrecadação geral',
            tax_collection: 'Arrecadação por tributo',
            collection_charges: 'Arrecadação de encargos',
            management_view_active_debt: 'Visão gerencial da dívida ativa',
            manage_installments_agreements: 'Gerenciar parcelamentos/acordos',
            report_requests_API: 'Relatório das solicitações de requerimento via API',
          },
          settings: {
            title: 'Configurações',
            inform_tax: 'Informar taxas',
            calculation_standards: 'Padrões de cálculo',
            blocked_inscriptions: 'Inscrições bloqueadas',
            refis: 'REFIS',
            scheduling: 'Agendamento',
          },
          debt_box: 'Caixa de dívidas',
          financial: {
            title: 'Financeiro',
          },
          contributor_box: {
            contributor: 'Contribuinte',
            contributorArea: 'Área do contribuinte',
            createEditContributor: 'Cadastrar/editar contribuinte',
          },
        },
        support: {
          title: 'Suporte',
          suggestion_box: 'Caixa de sugestões',
          contact_us: 'Fale conosco',
          faq: 'FAQ (Dúvidas Frequentes)',
          settings_sistem: 'Configurações do sistema',
        },
        settings: {
          user_management: 'Gestão de usuários',
        },
      },
    },
  },
};
