import { PiNewspaperClipping } from 'react-icons/pi';
import { t } from 'i18next';
import { Card } from '../../../../components/Reports/Card';
import { Table } from '../../../../components/Table';
import styles from './styles.module.scss';
import Format from '../../../../helpers/format';
import {
  OpenDebts, SummaryNegotiationDebts, SummaryOpenDebts,
} from '../../../../@types/activeDebt/reports';

type DebtSummaryProps = {
  openData: SummaryOpenDebts;
  negotiationData: SummaryNegotiationDebts;
};

export function DebtSummary(props: DebtSummaryProps) {
  return (
    <div className={styles.container} data-cy="summary-debts">
        {props.openData && (
          <Card iconTitle={<PiNewspaperClipping />} title={t('reports.fullStatement.debtSummary.cardTitle')} largeClass={'Col-12'}>
            <div className={styles.summaryTable}>
              <Table>
                <thead>
                  <tr>
                    <th data-cy="data-tribute">{t('reports.fullStatement.debtSummary.openDebtsTable.tribute')}</th>
                    <th data-cy="data-current-value" className={styles.leftBorder}>{t('reports.fullStatement.debtSummary.openDebtsTable.currentBalance')}</th>
                    <th data-cy="data-punitive-fines">{t('reports.fullStatement.debtSummary.openDebtsTable.currentPunitiveFine')}</th>
                    <th data-cy="data-monetary-correction">{t('reports.fullStatement.debtSummary.openDebtsTable.monetaryCorrection')}</th>
                    <th data-cy="data-fines">{t('reports.fullStatement.debtSummary.openDebtsTable.fees')}</th>
                    <th data-cy="data-morarotiun-fines">{t('reports.fullStatement.debtSummary.openDebtsTable.moratoriumFines')}</th>
                    <th data-cy="data-charges">{t('reports.fullStatement.debtSummary.openDebtsTable.charges')}</th>
                    <th data-cy="data-balance">{t('reports.fullStatement.debtSummary.openDebtsTable.outstadingBalance')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.openData.result.map((od: OpenDebts) => (
                      <tr>
                        <td>{od.receipt_type_description}</td>
                        <td className={styles.leftBorder}>{Format.currency(od.sum_total_main_value)}</td>
                        <td>{Format.currency(od.sum_total_punitive_fines)}</td>
                        <td>{Format.currency(od.sum_monetary_correction)}</td>
                        <td>{Format.currency(od.sum_interest_value)}</td>
                        <td>{Format.currency(od.sum_moratorium_fines)}</td>
                        <td>{Format.currency(od.sum_fee_value)}</td>
                        <td>{Format.currency(od.total_balance_to_legal_action)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
            <div className={styles.footerInfo}>
              <i>{t('reports.fullStatement.debtSummary.openDebtsTable.footerInfo')}</i>
              <span>{t('reports.fullStatement.debtSummary.openDebtsTable.totalBalance')} <strong>{Format.currency(props.openData.sum_total_balance)}</strong></span>
            </div>
          </Card>
        )}
        {/* {props.negotiationData && props.negotiationData.result.length !== 0 && (
          <Card iconTitle={<PiNewspaperClipping />} title={'RESUMO DA DÍVIDA EM NEGOCIAÇÃO'} largeClass={'Col-12'}>
            <div className={styles.summaryTable}>
              <Table>
                <thead>
                  <tr>
                    <th>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.tribute')}</th>
                    <th className={styles.leftBorder}>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.negotiatedValue')}</th>
                    <th>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.paidValue')}</th>
                    <th className={`${styles.leftBorder} ${styles.topBorder}`}>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.overdueA')}</th>
                    <th className={styles.topBorder}>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.overdueB')}</th>
                    <th className={styles.topBorder}>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.total')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.negotiationData.result.map((nd: NegotiationDebts) => (
                      <tr>
                        <td>{nd.receipt_type_description}</td>
                        <td className={styles.leftBorder}>{Format.currency(nd.balance)}</td>
                        <td>{Format.currency(nd.amount_paid_main)}</td>
                        <td className={styles.leftBorder}>{Format.currency(nd.total_overdue_installments)}</td>
                        <td>{Format.currency(nd.total_not_overdue_installments)}</td>
                        <td>{Format.currency(nd.total_overdue_and_not_overdue_installments)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
            <div className={styles.footerInfoNegotiation}>
              <span>{t('reports.fullStatement.debtSummary.negotiationDebtsTable.remainingTotal')} <strong>{Format.currency(props.negotiationData.sum_total_remaining_value)}</strong></span>
            </div>
          </Card>
        )} */}
    </div>
  );
}
