import { Trans } from 'react-i18next/TransWithoutContext';
import styles from './styles.module.scss';

export function DebtInfo() {
  const i18nKey = 'debtBox.results.inscriptions.debts.defaultCalculation.debtInfo';
  return (
    <div data-cy='data-cy-debts-info' className={styles.debtInfoContainer}>
      <span className={styles.firstElement}>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.balance`} components={{ bold: <strong /> }} />
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.currentBalance`} components={{ bold: <strong />, italic: <i /> }} />
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.punitiveFine`} components={{ bold: <strong />, italic: <i /> }} />
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.monetarieCorrection`} components={{ bold: <strong /> }}/>
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.interests`} components={{ bold: <strong /> }}/>
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.moratoriumFine`} components={{ bold: <strong /> }}/>
      </span>
      <span>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.charges`} components={{ bold: <strong /> }}/>
      </span>
      <span className={styles.lastElement}>
        <Trans className={styles.balance} i18nKey={`${i18nKey}.obs`} components={{ bold: <strong />, italic: <i /> }}/>
      </span>
    </div>
  );
}
