import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Footer } from '../../../components/Reports/Footer';
import { Header } from '../../../components/Reports/Header';
import { Main } from '../../../components/Reports/Main';
import styles from './styles.module.scss';
import { DebtSummary } from './DebtSummary';
import { GeneralInfo } from './GeneralInfos';
import { ReceiptTypeSumary } from './ReceiptTypeSumary';
import { PortalFooter } from './PortalFooter';
import Service from '../../../services/inscriptionService';

export function FullStatement() {
  document.body.classList.remove('darkModeTheme');
  const { id } = useParams();
  const { detailed } = useParams();

  const getContributorData = async () => {
    if (id) {
      const contributorId = parseInt(id, 10);
      const result = await Service.getContributorSummaryData(contributorId);
      return result;
    }
    return '';
  };

  const {
    data,
  } = useQuery({
    queryKey: ['paymentGroups', id],
    queryFn: getContributorData,
    enabled: true,
  });

  const getSummaryOpenDebts = async () => {
    if (id) {
      const contributorId = parseInt(id, 10);
      const body = { contributor_id: contributorId };
      const result = await Service.getSummaryOpenDebts(body);
      return result;
    }
    return '';
  };

  const {
    data: summaryOpenDebts,
  } = useQuery({
    queryKey: ['getSummaryOpenDebts'],
    queryFn: getSummaryOpenDebts,
    enabled: true,
  });

  const getSummaryNegotiationDebts = async () => {
    if (id) {
      const contributorId = parseInt(id, 10);
      const body = { contributor_id: contributorId };
      const result = await Service.getSummaryNegotiationDebts(body);
      return result;
    }
    return '';
  };

  const {
    data: summaryNegotiationDebts,
  } = useQuery({
    queryKey: ['getSummaryNegotiationDebts'],
    queryFn: getSummaryNegotiationDebts,
    enabled: true,
  });

  return (
    <div className={styles.container}>
      <Header
        type='full'
        title={t('reports.fullStatement.title')}
        subtitle={t('reports.fullStatement.subtitle')}
      />
      <Main>
        <GeneralInfo data={data}/>
        <DebtSummary openData={summaryOpenDebts} negotiationData={summaryNegotiationDebts} />
      </Main>
      {data && data.receipt_types.map((rt: any) => (
        <ReceiptTypeSumary
          receipTypeDescription={rt}
          contributorId={id}
          detailedReport={detailed}
          activeInscriptions={rt.active_inscriptions_ids}
          negotiationInscriptions={rt.active_negotiation_inscriptions_ids}
          extinctiInscriptions={rt.extinct_inscriptions_ids}
        />
      ))}
      <Main>
        <PortalFooter />
      </Main>
      <Footer />
    </div>
  );
}
