import { t } from 'i18next';
import { useState } from 'react';
import { format } from 'date-fns';
import { DropFiles } from '../../../../components/DropFiles';
import { Subtitle } from '../../../../components/Subtitle';
import styles from './styles.module.scss';
import { AttachDocument as AttachDocumentType, OptionType } from '../../../../@types/config';
import { ListFiles } from '../../../../components/DropFiles/ListFiles';
import Screen from '../../../../helpers/screen';
import Show from '../../../../components/Show';
import { Button } from '../../../../components/Button';
import { AttachedDocument } from '../../../../@types/activeDebt/inscription';

type AttachmentProps = {
  handleFunction?: (files: AttachedDocument[]) => void;
  documentType: OptionType[];
};

export function AttachDocument(props: AttachmentProps) {
  const [files, setFiles] = useState<AttachDocumentType[]>([]);
  const types = files.map((file) => (file.type));
  const typeList = props.documentType;

  const isMobile = Screen.isMobile();

  const handleType = (document: AttachDocumentType, type: string) => {
    const newState = files.map((obj) => {
      if (document.id === obj.id) {
        return {
          ...obj, type,
        };
      }
      return obj;
    });
    setFiles(newState);
  };

  const removeFile = (filesParam: AttachDocumentType) => {
    const newState = files.filter((el) => el.id !== filesParam.id);
    setFiles(newState);
  };

  const handleDrop = async (filesParam: File[]) => {
    const verifiedFiles = filesParam.map(async (file) => {
      const newFile: AttachDocumentType = {
        id: (Math.random() + 1).toString(36).substring(1),
        file,
        type: ' ',
        created_at: format(new Date(), 'dd/MM/yyyy'),
      };
      return newFile;
    });

    const newFiles = await Promise.all(verifiedFiles);
    setFiles((current) => [...current, ...newFiles]);
  };

  return (
    <div className={ styles.container }>
      <Subtitle
        text={t('paymentRequest.documents.attachDocument.subtitle')}
        regularText
      />
      <DropFiles isMobile={isMobile} onDrop={handleDrop}/>

      <Show if={files.length > 0}>
        <ListFiles
          documents={files}
          removeFile={removeFile}
          handleType={handleType}
          types={typeList}
          isMobile={isMobile}
        />
        <div className={styles.attachButton}>
          <Button
            title={t('inscription.documents.attachDocument')}
            buttonType='primary'
            onClick={() => props.handleFunction?.(files)}
            disabled={types.some((type) => type === ' ')}
            round
          />
      </div>
      </Show>
    </div>
  );
}
