import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';

export type ItemType = {
  label: string;
  display?: boolean;
  enabled?: boolean;
  onClick: (e: any) => void;
};

type DropdownButtonProps = {
  title: string;
  items: ItemType[];
  enabled?: boolean;
};

export function DropdownButton(props: DropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (e: any, item: ItemType) => {
    if (item.enabled !== false) {
      item.onClick(e);
    }
    setIsOpen(false);
  };

  const renderOption = (item: ItemType) => {
    if (item.display !== false) {
      return (
        <button
          className={`${styles.listContent} ${!item.enabled ? styles.disabled : ''}`}
          onClick={(e) => handleItemClick(e, item)}
        >
          <span className={styles.title}>{item.label}</span>
        </button>
      );
    }
    return <></>;
  };

  const handleButtonCLick = () => {
    if (props.enabled) {
      handleClick();
    }
  };

  useEffect(() => {
    if (!props.enabled) {
      setIsOpen(false);
    }
  }, [props.enabled]);

  return (
    <div className={styles.container}>
      <button
        className={`${styles.buttonContent} ${isOpen ? styles.open : ''} ${!props.enabled ? styles.disabled : ''}`}
        onClick={() => handleButtonCLick()}
      >
        <span className={ `${styles.title}` }>
          {props.title}
        </span>
        <div className={`${styles.icon}`}>
          { isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </button>
      <Collapse in={ isOpen } className={styles.list}>
        {props.items.map((item) => renderOption(item))}
      </Collapse>
    </div>
  );
}
