import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { Button } from '../../../components/Button';
import { BasicData, BasicDataType } from './BasicData';
import { AddressData, AddressDataType } from './AddressData';
import { ContactData, ContactDataType } from './ContactData';
import { CurrentUser } from '../../../@types/currentUser';
import { HomeContext } from '../../../context/homeContext';
import ContributorService from '../../../services/contributorService';
import styles from './styles.module.scss';

interface FormValues {
  basicData: BasicDataType;
  addressData: AddressDataType;
  contactData: ContactDataType;
}

type FormProps = {
  closeModal: () => void;
};

export function Form(props: FormProps) {
  const [errors, setErrors] = useState();
  const { addToasts } = useContext(HomeContext);
  const methods = useForm<FormValues>();
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);

  const handleValidValues = (legalType: string, value: string) => {
    return legalType === 'individual' ? value : '';
  };

  const submit = async (form: any) => {
    const formData = new FormData();

    formData.append('contributor[name]', form.contributor_name);
    formData.append('contributor[legal_type]', form.legal_type);
    formData.append('contributor[cpf_cnpj]', form.cpf ?? form.cnpj);
    formData.append('contributor[profession]', handleValidValues(form.legal_type, form.profession));
    formData.append('contributor[marital_status]', handleValidValues(form.legal_type, form.marital_status));
    formData.append('contributor[educational_stage]', handleValidValues(form.legal_type, form.educational_stage));
    formData.append('contributor[gender]', handleValidValues(form.legal_type, form.gender));
    formData.append('contributor[birth_date]', handleValidValues(form.legal_type, form.birth_date));
    formData.append('contributor[death_date]', handleValidValues(form.legal_type, form.death_date));

    formData.append('contributor[addresses_attributes][0][cep]', form.cep);
    formData.append('contributor[addresses_attributes][0][street]', form.street);
    formData.append('contributor[addresses_attributes][0][street_number]', form.street_number);
    formData.append('contributor[addresses_attributes][0][address_complement]', form.address_complement ?? '');
    formData.append('contributor[addresses_attributes][0][neighborhood]', form.neighborhood);
    formData.append('contributor[addresses_attributes][0][state]', form.state);
    formData.append('contributor[addresses_attributes][0][city]', form.city);
    formData.append('contributor[addresses_attributes][0][individual_id]', currentUser.id.toString());
    formData.append('contributor[addresses_attributes][0][main]', 'true');

    if (form.document) {
      formData.append('contributor[addresses_attributes][0][address_declaration_attributes][archive]', form.document);
    }
    formData.append('contributor[addresses_attributes][0][address_declaration_attributes][document_date]', form.documentDate);

    formData.append('contributor[contributor_contact_histories][cell_phone]', form.cell_phone || '');
    formData.append('contributor[contributor_contact_histories][telephone]', form.telephone || '');
    formData.append('contributor[contributor_contact_histories][email]', form.email);
    formData.append('contributor[contributor_contact_histories][cell_phone_whatsapp]', 'true');
    formData.append('contributor[contributor_contact_histories][current_contact]', 'true');
    formData.append('contributor[contributor_contact_histories][individual_id]', currentUser.id.toString());

    try {
      const res = await ContributorService.addContributor(formData);
      if (res.status === 200 || res.status === 201) {
        addToasts({
          type: 'success',
          text: t('contributorBox.form.success'),
          widthFull: true,
        });
        props.closeModal();
      }
    } catch (error: any) {
      setErrors(error.response.data);
      addToasts({
        type: 'error',
        text: t('contributorBox.form.error'),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={methods.handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={{ xs: 2, md: 2 }}
        >
          <BasicData errors={errors} />
          <AddressData />
          <ContactData />
          <Grid className={styles.footer} item xs={4} sm={12} md={12}>
            <Button
              dataCy={'cypress-submit-button'}
              title={t('contributorBox.form.submit')}
              buttonType='primary'
              round
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
