import { useContext, useEffect, useState } from 'react';
import { Skeleton, Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import styles from './styles.module.scss';
import DebtBoxService from '../../../../services/debtBoxService';
import { Inscription } from '../../PaymentGroups/PaymentGroup/Inscription';
import { HomeContext } from '../../../../context/homeContext';
import Format from '../../../../helpers/format';
import { InstallmentSimulation, PaymentRequestData } from '../../../../@types/activeDebt/paymentRequest';
import Show from '../../../../components/Show';
import Select from '../../../../components/Select';
import { OptionType } from '../../../../@types/config';
import { Button } from '../../../../components/Button';
import ReportService from '../../../../services/reportService';
import { downloadFile } from '../../../../helpers/downloadPDF';

type PaymentRequestGroupProps = {
  contributorId?: number;
  receiptTypeId?: number;
  onClick: () => void;
  onChangeInstallments: (value: number) => void;
};

export function PaymentRequestGroup(props: PaymentRequestGroupProps) {
  const { addToasts, inscriptionsSelected } = useContext(HomeContext);
  const [data, setData] = useState<PaymentRequestData>();
  const [isLoading, setIsLoading] = useState(false);
  const [inscriptionsValues, setInscriptionsValues] = useState<any>();
  const [installments, setInstallments] = useState<number | undefined>();
  const loadData = async () => {
    if (inscriptionsSelected.length > 0) {
      const inscriptionsIds = inscriptionsSelected.map((i) => i.id).flat();
      setIsLoading(true);
      const result = await DebtBoxService.getInscriptionsByPaymentGroup(inscriptionsIds);
      let simulationData;
      if (props.contributorId && props.receiptTypeId && inscriptionsIds.length > 0) {
        const dataToSimulate = {
          contributor_id: props.contributorId,
          receipt_type_id: props.receiptTypeId,
          inscriptions_ids: inscriptionsIds,
        };
        await DebtBoxService.getTotalValueByInscriptions(props.contributorId, props.receiptTypeId, inscriptionsIds)
          .then(async (res) => {
            setInscriptionsValues(res);
            simulationData = await DebtBoxService.getPaymentRequestSimulation(dataToSimulate);
            const resultData: PaymentRequestData = {
              name: '',
              payment_group_code: '',
              total_balance: res.total_balance,
              total_balance_to_legal_action: res.total_balance,
              inscriptions_ids: inscriptionsIds,
              inscriptions: result,
              simulation: simulationData,
              chosen_installment: undefined,
            };
            setData(resultData);
            setIsLoading(false);
          });
      }
    }
  };

  const onChooseNumInstallment = (num: any) => {
    if (data) {
      setInstallments(num.value);
      props.onChangeInstallments(num.value);
    }
  };

  const parseOptions = (list: InstallmentSimulation[] | undefined): OptionType[] => {
    if (list) {
      const options: OptionType[] = list.map((installment) => {
        return {
          value: installment.num_installments,
          label: `${installment.num_installments}x ${installment.simulation}`,
        };
      });
      return options;
    }
    return [];
  };

  const downloadSimulation = async () => {
    const { contributorId, receiptTypeId } = props;
    if (contributorId && receiptTypeId) {
      try {
        addToasts({
          type: 'info',
          text: t('debtBox.report.paymentRequestSimulation.info'),
          widthFull: true,
        });
        const allInscriptionsIds = inscriptionsValues.inscriptions.map((i: any) => i.id);
        const res = await ReportService.getPaymentRequestSimulation(contributorId, receiptTypeId, allInscriptionsIds);
        downloadFile(res, 'simulação.pdf');
      } catch (error) {
        addToasts({
          type: 'error',
          text: t('debtBox.report.paymentRequestSimulation.error'),
          widthFull: true,
        });
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [inscriptionsSelected]);

  return (
    <Grid
      container
      className={styles.container}
      columns={{ xs: 2, sm: 6, md: 12 }}
      spacing={{ xs: 1, md: 2 }}
    >
      <Grid item xs={2} sm={6} md={12}>
        <Show if={isLoading}>
          <Skeleton height={300} variant='rectangular' className={styles.skeleton} />
        </Show>
        <Show if={!isLoading}>
          <Grid className={styles.action} item xs={2} sm={6} md={12}>
            <div className={styles.button} onClick={() => downloadSimulation()}>
              <span>{t('debtBox.newNegotiation.modal.simulationPdf')}</span>
              <FaDownload />
            </div>
          </Grid>
          {data && (
              <div className={styles.paymentGroupContainer}>
                <Grid container columns={{ xs: 2, sm: 6, md: 12 }}>
                  <Grid item xs={2} sm={3} md={6}>
                    <span className={styles.paymentGroupName}>
                      {t('debtBox.newNegotiation.modal.paymentTitle')}
                    </span>
                  </Grid>
                  <Grid className={styles.paymentGroupTotal} item xs={2} sm={3} md={6}>
                    <Show if={inscriptionsValues?.total_balance}>
                      <span>
                        <Trans
                          i18nKey='debtBox.newNegotiation.modal.totalGroup'
                          components={{ bold: <strong /> }}
                          values={{
                            value: Format.currency(inscriptionsValues.total_balance),
                            qnt: data.simulation?.maximum_number_installments,
                          }}
                        />
                      </span>
                    </Show>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={6} md={12} className={styles.content}>
                  <Grid item xs={2} sm={6} md={12}>
                    {inscriptionsValues.inscriptions.map((inscription: any) => (
                      <Inscription
                        key={inscription.id}
                        inscription={inscription}
                        inPaymentRequest
                        showDeleteButton
                      />
                    ))}
                  </Grid>
                  <Grid className={styles.info} item xs={2} sm={6} md={12}>
                    <span>
                      <Trans
                        i18nKey='debtBox.newNegotiation.modal.minValueInstallment'
                        components={{ bold: <strong /> }}
                        values={{ value: Format.currency(data.simulation?.minimum_amount_installments || 0) }}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={2} sm={6} md={12}>
                    <span>
                      <Trans
                        i18nKey='debtBox.newNegotiation.modal.maxInstallments'
                        components={{ bold: <strong /> }}
                        values={{ value: data.simulation?.maximum_number_installments }}
                      />
                    </span>
                  </Grid>
                  <Grid className={styles.select} item xs={2} sm={3} md={3}>
                    <Select
                      classNameInput={styles.selectInput}
                      onChange={(value) => onChooseNumInstallment(value)}
                      label={t('debtBox.newNegotiation.modal.label')}
                      placeholder={t('debtBox.newNegotiation.modal.placeholder')}
                      options={parseOptions(data.simulation?.installment_simulations)}
                      labelBold
                      disableClearable
                      dataCy='installment-number-selection'
                    />
                  </Grid>
                </Grid>
              </div>
          )}
          <div className={styles.footer}>
          <div className={styles.observations}>
            <p>{t('debtBox.newNegotiation.obs1')}</p>
            <p>{t('debtBox.newNegotiation.obs2')}</p>
            <p>{t('debtBox.newNegotiation.obs3')}</p>
          </div>

          <div className={styles.submit}>
            <Button
              title={t('debtBox.newNegotiation.submit')}
              round
              disabled={!installments || !inscriptionsValues}
              buttonType='primary'
              onClick={() => props.onClick()}
            />
          </div>
          </div>
        </Show>
      </Grid>
    </Grid>
  );
}
