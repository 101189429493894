export type Info = {
  id: string;
  color: string;
  hoverColor: string;
  textColor: string;
  borderColor?: string;
};

export const INFOS: Info[] = [
  {
    id: 'administrative_inscription',
    color: '#10949E',
    hoverColor: '#01757D',
    textColor: '#FFFFFF',
  },
  {
    id: 'addresses',
    color: '#10949E',
    hoverColor: '#01757D',
    textColor: '#FFFFFF',
  },
  {
    id: 'inscription_base_document',
    color: '#E3A614',
    hoverColor: '#CA9108',
    textColor: '#FFFFFF',
  },
  {
    id: 'taxpayer_declaration',
    color: '#E3A614',
    hoverColor: '#CA9108',
    textColor: '#FFFFFF',
  },
  {
    id: 'notification',
    color: '#E3A614',
    hoverColor: '#CA9108',
    textColor: '#FFFFFF',
  },
  {
    id: 'installment_process',
    color: '#E3A614',
    hoverColor: '#CA9108',
    textColor: '#FFFFFF',
  },
  {
    id: 'other_adm_process',
    color: '#E3A614',
    hoverColor: '#CA9108',
    textColor: '#FFFFFF',
  },
  {
    id: 'debt_state',
    color: '#FFFFFF',
    hoverColor: '#e8e8e8',
    textColor: '#707070',
    borderColor: '#707070',
  },
  {
    id: 'protested',
    color: '#EF771B',
    hoverColor: '#C55C0C',
    textColor: '#FFFFFF',
  },
  {
    id: 'sent_protest',
    color: '#EF771B',
    hoverColor: '#C55C0C',
    textColor: '#FFFFFF',
  },
  {
    id: 'banned_protest',
    color: '#EF771B',
    hoverColor: '#C55C0C',
    textColor: '#FFFFFF',
  },
  {
    id: 'payment_requests',
    color: '#E62155',
    hoverColor: '#B10633',
    textColor: '#FFFFFF',
  },
  {
    id: 'payment_requests',
    color: '#E62155',
    hoverColor: '#B10633',
    textColor: '#FFFFFF',
  },
  {
    id: 'legal_action',
    color: '#ADABAB',
    hoverColor: '#707070',
    textColor: '#FFFFFF',
  },
  {
    id: 'sent_legal_action',
    color: '#ADABAB',
    hoverColor: '#707070',
    textColor: '#FFFFFF',
  },
  {
    id: 'dejudicialized',
    color: '#ADABAB',
    hoverColor: '#707070',
    textColor: '#FFFFFF',
  },
  {
    id: 'dams',
    color: '#9DA423',
    hoverColor: '#787E05',
    textColor: '#FFFFFF',
  },
  {
    id: 'billets',
    color: '#9DA423',
    hoverColor: '#787E05',
    textColor: '#FFFFFF',
  },
  {
    id: 'digital_processes',
    color: '#AB1BEF',
    hoverColor: '#8504C1',
    textColor: '#FFFFFF',
  },
  {
    id: 'refis',
    color: '#1A1818',
    hoverColor: '#707070',
    textColor: '#FFFFFF',
  },
];
