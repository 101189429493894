import { FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { translateRoute, isValidRoute } from '../../helpers/routeTranslate';
import styles from './styles.module.scss';

type BreadcumbProps = {
  pathnames: string[]
};

export function Breadcumb(props: BreadcumbProps) {
  const navigate = useNavigate();
  const paths = props.pathnames.filter((pathItem) => isValidRoute(pathItem));
  const breadcumbLength: number = paths.length;

  return (
    <div className={styles.breadcumbContainer}>
      <a onClick={() => navigate('/')}>Home</a>
      {
        paths.map((value, index) => {
          const routeTo = `/${props.pathnames.slice(0, index + 1).join('/')}`;
          const lastItem: Boolean = breadcumbLength === index + 1;
          if (lastItem) {
            return (
              <div className={styles.breadcumLastItem}>
                <FaChevronRight />
                <p> {translateRoute(value)}</p>
              </div>
            );
          }
          return (
              <div className={styles.breadcumItem}>
                <FaChevronRight />
                <a onClick={() => navigate(routeTo)}> {translateRoute(value)}</a>
              </div>
          );
        })}
    </div>
  );
}
