import { Grid, Skeleton } from '@mui/material';
import { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { Button } from '../../../components/Button';
import Format from '../../../helpers/format';
import { HomeContext } from '../../../context/homeContext';
import Show from '../../../components/Show';
import { InscriptionsByPaymentGroupResponse } from '../../../@types/activeDebt/debtBox';
import ReportService from '../../../services/reportService';
import { downloadFile } from '../../../helpers/downloadPDF';
import DebtBoxService from '../../../services/debtBoxService';

export type SelectedInscriptions = {
  name: string,
  payment_group_code: string,
  total_balance: number,
  total_balance_to_legal_action: number,
  inscriptions_ids: number[],
};

type FooterProps = {
  onNegotiation: () => void;
  contributorId: number | undefined;
  receiptTypeId: number | undefined;
  inscriptionsInfo: SelectedInscriptions[];
  loading?: boolean;
};

export function Footer(props: FooterProps) {
  const {
    removeInscriptions, inscriptionsSelected,
    addToasts, removeAllInscriptions,
  } = useContext(HomeContext);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');

  const removeItem = (item: SelectedInscriptions) => {
    const inscriptions: InscriptionsByPaymentGroupResponse[] = inscriptionsSelected.filter((i) => item.inscriptions_ids.includes(i.id));
    removeInscriptions(inscriptions);
  };

  const handleExtract = async () => {
    if (props.contributorId && inscriptionsSelected.length > 0) {
      let searchObject = search ? JSON.parse(decodeURIComponent(search)) : {};
      const params = {
        inscriptionIds: inscriptionsSelected.map((item) => item.id),
      };
      searchObject = { ...searchObject, ...params };
      window.open(`/reports/custom/${props.contributorId}?search=${encodeURIComponent(JSON.stringify(searchObject))}`, '_blank');
    }
  };

  const downloadPaymentRequestSimulation = async () => {
    if (inscriptionsSelected.length !== 0 && props.contributorId) {
      const { contributorId } = props;
      const receiptTypeId = inscriptionsSelected ? inscriptionsSelected[0].receipt_type_id : 0;
      const inscriptionsIds = inscriptionsSelected.map((i) => i.id);
      await ReportService.getPaymentRequestSimulation(contributorId, receiptTypeId, inscriptionsIds)
        .then((response) => {
          downloadFile(response, 'simulação_de_parcelamento.pdf');
          addToasts({
            type: 'success',
            text: t('paymentRequest.unpaidBillets.downloadPaymentRequest.toast.success'),
            widthFull: true,
          });
        })
        .catch((error) => {
          addToasts({
            type: 'error',
            text: t('paymentRequest.unpaidBillets.downloadPaymentRequest.toast.error'),
            widthFull: true,
          });
        });
    }
  };

  const handleInCashBillet = async () => {
    if (props.contributorId && props.receiptTypeId && inscriptionsSelected.length > 0) {
      try {
        const ids = inscriptionsSelected.map((inscription) => inscription.id);
        const res = await DebtBoxService.generateInCashBillet(props.contributorId, props.receiptTypeId, ids);
        downloadFile(res, 'boleto.pdf');
        addToasts({
          type: 'success',
          text: t('debtBox.results.actions.billet.success'),
          widthFull: true,
        });
        removeAllInscriptions();
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.exception) {
          addToasts({
            type: 'error',
            text: t('debtBox.results.actions.billet.error', { value: error.response.data.exception }),
          });
        } else {
          addToasts({
            type: 'error',
            text: t('debtBox.results.actions.billet.error', { value: '' }),
          });
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={2}
      >
        <Grid item xs={2} sm={4} md={6}>
          <Show if={!!props.loading}>
            <Skeleton variant='rectangular' height={100}/>
          </Show>
          <Show if={!props.loading}>
            <p className={styles.text}>{t('debtBox.search.footer.selectedCdas')}</p>
            <table className={styles.dataTable}>
              <tbody>
                {props.inscriptionsInfo.map((item, index, array) => (
                  <tr key={index}>
                    <td className={styles.labelColumn}>{item.name}</td>
                    <td className={styles.totalSelectedColumn}>
                      {t('debtBox.search.footer.totalSelected', { number: item.inscriptions_ids ? item.inscriptions_ids.length : 0 })}
                    </td>
                    <td className={styles.valueColumn}>{Format.currency(item.total_balance)}</td>
                    <Show if={index !== array.length - 1}>
                      <td className={styles.delete} onClick={() => removeItem(item)}><FaTimes /></td>
                    </Show>
                  </tr>
                ))}
              </tbody>
            </table>
          </Show>
        </Grid>
        <Grid item xs={2} sm={2} md={6}>
          <div className={styles.actions}>
            <Button
              title={t('debtBox.search.footer.extract')}
              buttonType='default'
              disabled={props.loading}
              onClick={() => handleExtract()}
              round
            />
            <Button
              title={t('debtBox.search.footer.simulation')}
              buttonType='default'
              disabled={props.loading}
              onClick={() => downloadPaymentRequestSimulation()}
              round
            />
            <Button
              title={t('debtBox.search.footer.negociation')}
              buttonType='default'
              disabled={props.loading}
              onClick={() => {
                props.onNegotiation();
              }}
              round
            />
            <Button
              title={t('debtBox.search.footer.billet')}
              buttonType='primary'
              disabled={props.loading}
              onClick={() => handleInCashBillet()}
              round
            />
          </div>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}
