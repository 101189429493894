import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Breadcumb } from '../../components/Breadcumb';
import styles from './styles.module.scss';
import { NewContributorButton } from './NewContributorButton';
import Select from '../../components/Select';
import DebtBoxService from '../../services/debtBoxService';
import { OptionType } from '../../@types/config';
import { Instructions } from './Instructions';
import { ContributorDetails } from './ContributorDetails';
import { Title } from '../../components/Title';

export function ContributorBox() {
  const location = useLocation();
  const { pathname } = useLocation();
  const [contributor, setContributor] = useState<OptionType | undefined>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params) {
      const contributorData: OptionType = {
        value: params.get('value'),
        label: params.get('label') || '',
      };
      setContributor(contributorData);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 3, sm: 12, md: 16 }}
        spacing={1}
      >
        <Grid item xs={3} sm={12} md={16} className={ styles.header }>
          <Title text={ t('contributorBox.title') } />
          <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
        </Grid>

        <Grid item xs={3} sm={2} md={2}>
          <NewContributorButton />
        </Grid>

        <Grid item xs={3} sm={10} md={14}>
          <Select
            dataCy={'search-contributor-in-contributor-field'}
            classNameInput={styles.selectInput}
            popperStyle={styles.popperStyle}
            placeholder={t('contributorBox.placeholder')}
            noOptionsMessage={t('contributorBox.noOptions')}
            options={[]}
            value={contributor}
            urlToUpdate={DebtBoxService.getContributorList}
            onChange={(val) => setContributor(val || undefined)}
            labelBold
          />
        </Grid>
        {(contributor === undefined) && (
          <Grid item xs={3} sm={12} md={16} className={styles.instructions}>
            <Instructions />
          </Grid>
        )}
        {contributor !== undefined && (
          <Grid item xs={3} sm={12} md={16}>
            <ContributorDetails contributorData={contributor}/>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
