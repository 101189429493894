import {
  FaCheckSquare,
  FaRegSquare,
  FaCheck,
  FaMinusSquare,
} from 'react-icons/fa';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.scss';

type CheckBoxProps = {
  value: boolean;
  isMarker?: boolean;
  text?: string;
  color?: string;
  uncheckedColor?: string;
  onClick?: () => void;
  fontLarger?: boolean;
  fontSmall?: boolean;
  partially?: boolean;
  disabled?: boolean;
  removeTooltip?: boolean;
  dataCy?: string;
};

export function CheckBox(props: CheckBoxProps) {
  const renderBox = () => {
    if (props.value) {
      return props.partially
        ? <FaMinusSquare style={ props.color ? { color: props.color } : { color: 'var(--neutral6)' } }/>
        : <FaCheckSquare style={ props.color ? { color: props.color } : { color: 'var(--neutral6)' } }/>;
    }
    return <FaRegSquare />;
  };

  const renderMarker = () => {
    if (props.value) {
      return <FaCheck style={ props.color
        ? { color: 'var(--neutral0)', backgroundColor: props.color, padding: '1.5px' }
        : { color: 'var(--neutral0)', backgroundColor: 'var(--neutral6)', padding: '1.5px' } }/>;
    }
    return <FaRegSquare style={ props.uncheckedColor
      ? { color: props.uncheckedColor, backgroundColor: props.color }
      : props.color ? { color: props.color, backgroundColor: props.color }
        : {} }/>;
  };

  const handleClick = () => {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  };

  const renderText = () => {
    if (!props.text || props.removeTooltip) {
      return '';
    }

    return props.text;
  };

  return (
    <a
      className={
        `${styles.container} 
          ${props.fontLarger ? styles.fontLarger : ''} 
          ${props.disabled ? styles.disabled : ''}
          ${props.fontSmall ? styles.fontSmall : ''}`
      }
      style={ props.color && !props.disabled ? { color: props.color } : {} }
      onClick={handleClick}
      data-cy={props.dataCy}
    >
      <div className={ !props.isMarker ? styles.box : '' }>
        { props.isMarker ? renderMarker() : renderBox()}
      </div>
      {
        props.text != null && props.text.length > 20 && !props.removeTooltip
          ? <Tooltip children={<span>{ props.text }</span>} title={renderText()} />
          : <span>{ props.text }</span>
      }
    </a>
  );
}
