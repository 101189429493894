import { useEffect } from 'react';
// import { Boxes } from './Boxes';
import styles from './styles.module.scss';
import { loadTitle } from '../../helpers/changeTitle';

type HomeProps = {
  title?: string;
};

export function Home(props: HomeProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <div className={ styles.content }>
      {/* <Boxes /> */}
    </div>
  );
}
