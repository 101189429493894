import {
  FaCalendarAlt,
  FaCheckCircle,
  FaExclamationTriangle,
  FaFileAlt,
  FaFlag,
  FaPaperclip,
  FaTrashAlt,
  FaWpforms,
} from 'react-icons/fa';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { PaymentRequestDetailsResponse } from '../../../@types/activeDebt/debtBox';
import { Table } from '../../../components/Table';
import styles from './styles.module.scss';
import { GenerateDocument } from './GenerateDocument';
import { Link } from '../../../components/Link';
import DebtBoxService from '../../../services/debtBoxService';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { AttachDocument } from './AttachDocument';
import { OptionType } from '../../../@types/config';
import InscriptionService from '../../../services/inscriptionService';
import { AttachedDocument } from '../../../@types/activeDebt/inscription';
import { HomeContext } from '../../../context/homeContext';
import { downloadFile } from '../../../helpers/downloadPDF';
import GeneralService from '../../../services/generalService';
import Show from '../../../components/Show';
import { RepresentativeForm } from '../../../components/RepresentativeForm';

type DocumentsProps = {
  data: PaymentRequestDetailsResponse;
  handleUpdateList?: () => void;
};
export function Documents(props: DocumentsProps) {
  const [installmentTerm, setInstallmentTerm] = useState(props.data?.document_installment_term?.document_id);
  const [documents, setDocuments] = useState(props.data?.documents);
  const [loading, setLoading] = useState(false);
  const [representaviveModal, setRepresentativeModal] = useState(false);
  const [openAttachDocumentModal, setOpenAttachDocumentModal] = useState(false);
  const [documentType, setDocumentType] = useState<OptionType[]>([{ label: '', value: 0 }]);
  const { addToasts, setAlert } = useContext(HomeContext);

  const getDocumentTypes = async () => {
    const documentTypes = await InscriptionService.getPaymentRequestDocumentsType();
    setDocumentType(documentTypes.result);
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  useEffect(() => {
    setDocuments(props.data?.documents);
  }, [props.data]);

  const handleDelete = (id: number) => {
    setAlert(undefined);
    if (props.data) {
      if (id) {
        setLoading(true);
        DebtBoxService.deletePaymentRequestDocument(props.data.id, id)
          .then(() => {
            setLoading(false);
            const newList = documents?.filter((doc) => doc.document_id !== id);
            setDocuments(newList);
            if (props.handleUpdateList) props.handleUpdateList();
          }).catch(() => setLoading(false));
      }
    }
  };

  const handleAttachDocument = (files: AttachedDocument[]) => {
    if (props.data) {
      DebtBoxService.attachPaymentRequestDocuments(props.data.id, files)
        .then(() => {
          setOpenAttachDocumentModal(false);
          addToasts({
            type: 'success',
            text: t('inscription.documents.toast.success'),
          });
          if (props.handleUpdateList) props.handleUpdateList();
        }).catch(() => {
          addToasts({
            type: 'error',
            text: t('inscription.documents.toast.error'),
          });
        });
    }
  };

  const handleOpenDeleteAlert = (id: number) => {
    setAlert({
      visible: true,
      text: `${t('paymentRequest.documents.confirmAlertText')} ${props.data?.code}`,
      title: t('paymentRequest.documents.confirmAlertTitle'),
      confirmType: 'danger',
      handleConfirm: () => handleDelete(id),
    });
  };

  const handleDownloadTerm = () => {
    DebtBoxService.getPaymentRequestInstallmentTerm(props.data.id, installmentTerm)
      .then((res) => {
        downloadFile(res, `termo-de-reconhecimento-${props.data.code}`);
      });
  };

  const handleDownloadDocument = (document_id: number, file_name: string) => {
    GeneralService.getDownloadDocument(document_id)
      .then((res) => {
        downloadFile(res, file_name);
      }).catch((error) => {
        addToasts({
          type: 'error',
          text: error.data.error,
        });
      });
  };

  const closeRepresentativeModal = (e: any) => {
    setRepresentativeModal(false);
    e.stopPropagation();
  };

  return (
    <div className={ styles.container }>
      <div>
        <div className={styles.addRepresentative}>
          <Link onClick={ () => setRepresentativeModal(true)}>
            {t('debtBox.newNegotiation.representativeModal.addRepresentative')}
          </Link>
        </div>
        {installmentTerm ? (
          <div className={styles.attachedDocuments}>
            <FaCheckCircle />
            <span>
              {t('paymentRequest.documents.hasInstallmentTerm')}
              <Link
                onClick={() => handleDownloadTerm()}
              >
                {t('paymentRequest.documents.showDocument')}
              </Link>.
            </span>
          </div>
        ) : (
          <GenerateDocument
            data={props.data}
            onCreate={(val) => setInstallmentTerm(val)}
          />
        )}
      </div>
      <div className={styles.alerts}>
        <Show if={props.data.necessary_documents.length > 0}>
          <div className={styles.attachedDocuments}>
            <span>
              <FaExclamationTriangle className={styles.alert}/>
              {t('paymentRequest.documents.alertDocuments')}
              <b>{props.data.necessary_documents.join(', ')}</b>
            </span>
          </div>
        </Show>
        <span className={styles.attachedDocuments}>
          <FaCheckCircle />
          {t('paymentRequest.documents.attachedDocuments')}
        </span>
      </div>
      {loading ? (
        <Skeleton variant='rectangular' height={100}/>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{t('paymentRequest.documents.file')}</th>
              <th>{t('paymentRequest.documents.date')}</th>
              <th>{t('paymentRequest.documents.type')}</th>
              <th>{t('paymentRequest.documents.delete')}</th>
            </tr>
          </thead>

          <tbody>
            {
              documents?.map((document) => (
                <tr>
                  <td>
                      <Link
                        onClick={() => handleDownloadDocument(document.document_id, document.file_name)}
                      >
                        <FaFileAlt/>
                        {document.file_name}
                      </Link>
                    </td>
                  <td>
                    <FaCalendarAlt/> {document.created_at}
                  </td>
                  <td>
                    <FaFlag/> {document.type}
                  </td>
                  <td className={styles.deleteButton}>
                    <Tooltip title={t('paymentRequest.documents.deleteTooltip')}>
                      <a onClick={() => handleOpenDeleteAlert(document.document_id)}>
                        <FaTrashAlt />
                      </a>
                    </Tooltip>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )}
      <div className={styles.attachButton}>
        <Button
          title={t('paymentRequest.documents.attachDocument.title')}
          buttonType='primary'
          onClick={() => setOpenAttachDocumentModal(true)}
          round
        />
      </div>
      <Modal
        title={t('paymentRequest.documents.attachDocument.title')}
        open={openAttachDocumentModal}
        onClose={() => setOpenAttachDocumentModal(false)}
        icon={<FaPaperclip />}
        size='large'
      >
        <AttachDocument
          handleFunction={handleAttachDocument}
          documentType={documentType}
        />
      </Modal>
      <Modal
        icon={<FaWpforms />}
        title={t('debtBox.newNegotiation.representativeModal.title')}
        open={representaviveModal}
        onClose={(e: any) => closeRepresentativeModal(e)}
      >
        {props.data && (
          <RepresentativeForm
            negotiationId={props.data?.id}
            closeRepresentativeModal={() => setRepresentativeModal(false)}
            idDocument={(id: number) => props.handleUpdateList && props.handleUpdateList()}
          />
        )}
      </Modal>
    </div>
  );
}
