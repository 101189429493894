import { t } from 'i18next';
import { Grid, Skeleton } from '@mui/material';
import { useContext } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { Button } from '../../../../components/Button';
import { Subtitle } from '../../../../components/Subtitle';
import styles from './styles.module.scss';
import { HomeContext } from '../../../../context/homeContext';
import { Contributor } from '../../../../@types/contributor';
import { ContactTable } from './ContactTable';
import { NewContactForm } from './NewContactForm';

type ContactListProps = {
  contributor: Contributor;
  refetch: () => void;
};

export function ContactList(props: ContactListProps) {
  const { setModal } = useContext(HomeContext);

  const openNewAddressModal = () => {
    setModal({
      visible: true,
      icon: <FaPhoneAlt />,
      title: t('contributorBox.details.contact.addNew'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'large',
      children: (
        <NewContactForm
          contributorId={props.contributor.id}
          closeModal={() => setModal(undefined)}
          refetch={() => props.refetch()}
        />
      ),
    });
  };

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={12}>
        <p className={styles.text}>
          {`
            ${t('contributorBox.details.contact.message1')}
            ${t('contributorBox.details.contact.message2')}
            ${t('contributorBox.details.contact.message3')}
            ${t('contributorBox.details.contact.message4')}
          `}
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Button
          dataCy={'cypress-open-contact-modal-button'}
          title={t('contributorBox.details.contact.addNew')}
          buttonType='primary'
          onClick={() => openNewAddressModal()}
          round
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {(!props.contributor) && (
          <Skeleton height={350} variant='rectangular' />
        )}
        {(props.contributor) && (
          <Grid container spacing={2} className={styles.container}>
            <Grid item xs={12} sm={12} md={12}>
              <Subtitle text={t('contributorBox.details.contact.current')} />
              <ContactTable
                contactList={props.contributor.currents_contributor_contact_histories}
                refetch={() => props.refetch()}
                displayAction
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Subtitle text={t('contributorBox.details.contact.old')} />
              <ContactTable
                contactList={props.contributor.others_contributor_contact_histories}
                displayAction={false}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
