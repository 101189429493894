import { Modal as ModalMui } from '@mui/material';
import { t } from 'i18next';
import { ReactNode, useContext } from 'react';
import { HomeContext } from '../../context/homeContext';
import { Header } from './Header';
import styles from './styles.module.scss';

type ModalProps = {
  title?: string;
  children?: ReactNode;
  open?: boolean;
  onClose?: (e: any) => void;
  size?: 'small' | 'medium' | 'large';
  icon?: ReactNode;
  context?: boolean;
  confirmationOnClose?: boolean;
};

export function Modal(props: ModalProps) {
  const { modal, setModal, setAlert } = useContext(HomeContext);
  const onClose = (e: any) => {
    if (props.onClose) {
      props.onClose(e);
    } else if (modal?.handleClose) {
      modal.handleClose();
    }
  };

  const handleClosedAlert = () => {
    setModal(undefined);
    setAlert(undefined);
  };

  const handleAlert = () => {
    if (modal ? modal.confirmationOnClose : props.confirmationOnClose) {
      setAlert({
        visible: true,
        handleConfirm: () => handleClosedAlert(),
        title: t('general.modalOutSideConfirmationAlert.title'),
        text: t('general.modalOutSideConfirmationAlert.text'),
      });
    }
  };

  const getSizeClass = (size: string | undefined) => {
    if (size === 'small') return styles.small;
    if (size === 'medium') return styles.medium;
    if (size === 'large') return styles.large;
    return '';
  };

  if (props.context || props.open) {
    return (
      <ModalMui
        open={props.context ? !!modal?.open : !!props.open}
        onClose={() => handleAlert()}
        disableAutoFocus={true}
      >
        <div
          className={`${styles.box} ${props.size ? getSizeClass(props.size) : getSizeClass(modal?.size)}`}
        >
          <Header
            title={props.context ? modal?.title : props.title}
            onClose={(e: any) => onClose(e)}
            icon={props.context ? modal?.icon : props.icon}
            boxComponent={modal?.box}
            minimize={modal?.minimize}
            onMinimize={modal?.onMinimize}
          />
          <div className={styles.body}>
            {props.children ? props.children : modal?.children}
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
