import { Grid } from '@mui/material';
import { t } from 'i18next';
import Switch from 'react-switch';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Button } from '../../../components/Button';
import DebtBoxService from '../../../services/debtBoxService';
import { Label } from '../../../components/Label';
import Select from '../../../components/Select';
import { OptionType } from '../../../@types/config';
import { InputText } from '../../../components/InputText';
import MultipleSelect from '../../../components/MultipleSelect';

type SearchProps = {
  onlyRead?: boolean;
  search?: {
    contributor?: OptionType;
    inscription?: OptionType[];
    processNumber?: string;
    protestProtocol?: string;
    cda?: string;
    installmentNumber?: string;
    origin?: string;
    showExtinctDebits?: boolean;
  };
};

export function Search(props: SearchProps) {
  const [showExtinctDebits, setShowExtinctDebits] = useState(
    props.search?.showExtinctDebits !== undefined ? props.search?.showExtinctDebits : true,
  );
  const [contributor, setContributor] = useState<OptionType | undefined>(props.search?.contributor);
  const [processNumber, setProcessNumber] = useState<any>(props.search?.processNumber);
  const [inscription, setInscription] = useState<OptionType[]>(props.search?.inscription || []);
  const [protestProtocol, setProtestProtocol] = useState<any>(props.search?.protestProtocol);
  const [cda, setCda] = useState<any>(props.search?.cda);
  const [installmentNumber, setInstallmentNumber] = useState<any>(props.search?.installmentNumber);
  const [origin, setOrigin] = useState<any>(props.search?.origin);

  const [showMore, setShowMore] = useState<boolean>(false);

  const submit = (event: any) => {
    event.preventDefault();
    const params = encodeURIComponent(JSON.stringify({
      contributor,
      processNumber,
      inscription,
      protestProtocol,
      cda,
      installmentNumber,
      origin,
      showExtinctDebits,
    }));
    window.location.href = `/debt_box_search?search=${params}`;
  };

  const emptyFields = () => {
    return (!contributor && !processNumber && !protestProtocol && (!inscription || inscription.length === 0) && !cda && !installmentNumber && !origin);
  };

  const showField = (field: any) => {
    return !props.onlyRead || field || showMore;
  };

  const handleClearData = (e: any) => {
    e.preventDefault();
    window.location.href = '/';
  };

  return (
    <form onSubmit={submit} className={ styles.container }>
      <Grid
        columns={{ xs: 1, sm: 12, md: 12 }}
        container
      >
        { showField(props.search?.contributor) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <Select
                label={t('debtBox.search.fields.contributor.label')}
                placeholder={t('debtBox.search.fields.contributor.placeholder')}
                options={[]}
                value={contributor}
                urlToUpdate={DebtBoxService.getContributorList}
                onChange={(val) => setContributor(val || undefined)}
                dataCy="search-contributor"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.processNumber) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
              item xs={3} sm={12} md={6}
            >
              <InputText
                label={t('debtBox.search.fields.processNumber.label')}
                placeholder={t('debtBox.search.fields.processNumber.placeholder')}
                value={processNumber}
                onChange={(val) => setProcessNumber(val.target.value)}
                dataCy="search-process-number"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.inscription && props.search?.inscription.length > 0) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <MultipleSelect
                label={t('debtBox.search.fields.inscription.label')}
                placeholder={t('debtBox.search.fields.inscription.placeholder')}
                selectedOptions={inscription || []}
                options={[]}
                urlToUpdate={DebtBoxService.getInscriptionsByNumberList}
                onChange={(val) => setInscription(val || [])}
                dataCy="search-inscription"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.protestProtocol) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <InputText
                label={t('debtBox.search.fields.protestProtocol.label')}
                placeholder={t('debtBox.search.fields.protestProtocol.placeholder')}
                value={protestProtocol}
                onChange={(val) => setProtestProtocol(val.target.value)}
                dataCy="search-protest-protocol"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.cda) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <InputText
                label={t('debtBox.search.fields.cda.label')}
                placeholder={t('debtBox.search.fields.cda.placeholder')}
                value={cda}
                onChange={(val) => setCda(val.target.value)}
                dataCy="search-cda"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.installmentNumber) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <InputText
                label={t('debtBox.search.fields.installmentNumber.label')}
                placeholder={t('debtBox.search.fields.installmentNumber.placeholder')}
                value={installmentNumber}
                onChange={(val) => setInstallmentNumber(val.target.value)}
                dataCy="search-installment"
                labelBold
              />
            </Grid>
          </Grid>
        )}

        { showField(props.search?.origin) && (
          <Grid
            item xs={3} sm={12}
            className={styles.field}
          >
            <Grid
            item xs={3} sm={12} md={6}
            >
              <InputText
                label={t('debtBox.search.fields.origin.label')}
                placeholder={t('debtBox.search.fields.origin.placeholder')}
                value={origin}
                onChange={(val) => setOrigin(val.target.value)}
                dataCy="search-origin"
                labelBold
              />
            </Grid>
          </Grid>
        )}
        {((!emptyFields() || props.onlyRead) && props.search) && (
          <>
            <a
              onClick={() => setShowMore(!showMore)}
              className={`${styles.button} ${showMore ? styles.open : ''}`}
            >
              <span>
                {showMore ? t('debtBox.search.lessFilters') : t('debtBox.search.moreFilters')}
              </span>
              <FaChevronDown />
            </a>
          </>
        )}

      </Grid>

      <div className={styles.actions}>
        <div className={styles.switch}>
          <Label text={t('debtBox.search.fields.showExtinctDebits')}/>
          <Switch
            checked={!!showExtinctDebits}
            onChange={() => setShowExtinctDebits(!showExtinctDebits)}
            offColor='#e8e8e8'
            onColor='#1ECAA1'
            activeBoxShadow=''
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            width={32}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={(e) => handleClearData(e)}
            title={t('debtBox.search.clear')}
            buttonType="default"
            type='reset'
            round
          />
          <Button
            onClick={submit}
            title={t('debtBox.search.confirm')}
            buttonType="primary"
            disabled={emptyFields()}
            type='submit'
            round
          />
        </div>
      </div>
    </form>
  );
}
