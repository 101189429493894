import { createTheme } from '@mui/material/';

export const globalTheme = (createTheme as any)({
  palette: {
    primary: {
      main: '#1ECAA1',
      light: '#1ECAA1',
      dark: '#009ad3',
    },
  },
});
