import { Search } from '../../DebtBox/Search';
import styles from './styles.module.scss';

type SearchHeaderProps = {
  search?: any;
};

export function SearchHeader(props: SearchHeaderProps) {
  return (
    <div className={ styles.container }>
      <Search search={props.search} onlyRead/>
    </div>
  );
}
