import { FormControlLabel, Radio, RadioGroup as RadioGroupMui } from '@mui/material';
import styles from './styles.module.scss';
import { Label } from '../Label';
import { OptionType } from '../../@types/config';

type RadioGroupProps = {
  dataCy?: string;
  label: string;
  options: OptionType[];
  value?: any;
  onChange: (e: any) => void;
  notDefaultOptions?: boolean;
  noRow?: boolean;
  required?: boolean;
};

export function RadioGroup(props: RadioGroupProps) {
  return (
    <div className={ styles.container }>
      {props.label && <Label text={props.label} bold required={!props.notDefaultOptions}/> }
      <RadioGroupMui
        defaultValue={props.notDefaultOptions ? undefined : (props.value ? props.value : props.options[0].value)}
        onChange={(e) => props.onChange(e)}
        {...{ row: !props.noRow }}
      >
        {
          props.options.map((option, index) => (
            <FormControlLabel
              classes={{ label: styles.label }}
              value={option.value}
              control={
                <Radio
                  data-cy={`${props.dataCy}[${index}]`}
                  size='small'
                  sx={{
                    color: 'var(--neutral4)',
                    '&.Mui-checked': {
                      color: 'var(--primary4)',
                    },
                  }}
                  {...{ required: !!props.required }}
                />
              }
              label={option.label}
            />
          ))
        }
      </RadioGroupMui>
    </div>
  );
}
