import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  FaCalendarAlt, FaFileAlt, FaTrashAlt,
} from 'react-icons/fa';
import styles from './styles.module.scss';
import { AttachDocument, OptionType } from '../../../@types/config';
import Select from '../../Select';

type ListFilesProps = {
  documents: any[];
  removeFile: (files: AttachDocument) => void;
  handleType?: (documents: AttachDocument, type: string) => void;
  types: OptionType[];
  isMobile?: boolean;
};

type DocumentOptions = {
  id: string;
  label: string;
  value: any;
};

export function ListFiles(props: ListFilesProps) {
  const [filteredData, setFilteredData] = useState<OptionType[]>([]);
  const [files, setFiles] = useState<AttachDocument[]>(props.documents);
  const [documents, setDocuments] = useState<DocumentOptions[]>(
    props.documents.map((document: AttachDocument) => ({ id: document.id, label: '', value: '' })),
  );

  const getValue = (documentId: string) => {
    const optionDocument = documents.find((document: DocumentOptions) => document.id === documentId);

    if (optionDocument) {
      return { label: optionDocument.label, value: optionDocument.value };
    }
    return { label: '', value: '' };
  };

  const changeDocumentTypes = (documentId: string, event: OptionType) => {
    const selectedsIds = files.map((file) => file.id);
    const list = props.documents.map((document: AttachDocument) => {
      if (selectedsIds.includes(documentId) && document.id === documentId) {
        return { id: document.id, label: event.label, value: event.value };
      }
      const documentOption = getValue(document.id);
      return { id: document.id, label: documentOption.label, value: documentOption.value };
    });
    setDocuments(list);
  };

  const getDocuments = (document: AttachDocument) => {
    const findDocument = files.find((obj) => obj.id === document.id);
    if (!findDocument) {
      return document;
    }

    return findDocument;
  };

  const handleDocuments = (event: any, document: AttachDocument) => {
    if (event && props.handleType) {
      props.handleType(getDocuments(document), event.value);
      changeDocumentTypes(document.id, event);
    }
  };

  useEffect(() => {
    setFiles(props.documents);
    const selectedTypes = new Set(props.documents.map((file) => file.type));
    const unselectedTypes = props.types.filter((type) => !selectedTypes.has(type.value));
    setFilteredData(unselectedTypes);
  }, [props.documents]);

  return (
    <div className={ styles.container }>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('debtBox.newNegotiation.modal.documentsTab.name')}</th>
            <th>{t('debtBox.newNegotiation.modal.documentsTab.date')}</th>
            <th>{t('debtBox.newNegotiation.modal.documentsTab.type')}</th>
            <th>{t('debtBox.newNegotiation.modal.documentsTab.delete')}</th>
          </tr>
        </thead>
        {
        props.documents.map((document) => (
          <tbody>
            <td>
              <FaFileAlt />
              <a target='_blank'>
                {document.file.name}
              </a>
            </td>
            <td>
              <FaCalendarAlt />
              <span>{document.created_at}</span>
            </td>
            <td>
              <Select
                label={''}
                value={getValue(document.id)}
                options={filteredData}
                placeholder={t('debtBox.newNegotiation.modal.documentsTab.selectLabel')}
                required
                disableClearable
                onChange={(e) => handleDocuments(e, document)}
              />
            </td>
            <td>
              <a
                className={styles.buttonDelete}
                onClick={() => props.removeFile(document)}
              >
                <FaTrashAlt />
              </a>
            </td>
          </tbody>
        ))}
      </table>
    </div>
  );
}
