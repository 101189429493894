import { Grid } from '@mui/material';
import { t } from 'i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { Button } from '../../Button';
import styles from './styles.module.scss';
import { InputText } from '../../InputText';
import GeneralService from '../../../services/generalService';
import { HomeContext } from '../../../context/homeContext';

type ChangePasswordProps = {
  closeModal: () => void;
};

export function ChangePassword(props: ChangePasswordProps) {
  const { addToasts } = useContext(HomeContext);
  const schema = Yup.object().shape({
    current_password: Yup.string()
      .required(t('general.password.requeried')),
    password: Yup.string()
      .required(t('general.password.requeried')),
    password_confirmation: Yup.string()
      .required(t('general.password.requeried'))
      .oneOf([Yup.ref('password')], t('general.password.validation')),
  });

  const {
    handleSubmit, formState: { errors }, control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submit = (form: any) => {
    GeneralService.changePassword(form).then((res) => {
      addToasts({
        type: 'success',
        text: t('general.password.success'),
      });
      props.closeModal();
    }).catch(() => {
      addToasts({
        type: 'error',
        text: t('general.password.error'),
      });
    });
  };

  return (
    <form
      className={ styles.container }
      onSubmit={handleSubmit(submit)}
      autoComplete="off"
    >
      <Grid
        container
        columns={{ xs: 4, sm: 6, md: 6 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="current_password"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('general.password.current')}
                placeholder={t('general.password.current')}
                type='password'
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name='password'
            render={({ field }) => (
              <InputText
                {...field}
                label={t('general.password.new')}
                placeholder={t('general.password.newPlaceholder')}
                type='password'
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="password_confirmation"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('general.password.new')}
                placeholder={t('general.password.newPlaceholder')}
                type='password'
                required
              />
            )}
          />
          <p className={styles.error}>{errors.password_confirmation?.message}</p>
        </Grid>
        <div className={styles.footer}>
          <Button
            title={t('general.save')}
            buttonType='primary'
            size='flat'
            type='submit'
            round
          />
        </div>
      </Grid>
    </form>
  );
}
