import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import {
  Contributor,
  ReceiptTypeSearch,
} from '../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { Item } from './Item';
import { Footer, SelectedInscriptions } from '../Footer';
import Show from '../../../components/Show';
import { HomeContext } from '../../../context/homeContext';
import { NewNegotiation } from '../NewNegotiation';
import { hasMultiplePaymentGroup } from '../../../helpers/inscriptionActions';

type PaymentGroupsProps = {
  receiptType?: ReceiptTypeSearch;
  contributor?: Contributor;
};

export function PaymentGroups(props: PaymentGroupsProps) {
  const { inscriptionsSelected, removeAllInscriptions, setModal } = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [inscriptionInfo, setInscriptionInfo] = useState<SelectedInscriptions[]>([]);
  const loadData = async () => {
    if (props.receiptType && props.contributor) {
      const result = await DebtBoxService.getPaymentGroupsByContributorAndReceiptType(
        props.contributor.id,
        props.receiptType.id,
        props.receiptType.inscriptions_ids,
      );
      return result;
    }
    return null;
  };

  const queryClient = useQueryClient();

  const {
    refetch, data, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`paymentGroups-${props.contributor}-${props.receiptType}`],
    queryFn: loadData,
    enabled: false,
  });
  useEffect(() => {
    if (props.receiptType && props.contributor) {
      queryClient.setQueryData(['paymentGroups'], { data: undefined });
      refetch();
      removeAllInscriptions();
    }
  }, [props.receiptType, props.contributor]);

  const openNewNegotiationModal = () => {
    setModal({
      visible: true,
      icon: undefined,
      title: t('debtBox.newNegotiation.title'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'large',
      children: (
        <NewNegotiation
          contributor={props.contributor}
          receiptType={props.receiptType}
          reloadData={() => refetch()}
          loading={loading}
          inscriptionsInfo={inscriptionInfo}
        />
      ),
    });
  };

  const handleReloadData = () => {
    refetch();
    removeAllInscriptions();
  };

  const getInscriptionsInfo = async (contributorId: number) => {
    setLoading(true);
    const receiptTypeId = inscriptionsSelected[0].receipt_type_id;
    const inscriptionsIds: number[] = inscriptionsSelected.map((item) => item.id);
    const result = await DebtBoxService.getSelectedInscriptionsInfo(contributorId, receiptTypeId, inscriptionsIds);
    const totalBalanceSum: number = result.reduce((acc, item) => acc + item.total_balance, 0);
    const totalBalanceToLegalActionSum: number = result.reduce((acc, item) => acc + item.total_balance_to_legal_action, 0);
    const selectedInscriptionsIds: number[] = result.flatMap((item) => item.inscriptions_ids);
    result.push({
      name: 'Total',
      payment_group_code: 'total_selected_group',
      total_balance: totalBalanceSum,
      total_balance_to_legal_action: totalBalanceToLegalActionSum,
      inscriptions_ids: selectedInscriptionsIds,
    });
    setInscriptionInfo(result);
    setLoading(false);
  };

  useEffect(() => {
    if (props.contributor?.id !== undefined && inscriptionsSelected.length > 0) {
      getInscriptionsInfo(props.contributor.id);
    }
  }, [inscriptionsSelected]);

  return (
    <>
      <div className={ styles.container }>
        {
          (isRefetching || isLoading) && (
            <Skeleton variant='rectangular' height={100}/>
          )
        }
        {
          (data && (!isLoading && !isRefetching)) && (
            <div className={styles.content}>
              <Item
                title={t('debtBox.results.active')}
                data={data.active_debts[0]}
                onNegotiation={() => openNewNegotiationModal()}
                contributorId={props.contributor?.id}
                receiptTypeId={props.receiptType?.id}
                onRefetch={() => handleReloadData()}
              />
              <Item
                title={t('debtBox.results.extinct')}
                data={data.extinct_debts[0]}
                contributorId={props.contributor?.id}
              />
            </div>
          )
        }
      </div>
      <Show if={inscriptionsSelected.length > 0 && hasMultiplePaymentGroup(inscriptionsSelected)}>
        <Footer
          onNegotiation={() => openNewNegotiationModal()}
          contributorId={props.contributor?.id}
          receiptTypeId={props.receiptType?.id}
          loading={loading}
          inscriptionsInfo={inscriptionInfo}
        />
      </Show>
    </>
  );
}
