import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Grid } from '@mui/material';
import styles from './styles.module.scss';
import { Breadcumb } from '../../components/Breadcumb';
import { Search } from './Search';
import { Instructions } from './Instructions';
import { Title } from '../../components/Title';

export function DebtBox() {
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <Title text={ t('debtBox.title') } />
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>

        <Grid item xs={6} className={ styles.instructions }>
          <Instructions />
        </Grid>

        <Grid item xs={6}>
          <Search />
        </Grid>
      </Grid>
    </div>
  );
}
