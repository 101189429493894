import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { PaymentRequestDetailsResponse } from '../../../../@types/activeDebt/debtBox';
import { Button } from '../../../../components/Button';
import styles from './styles.module.scss';
import DebtBoxService from '../../../../services/debtBoxService';

type GenerateDocumentProps = {
  data?: PaymentRequestDetailsResponse;
  onCreate: (val: number) => void;
};

export function GenerateDocument(props: GenerateDocumentProps) {
  const loadData = async () => {
    if (props.data?.id) {
      const result = await DebtBoxService.paymentRequestGenerateDocument(props.data.id);
      props.onCreate(result.document_id);
      return result;
    }
    return null;
  };

  const {
    data, refetch, isLoading,
  } = useQuery({
    queryKey: ['paymentRequestGenerateDocument'],
    queryFn: loadData,
    enabled: false,
  });
  const handleConfirm = () => {
    refetch();
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <Skeleton variant='rectangular' height={100}/>
      )
      }

      {!data && !isLoading && (
        <>
          <span className={styles.title}>{t('paymentRequest.documents.text')}</span>
          <Button
            title={t('paymentRequest.documents.confirm')}
            buttonType='default'
            round
            onClick={() => handleConfirm()}
            className={styles.button}
          />
          <span className={styles.alert}>{t('paymentRequest.documents.alert')}</span>
        </>
      )}
    </div>
  );
}
