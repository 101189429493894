import { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { PiMoonStarsFill, PiSunFill } from 'react-icons/pi';
import { t } from 'i18next';
import logo from '../../../assets/logo-epgm.svg';
import logoDark from '../../../assets/logo-agilis-dark.svg';
import { BarButton } from '../Sidebar/BarButton';
import { Profile } from '../../Profile';
import styles from './styles.module.scss';
import { ThemeContext } from '../../../context/themeContext';

type TopbarProps = {
  openMobile?: boolean;
  setOpenMobile: (e: boolean) => void;
};

export function Topbar(props: TopbarProps) {
  const { themeMode, setThemeMode } = useContext(ThemeContext);

  const handleChangeTheme = () => {
    setThemeMode(themeMode === 'dark' ? 'light' : 'dark');
  };

  return (
    <div className={styles.topbarContainer} id='topbar'>
      <div className={styles.rightItems}>
        <BarButton
          opened={ false }
          onClick={ () => props.setOpenMobile(true) }
          mobile
        />
        <Tooltip title='Home'>
          <a href='/'>
            <img src={themeMode === 'dark' ? logoDark : logo} alt='e-PGM' />
          </a>
        </Tooltip>
      </div>
      <div className={styles.rightItems}>
        <Tooltip title={themeMode === 'dark' ? t('general.lightMode') : t('general.darkMode')}>
          <a
            className={styles.changeThemeMode}
            onClick={handleChangeTheme}
            id='change-theme'
          >
            {themeMode === 'dark' ? <PiSunFill/> : <PiMoonStarsFill/>}
          </a>
        </Tooltip>
        <Profile />
      </div>
    </div>
  );
}
