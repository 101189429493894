import { t } from 'i18next';

export function optionalPropsController(tag: string, onClickFunction?: () => void, negotiationId?: number) {
  const redirectToNegotiation = (id: number | undefined) => {
    window.open(`/payment_request/${id}`);
  };

  switch (tag) {
    case 'addresses':
      return { updateAddress: true, textLink: t('inscription.data.updateAddress.label') };
    case 'protested':
      return { textLink: t('inscription.protestInfoModal.tooltipTitle'), onClick: onClickFunction && (() => onClickFunction()) };
    case 'payment_requests':
      return { textLink: t('inscription.lostNegotiation.pill.toolTipTittle'), onClick: () => redirectToNegotiation(negotiationId) };
    default:
      return {};
  }
}
