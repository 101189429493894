import { Grid, Skeleton } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FaWpforms } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import { AttachDocument, OptionType } from '../../../../@types/config';
import { AttachForm } from '../AttachForm';
import DebtBoxService from '../../../../services/debtBoxService';
import Show from '../../../../components/Show';
import { downloadFile } from '../../../../helpers/downloadPDF';
import { PaymentRequest } from '../../../../@types/activeDebt/paymentRequest';
import InscriptionService from '../../../../services/inscriptionService';
import { Modal } from '../../../../components/Modal';
import { RepresentativeForm } from '../../../../components/RepresentativeForm';

type DocumentsProps = {
  id?: number;
  installments?: number;
  nextStep: () => void;
  negotiation?: PaymentRequest;
};

export function Documents(props: DocumentsProps) {
  const [documents, setDocuments] = useState<AttachDocument[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingTerm, setLoadingTerm] = useState(false);
  const [documentType, setDocumentType] = useState<OptionType[]>([{ label: '', value: 0 }]);
  const [documentTermId, setDocumentTermId] = useState<number | undefined>();
  const [representaviveModal, setRepresentativeModal] = useState(false);
  const [idDocument, setIdDocument] = useState<number>();

  const getDocumentTypes = async () => {
    const documentTypes = await InscriptionService.getPaymentRequestDocumentsType();
    setDocumentType(documentTypes.result);
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);

  const handleConfirm = () => {
    if (documents && props.id) {
      setLoading(true);
      DebtBoxService.attachPaymentRequestDocuments(props.id, documents)
        .then(() => {
          setLoading(false);
          props.nextStep();
        }).catch(() => {
          setLoading(false);
        });
    }
  };

  const handleDownloadRepresentativeTerm = () => {
    if (idDocument) {
      DebtBoxService.negotiationRepresentativeDocument(idDocument)
        .then((res) => {
          downloadFile(res, `termo-de-reconhecimento-representante-${props.negotiation?.code}`);
          setLoadingTerm(false);
        });
    }
  };

  const handleDownloadTerm = (documentId?: number) => {
    if (props.id && documentId) {
      DebtBoxService.getPaymentRequestInstallmentTerm(props.id, documentId)
        .then((res) => {
          downloadFile(res, `termo-de-reconhecimento-${props.negotiation?.code}`);
          setLoadingTerm(false);
        });
    }
  };

  const handleGenerateTerm = () => {
    if (props.id && !documentTermId) {
      setLoadingTerm(true);
      DebtBoxService.paymentRequestGenerateDocument(props.id)
        .then((res) => {
          handleDownloadTerm(res.document_id);
          setDocumentTermId(res.document_id);
        })
        .catch(() => {
          setLoadingTerm(false);
        });
    } else {
      setLoadingTerm(true);
      handleDownloadTerm(documentTermId);
    }
  };

  const closeRepresentativeModal = (e: any) => {
    setRepresentativeModal(false);
    e.stopPropagation();
  };

  return (
    <Grid
      container
      className={styles.container}
      columns={{ xs: 2, sm: 6, md: 12 }}
      spacing={{ xs: 1, md: 2 }}
    >
      <Grid item xs={2} sm={6} md={12}>
        <div className={styles.number}>
          <div>
            <span>{t('debtBox.newNegotiation.negotiation.number')}</span>
            <span className={styles.code}>{props.negotiation?.code}</span>
          </div>
          <div>
            <a className={styles.representationLink} onClick={ () => setRepresentativeModal(true)}>
              {t('debtBox.newNegotiation.representativeModal.addRepresentative')}
            </a>
          </div>
        </div>
        <div className={styles.term}>
          <span className={styles.title}>
            {t('debtBox.newNegotiation.modal.documentsTab.generateTerm.title')}
          </span>
          <Button
            title={t('debtBox.newNegotiation.modal.documentsTab.generateTerm.button')}
            round
            buttonType='primary'
            className={styles.button}
            onClick={idDocument ? handleDownloadRepresentativeTerm : handleGenerateTerm}
            disabled={loadingTerm}
          />
          <span className={styles.text}>
            {t('debtBox.newNegotiation.modal.documentsTab.generateTerm.text')}
          </span>
        </div>
      </Grid>
      <Grid item xs={2} sm={6} md={12}>
        <Show if={!loading && !!(props.negotiation?.number_installments && props.negotiation?.number_installments > 1)}>
          <AttachForm
            onChange={(val) => setDocuments(val)}
            handleConfirm={handleConfirm}
            files={documents}
            documentTypes={documentType}
          />
        </Show>
        <Show if={!loading && !!(props.negotiation?.number_installments && props.negotiation?.number_installments === 1)}>
          <div className={styles.footer}>
            <Button
              title={t('debtBox.newNegotiation.nextStep')}
              textCenter
              round
              buttonType='primary'
              onClick={props.nextStep}
            />
          </div>
        </Show>
        <Show if={loading}>
          <Skeleton variant='rectangular' height={300}/>
        </Show>
        <Modal
          icon={<FaWpforms />}
          title={t('debtBox.newNegotiation.representativeModal.title')}
          open={representaviveModal}
          onClose={(e: any) => closeRepresentativeModal(e)}
        >
          {props.negotiation && (
            <RepresentativeForm
              negotiationId={props.negotiation?.id}
              closeRepresentativeModal={() => setRepresentativeModal(false)}
              idDocument={(id: number) => setIdDocument(id)}
            />
          )}
        </Modal>
      </Grid>
    </Grid>
  );
}
