import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routes from './routes';
import { AuthProvider } from './context/authContext';

import './config/i18n';
import ThemeProvider from './context/themeContext';

const queryClient = new QueryClient();

export function App() {
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'reloadMainPage') {
        window.location.reload();
      } if (event.data.fromEpgm) {
        const { data } = event;
        data.fromEpgm = false;
        window.postMessage(data, '*');
      }
    }, false);
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
