import { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';
import { PaymentGroupItemType } from '../../../../@types/activeDebt/debtBox';
import { PaymentGroup } from '../PaymentGroup';

type ItemProps = {
  title: string;
  data: PaymentGroupItemType;
  contributorId?: number;
  receiptTypeId?: number;
  onNegotiation?: () => void;
  onRefetch?: () => void;
};

export function Item(props: ItemProps) {
  const [open, setOpen] = useState(true);

  const renderTotals = () => {
    return (
      <span className={styles.total}>
        {`${props.data?.total_payment_groups || 0} grupo(s) / ${props.data?.total_cdas || 0} CDA(s) / ${props.data?.total_requirements || 0} requerimento(s)`}
      </span>
    );
  };

  return (
    <div className={ styles.container }>
      <a onClick={() => setOpen(!open)} className={styles.content}>
        <span className={styles.title}>
          {props.title}
          {renderTotals()}
        </span>
        {open ? <FaChevronUp /> : <FaChevronDown />}
      </a>
      {open && (
        <div>
          {props.data?.payment_groups.map((group) => (
            <PaymentGroup
              group={group}
              contributorId={props.contributorId}
              receiptTypeId={props.receiptTypeId}
              onNegotiation={props.onNegotiation}
              count={group.inscriptions_ids.length}
              onRefetch={props.onRefetch && props.onRefetch}
            />
          ))}
        </div>
      )}
    </div>
  );
}
