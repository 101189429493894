import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';

type CollapseHeaderProps = {
  open: boolean;
  onClick: () => void;
  title: string;
  count?: number | string;
};

export function CollapseHeader(props: CollapseHeaderProps) {
  return (
    <a className={styles.header} onClick={() => props.onClick()}>
      <span>{props.title}
        {props.count !== undefined && <span className={styles.count}>{` (${props.count})`}</span>}
      </span>
      {props.open ? <FaChevronUp /> : <FaChevronDown /> }
    </a>
  );
}
