import { ReactNode, useState } from 'react';
import styles from './styles.module.scss';

type TabsProps = {
  tabs: {
    id: number;
    content: ReactNode;
    title: string;
  }[];
};

export function Tabs(props: TabsProps) {
  const [selected, setSelected] = useState(props.tabs[0].id);
  return (
    <div className={ styles.container }>
      <div className={ styles.content }>
        {
          props.tabs.map((tab) => (
            <div
              className={`${styles.tab} ${selected === tab.id && styles.selected}`}
              onClick={() => setSelected(tab.id)}
            >
              <span>{tab.title}</span>
            </div>
          ))
        }
      </div>

      {
        props.tabs.find((tab) => tab.id === selected)?.content
      }
    </div>
  );
}
