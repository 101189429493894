import { InputHTMLAttributes, useState } from 'react';
import { t } from 'i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Label } from '../Label';
import styles from './styles.module.scss';
import { Button } from '../Button';
import { Link } from '../Link';

interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelBold?: boolean;
  classNameContainer?: string;
  id?: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}

export function InputFile(props: InputFileProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      props.setValue('document', file);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    props.setValue('document', null);
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    document.getElementById('file-input')?.click();
  };

  return (
    <div className={`${styles.container} ${props.classNameContainer}`}>
      <div className={`${styles.header}`}>
        <Label
          text={props.label || ''}
          required={props.required}
          className={props.labelBold ? styles.labelBold : ''}
        />
      </div>
      <input
        {...props.register('document')}
        type="file"
        className={styles.input}
        disabled={props.disabled}
        data-testid={`cypress-input-${props.id}`}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-input"
      />
      {!selectedFile ? (
        <Button
          title={t('contributorBox.form.address.documentButton')}
          round
          buttonType='default'
          onClick={handleButtonClick}
        />
      ) : (
        <div className={styles.actions}>
          <Link href={'#'}>
            {selectedFile.name}
          </Link>
          <FaRegTrashAlt onClick={removeFile}/>
        </div>
      )}
    </div>
  );
}
