import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ReceiptTypeSearch } from '../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import Format from '../../../helpers/format';

type ReceiptTypesTabsProps = {
  receiptTypes: ReceiptTypeSearch[];
  loading?: boolean;
  onChange: (receiptType?: ReceiptTypeSearch) => void;
  contributor?: any;
};

export function ReceiptTypesTabs(props: ReceiptTypesTabsProps) {
  const [selected, setSelected] = useState<number | undefined>(props.receiptTypes[0]?.id);

  useEffect(() => {
    const receiptType = props.receiptTypes.find((rt) => rt.id === selected);
    if (receiptType) {
      props.onChange(receiptType);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(undefined);
  }, [props.contributor]);

  return (
    <div className={ styles.container }>
      {
        props.receiptTypes.map((rt) => (
          <a
            className={ `${styles.button} ${selected === rt.id ? styles.selected : ''}` }
            onClick={() => setSelected(rt.id)}
            data-cy='receipt-type-link'
          >
            <span className={ styles.description }>
              {rt.description}
            </span>
            <span className={ styles.data }>
              {t('debtBox.search.receiptTypes.cdaAndRequeriments', { cdas: rt.total_cdas, requeriments: rt.total_requeriments })}
            </span>
            <span className={ styles.data }>
              {t('debtBox.search.receiptTypes.totalBalance', { balance: Format.currency(rt.total_balance) })}
            </span>
          </a>
        ))
      }
    </div>
  );
}
