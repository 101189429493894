import { t } from 'i18next';
import { Grid, Skeleton } from '@mui/material';
import { AttendanceTable } from './AttendanceTable';
import { Contributor } from '../../../../@types/contributor';
import styles from './styles.module.scss';

type AttendanceListProps = {
  contributor: Contributor;
};

export function AttendanceList(props: AttendanceListProps) {
  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={12}>
        <p className={styles.text}>
          {`
            ${t('contributorBox.details.attendance.message')}
          `}
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {(!props.contributor) && (
          <Skeleton height={350} variant='rectangular' />
        )}
        {(props.contributor) && (
          <Grid container spacing={2} className={styles.container}>
            <Grid item xs={12} sm={12} md={12}>
              <AttendanceTable
                attendanceList={props.contributor.contributor_attendance_histories}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
