import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { RoundButton } from '../../../../../components/RoundButton';
import { Table } from '../../../../../components/Table';
import { AttendanceHistory } from '../../../../../@types/contributor';
import { getPaginationTitle } from '../../../../../helpers/strings';
import Show from '../../../../../components/Show';
import styles from './styles.module.scss';

type AttendanceTableProps = {
  attendanceList: AttendanceHistory[];
};

export function AttendanceTable(props: AttendanceTableProps) {
  const [currentData, setCurrentData] = useState<AttendanceHistory[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPerPage = 10;

  useEffect(() => {
    if (props.attendanceList) {
      const lastIndex = currentPage * totalPerPage;
      const firstIndex = lastIndex - totalPerPage;
      setCurrentData(props.attendanceList.slice(firstIndex, lastIndex));
    }
  }, []);

  const handlePage = (event: string) => {
    if (props.attendanceList) {
      const totalPages = Math.floor(props.attendanceList.length / totalPerPage) + 1;
      let page = 1;
      if (event === 'prev' && currentPage > 1 && totalPages > 1) {
        page = currentPage - 1;
      } else if (event === 'next' && currentPage < totalPages) {
        page = currentPage + 1;
      }
      const lastIndex = page * totalPerPage;
      const firstIndex = lastIndex - totalPerPage;
      setCurrentData(props.attendanceList.slice(firstIndex, lastIndex));
      setCurrentPage(page);
    }
  };

  const parseDate = (value: string) => {
    const date = parseISO(value);
    return format(date, 'dd/MM/yyyy');
  };

  return (
    <>
      <Show if={props.attendanceList.length > 0}>
        <div className={`${styles.pagination}`}>
          <span className={`${styles.paginationItem}`}>
            {getPaginationTitle(currentPage, totalPerPage, props.attendanceList.length)}
          </span>
          <RoundButton
            className={ styles.button }
            icon={<FaChevronLeft/>}
            size='small'
            onClick={() => handlePage('prev')}
          />
          <RoundButton
            className={ styles.button }
            icon={<FaChevronRight/>}
            size='small'
            onClick={() => handlePage('next')}
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th>{t('contributorBox.details.attendance.date')}</th>
              <th>{t('contributorBox.details.attendance.place')}</th>
              <th>{t('contributorBox.details.attendance.attendant')}</th>
              <th>{t('contributorBox.details.attendance.subject')}</th>
            </tr>
          </thead>
          <tbody>
            { currentData
              ? currentData.map((item: AttendanceHistory) => (
                <tr>
                  <td>{parseDate(item.created_at)}</td>
                  <td>{item.attendance_place}</td>
                  <td>{item.attendant}</td>
                  <td>{item.attendance_subject}</td>
                </tr>
              )) : <></>
            }
          </tbody>
        </Table>
      </Show>
      <Show if={props.attendanceList.length < 1}>
        <p className={styles.text}>{t('contributorBox.details.attendance.emptyData')}</p>
      </Show>
    </>
  );
}
