import { CustomReportVariables } from '../components/CustomReport';
import Axios from '../config/api';

export default class ReportService {
  public static async getFullExtractReport(contributorId: number) {
    return Axios.get('api/reports/inscriptions', {
      params: {
        contributor_id: contributorId,
      },
    })
      .then((res) => res.data);
  }

  public static async getCustomReport(params: any) {
    return Axios.get('api/reports/inscriptions/emit_extract', {
      params,
    })
      .then((res) => res.data);
  }

  public static async getActiveNegotiationsReport(contributorId: number) {
    return Axios.get('api/reports/inscriptions/emit_extract', {
      params: {
        contributor_id: contributorId,
      },
    })
      .then((res) => res.data);
  }

  public static async getPaymentRequestSimulation(contributorId: number, receiptTypeId: number, inscriptionsIds: number[]) {
    const body = {
      contributor_id: contributorId,
      receipt_type_id: receiptTypeId,
      inscriptions_ids: inscriptionsIds,
    };
    return Axios.post('api/reports/payment_requests/installment_simulate', body).then((res) => res.data);
  }

  public static async getCustomReportVariables(inscriptionsIds: number[], contributorId: number) {
    const data = { inscriptions_ids: inscriptionsIds, contributor_id: contributorId };
    return Axios.post<CustomReportVariables>('api/reports/variables_for_report', data)
      .then((res) => res.data);
  }
}
