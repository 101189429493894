import { t } from 'i18next';
import { Grid, Skeleton } from '@mui/material';
import { useContext, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { Button } from '../../../../components/Button';
import { Subtitle } from '../../../../components/Subtitle';
import styles from './styles.module.scss';
import { HomeContext } from '../../../../context/homeContext';
import { Contributor } from '../../../../@types/contributor';
import { AddressTable } from './AddressTable';
import { UpdateAddressForm } from './UpdateAddressForm';
import ContributorService from '../../../../services/contributorService';
import { downloadFile } from '../../../../helpers/downloadPDF';

type AddressListProps = {
  contributor: Contributor;
  refetch: () => void;
};

export function AddressList(props: AddressListProps) {
  const { setModal } = useContext(HomeContext);
  const [loadingDocument, setLoadingDocument] = useState(false);

  const openNewAddressModal = () => {
    setModal({
      visible: true,
      icon: <FaLocationDot />,
      title: t('contributorBox.details.address.update'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'large',
      children: (
        <UpdateAddressForm
          contributorId={props.contributor.id}
          closeModal={() => setModal(undefined)}
          refetch={() => props.refetch()}
        />
      ),
    });
  };

  const handleGenerateAddressDoc = () => {
    if (props.contributor.current_address[0]) {
      setLoadingDocument(true);
      const address = props.contributor.current_address[0];
      const email = props.contributor.currents_contributor_contact_histories[0] ? props.contributor.currents_contributor_contact_histories[0].email : '';
      const phone = props.contributor.currents_contributor_contact_histories[0] ? props.contributor.currents_contributor_contact_histories[0].telephone : '';
      const cellphone = props.contributor.currents_contributor_contact_histories[0] ? props.contributor.currents_contributor_contact_histories[0].cell_phone : '';
      const data = {
        contributor: {
          name: props.contributor.name,
          phone,
          cellphone,
          email,
          addresses_attributes: {
            cep: address.cep,
            street: address.street,
            street_number: address.street_number,
            address_complement: address.address_complement,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
          },
        },
      };
      ContributorService.generateAddressDocument(data)
        .then((res) => {
          setLoadingDocument(false);
          downloadFile(res, 'declaracao-de-endereco.pdf');
        })
        .catch(() => setLoadingDocument(false));
    }
  };

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={12}>
        <p className={styles.text}>
          {`
            ${t('contributorBox.details.address.message1')}
            ${t('contributorBox.details.address.message2')}
            ${t('contributorBox.details.address.message3')}
            ${t('contributorBox.details.address.message4')}
          `}
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={styles.buttons}>
        <Button
          dataCy={'cypress-open-address-modal-button'}
          title={t('contributorBox.details.address.generateAddressDoc')}
          buttonType='default'
          onClick={handleGenerateAddressDoc}
          disabled={loadingDocument}
          round
        />
        <Button
          dataCy={'cypress-open-address-modal-button'}
          title={t('contributorBox.details.address.update')}
          buttonType='primary'
          onClick={openNewAddressModal}
          round
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {(!props.contributor) && (
          <Skeleton height={350} variant='rectangular' />
        )}
        {(props.contributor) && (
          <Grid container spacing={2} className={styles.container}>
            <Grid item xs={12} sm={12} md={12}>
              <Subtitle text={t('contributorBox.details.address.current')} />
              <AddressTable
                addressList={props.contributor.current_address}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Subtitle text={t('contributorBox.details.address.old')} />
              <AddressTable
                addressList={props.contributor.other_addresses}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
