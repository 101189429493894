import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { Table } from '../../../components/Table';
import { CollapseHeader } from '../../../components/CollapseHeader';

type GeneratedBilletsProps = {
  id: number;
  reloadId?: number;
};

export function GeneratedBillets(props: GeneratedBilletsProps) {
  const [open, setOpen] = useState(true);
  const loadData = async () => {
    if (props.id) {
      const result = await DebtBoxService.getPaymentRequestGeneratedBillets(props.id);
      return result;
    }
    return null;
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`paymentRequestBillets-${props.reloadId}`],
    queryFn: loadData,
  });

  useEffect(() => {
    if (open && !data) {
      refetch();
    }
  }, [open]);

  const renderPaidBillet = (paid?: string) => {
    if (paid) {
      return paid;
    }
    return <span className={styles.notPaid}>{t('paymentRequest.billets.notPaid')}</span>;
  };

  const renderDueDateList = (dates: any[]) => {
    return (
      dates.map((d) => (
        <div>
          <span>{`${d.number} - ${d.due_date}`}</span>
          <br/>
        </div>
      ))
    );
  };

  return (
    <div className={ styles.container }>
      <CollapseHeader
        open={open}
        onClick={() => setOpen(!open)}
        title={t('paymentRequest.billets.title')}
        count={data ? data.result.length : undefined}
      />
      {open && (
        <>
          {(!data && (isLoading || isRefetching)) && (
            <Skeleton height={80} variant='rectangular' />
          )}
          {data && (
            <div className={styles.table}>
              <Table>
                <thead>
                  <tr>
                    <th>{t('paymentRequest.billets.number')}</th>
                    <th>{t('paymentRequest.billets.id')}</th>
                    <th>{t('paymentRequest.billets.cratedBy')}</th>
                    <th>{t('paymentRequest.billets.cratedAt')}</th>
                    <th>{t('paymentRequest.billets.pendencies')}</th>
                    <th>{t('paymentRequest.billets.actions')}</th>
                    <th>{t('paymentRequest.billets.total')}</th>
                    <th>{t('paymentRequest.billets.due')}</th>
                    <th>{t('paymentRequest.billets.dueDoc')}</th>
                    <th>{t('paymentRequest.billets.paidAt')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.result.map((billet) => (
                      <tr>
                        <td>{billet.installments_number_to_sentence_with_ranges}</td>
                        <td>{billet.formatted_number}</td>
                        <td>{billet.generated_by}</td>
                        <td>{billet.created_at}</td>
                        <td>
                          {billet.billet_pendencies ? (
                            <Tooltip title={billet.billet_pendencies}>
                              <div className={styles.pendenciesAlert}>
                                <FaExclamationTriangle />
                              </div>
                            </Tooltip>
                          ) : '-'}
                        </td>
                        <td>-</td>
                        <td>{billet.amount_to_pay}</td>
                        <td>{
                          Array.isArray(billet.installments_due_date) ? (
                            renderDueDateList(billet.installments_due_date)
                          ) : billet.installments_due_date
                        }</td>
                        <td>{billet.dam_due_date}</td>
                        <td>{renderPaidBillet(billet.settlement_date)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
}
