import { useState } from 'react';
import { InscriptionsByPaymentGroupResponse } from '../../../../../@types/activeDebt/debtBox';
import { PillInfo } from '../../../../../components/PillInfo';
import styles from './styles.module.scss';
import { ProtestInfo } from '../../../../../components/ProtestInfos';
import { optionalPropsController } from '../../../../../helpers/optionalPropsController';

type TagsProps = {
  inscription: InscriptionsByPaymentGroupResponse;
};

const listOfTags = [
  'addresses',
  'protested',
  'sent_protest',
  'administrative_inscription',
  'inscription_base_document',
  'digital_processes',
  'sent_legal_action',
  'legal_action',
  'banned_protest',
  'payment_requests',
  'dejudicialized',
  'refis',
];

export function Tags(props: TagsProps) {
  const [protestModalState, setProtestModalState] = useState(false);
  const adminInscription = props.inscription.administrative_inscription;

  const protestInfoModal = (e?: any) => {
    setProtestModalState(!protestModalState);
    if (e) {
      e.stopPropagation();
    }
  };

  return (
    <div className={ styles.container }>
      {props.inscription.pills.filter((p) => listOfTags.includes(p.key)).map((tag) => (
        <PillInfo
          id={tag.key}
          text={tag.description}
          other_description={tag.other_description}
          title={tag.title}
          label={tag.label}
          adminInscription={adminInscription}
          {...optionalPropsController(tag.key, () => protestInfoModal(), tag.link.length > 0 && tag.link[0].id)}
        />
      ))}
      <ProtestInfo
        modalState={protestModalState}
        inscriptionsId={props.inscription.id}
        openProtestInfoModal={(e: any) => protestInfoModal(e)}
      />
    </div>
  );
}
