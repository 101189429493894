import { ReactNode } from 'react';

import styles from './styles.module.scss';

type ReportMainProps = {
  children: ReactNode;
};

export function Main(props: ReportMainProps) {
  return (
    <main>
      <div className={styles.container}>
        { props.children }
      </div>
    </main>
  );
}
