import { InscriptionsByPaymentGroupResponse, PaymentGroupType } from '../@types/activeDebt/debtBox';

export function displayApproveRequirement(code: string): boolean {
  return code === 'debts_in_requested_requirement';
}

export function displayInscriptCda(code: string): boolean {
  return code === 'debts_in_approved_requirement';
}

export function displayDownloadCda(code: string): boolean {
  const validCodes = [
    'debts_in_legal_action',
    'debts_in_adminstrative_step',
    'debts_in_negotiation',
  ];
  return validCodes.includes(code);
}

export function displayPaymentRequest(code: string): boolean {
  const validCodes = [
    'debts_in_legal_action',
    'debts_in_adminstrative_step',
  ];
  return validCodes.includes(code);
}

export function displayPaymentRequestBillet(code: string): boolean {
  return code === 'debts_in_negotiation';
}

export function displayCancelPaymentRequest(code: string): boolean {
  return code === 'debts_in_negotiation';
}

export function displayExtract(code: string): boolean {
  const invalidCodes = [
    'debts_in_approved_requirement',
    'debts_in_rejected_requirement',
    'debts_in_requested_requirement',
  ];

  return !invalidCodes.includes(code);
}

export function displayBillet(code: string): boolean {
  const invalidCodes = [
    'debts_in_approved_requirement',
    'debts_in_rejected_requirement',
    'debts_in_requested_requirement',
    'debts_with_restriction',
    'debts_extinct',
  ];

  return !invalidCodes.includes(code);
}

export function hasMultiplePaymentGroup(inscriptions: InscriptionsByPaymentGroupResponse[]): boolean {
  const hasLegalAction = inscriptions.some(
    (inscription) => inscription.payment_group_code === 'debts_in_legal_action',
  );

  const hasAdministrativeStep = inscriptions.some(
    (inscription) => inscription.payment_group_code === 'debts_in_adminstrative_step',
  );

  return hasLegalAction && hasAdministrativeStep;
}

export function hasPaymentGroupInscription(inscriptions: InscriptionsByPaymentGroupResponse[], ids: number[]): boolean {
  return ids.some((id) => inscriptions.some((inscription) => inscription.id === id));
}

export function canGeneratePaymentRequestBillet(inscriptions: InscriptionsByPaymentGroupResponse[], ids: number[]): boolean {
  const allIdsPresent = ids.every((id) => inscriptions.some((inscription) => inscription.id === id));
  return allIdsPresent;
}

export function checkRestriction(
  inscription: InscriptionsByPaymentGroupResponse,
  inscriptionsSelected: InscriptionsByPaymentGroupResponse[],
  canApproveRequirement: boolean,
): boolean {
  const validCodes = [
    'debts_in_legal_action',
    'debts_in_adminstrative_step',
  ];

  const invalidCodes = [
    'debts_with_restriction',
  ];

  const requirementCodes = [
    'debts_in_approved_requirement',
    'debts_in_rejected_requirement',
    'debts_in_requested_requirement',
  ];

  if (invalidCodes.includes(inscription.payment_group_code)) {
    return true;
  }

  if (requirementCodes.includes(inscription.payment_group_code) && !canApproveRequirement) {
    return true;
  }

  if (inscriptionsSelected.length === 0) {
    return false;
  }

  if (validCodes.includes(inscription.payment_group_code)) {
    const hasDifferentGroup = inscriptionsSelected.some(
      (selected) => !validCodes.includes(selected.payment_group_code),
    );
    return hasDifferentGroup;
  }

  const hasMatchingInscription = inscriptionsSelected.some(
    (selected) => selected.payment_group_code === inscription.payment_group_code
    && selected.payment_request_code === inscription.payment_request_code,
  );

  return !hasMatchingInscription;
}

export function checkGlobalRestriction(
  payment_group_code: string,
  payment_request_code: string,
  inscriptionsSelected: InscriptionsByPaymentGroupResponse[],
  canApproveRequirement: boolean,
): boolean {
  const validCodes = [
    'debts_in_legal_action',
    'debts_in_adminstrative_step',
  ];

  const invalidCodes = [
    'debts_with_restriction',
  ];

  const requirementCodes = [
    'debts_in_approved_requirement',
    'debts_in_rejected_requirement',
    'debts_in_requested_requirement',
  ];

  if (invalidCodes.includes(payment_group_code)) {
    return true;
  }

  if (requirementCodes.includes(payment_group_code) && !canApproveRequirement) {
    return true;
  }

  if (inscriptionsSelected.length === 0) {
    return false;
  }

  if (validCodes.includes(payment_group_code)) {
    const hasDifferentGroup = inscriptionsSelected.some(
      (selected) => !validCodes.includes(selected.payment_group_code),
    );
    return hasDifferentGroup;
  }

  const hasMatchingInscription = inscriptionsSelected.some(
    (selected) => selected.payment_group_code === payment_group_code
      && selected.payment_request_code === payment_request_code,
  );

  return !hasMatchingInscription;
}

export function checkInscriptionsGroup(
  inscriptions: InscriptionsByPaymentGroupResponse[],
  paymentGroup: PaymentGroupType,
): boolean {
  if (inscriptions.length === 0) return false;

  return inscriptions.some((inscription) => paymentGroup.inscriptions_ids.includes(inscription.id));
}
