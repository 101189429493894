import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Subtitle } from '../../../components/Subtitle';
import { DocumentList } from './DocumentList';
import { OptionType } from '../../../@types/config';
import InscriptionService from '../../../services/inscriptionService';
import { Link } from '../../../components/Link';

type DocumentsProps = {
  inscriptionId: number;
};

export function Documents(props: DocumentsProps) {
  const [documentTypes, setDocumentTypes] = useState<OptionType[]>([{ label: '', value: 0 }]);

  const getDocumentTypes = async () => {
    const data = await InscriptionService.getInscriptionsDocumentsType();
    setDocumentTypes(data.result);
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);
  const loadData = async () => {
    if (props.inscriptionId) {
      const result = await InscriptionService.getInscriptionsDocuments(props.inscriptionId);
      return result;
    }
    return null;
  };

  const {
    data, isLoading, isRefetching, refetch,
  } = useQuery({
    queryKey: ['documentList'],
    queryFn: loadData,
  });
  return (
    <Grid container gap={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Subtitle text={t('inscription.documents.inscriptionsDocuments')} />
        <DocumentList
          inscriptionId={props.inscriptionId}
          documents={data?.inscription_documents || []}
          isLoading={isLoading || isRefetching}
          refetch={refetch}
          documentTypes={documentTypes}
          showAttachButton
        />
      </Grid>
      {
        data?.inscription_payment_requests.map((paymentRequest) => (
          <Grid item xs={12} sm={12} md={12}>
            <Subtitle
              text={
                `${t('inscription.documents.paymentRequestNumber')}
                ${paymentRequest.payment_request_code} - ${paymentRequest.payment_request_status}`
              }
              rightItem={(
                <Link href={`/payment_request/${paymentRequest.payment_request_id}`} target='_blank'>
                  {t('inscription.documents.showPaymentRequest')}
                </Link>
              )}
            />
            <DocumentList
              inscriptionId={props.inscriptionId}
              documents={paymentRequest.documents || []}
              isLoading={isLoading || isRefetching}
              refetch={refetch}
              documentTypes={documentTypes}
            />
          </Grid>
        ))
      }
    </Grid>
  );
}
