import { FaCircle } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import styles from './styles.module.scss';
import { Item } from '../Item';
import { Timeline, TimelineItem } from '../../../../@types/activeDebt/inscription';

type DayProps = {
  day: Timeline;
  last?: boolean;
  first?: boolean;
  selected?: number;
};

export function Day(props: DayProps) {
  const lastStepVerify = () => {
    if (props.first && !props.last) {
      return (
        <FaCircle className={styles.currentDot}/>
      );
    }
    if (props.last) {
      return (
        <FaCircle className={styles.startDot}/>
      );
    } return (
      <FaCircle className={styles.standardDot}/>
    );
  };

  return (
    <div className={styles.container }>
      <div className={`${styles.timeline} ${props.last ? styles.last : ''}`}>
        {lastStepVerify()}
        <div className={styles.content}>
          <p className={styles.date}>{format(parseISO(props.day.date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</p>
          {
            props.day.itens.map((dayItem: TimelineItem, index: number) => (
              <Item
                data={dayItem}
                first={index === 0}
                last={index === (props.day.itens.length - 1)}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}
