import { Modal as ModalMui } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { FaTimes } from 'react-icons/fa';
import { HomeContext } from '../../context/homeContext';
import { Button } from '../Button';
import styles from './styles.module.scss';
import Show from '../Show';
import { CheckBox } from '../CheckBox';
import { ButtonType } from '../../@types/button';

const ERROR_ANIMATION = require('../../assets/lotties/error.json');
const WARNING_ANIMATION = require('../../assets/lotties/warning.json');
const LOADING_ANIMATION = require('../../assets/lotties/loading.json');

type AlertProps = {
  visible?: boolean,
  title?: string,
  text?: string,
  type?: string,
  disabledButtons?: boolean;
  confirmType?: ButtonType;
  cancelType?: ButtonType;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
  autoFocus?: boolean;
};

export function Alert(props: AlertProps) {
  const { alert, setAlert } = useContext(HomeContext);
  const [agreement, setAgreement] = useState(false);

  const getAnimation = (type: string) => {
    if (type === 'error' || props.type === 'error') {
      return ERROR_ANIMATION;
    }
    if (type === 'loading' || props.type === 'loading') {
      return LOADING_ANIMATION;
    }
    return WARNING_ANIMATION;
  };

  const isVisible = () => {
    return alert?.visible || props.visible || false;
  };

  const handleClose = () => {
    if (alert?.type !== 'loading') {
      if (props.onCancelClick) {
        props.onCancelClick();
      } else {
        setAlert();
      }
    }
  };

  useEffect(() => {
    setAgreement(false);
  }, [alert]);

  if (alert) {
    return (
      <ModalMui
        open={isVisible()}
        onClose={handleClose}
        disableAutoFocus={true}
        onBackdropClick={handleClose}
        >
        <div className={styles.box}>
          <div className={styles.body}>
            <Show if={alert.type !== 'loading'}>
              <a className={styles.closeButton} onClick={handleClose}>
                <FaTimes />
              </a>
            </Show>
            <div className={styles.icon}>
              <Player
                loop={alert.type === 'loading' || props.type === 'loading'}
                autoplay
                keepLastFrame={true}
                src={getAnimation(alert.type || '')}
                speed={2}
                className={styles.lottie}
              >
                <Controls visible={false} />
              </Player>
            </div>
            <span className={styles.title}>{alert.title || props.title}</span>
            <span className={styles.text}>{alert.text || props.text}</span>

            { alert.agreementText
              && <CheckBox
                  fontSmall
                  value={agreement}
                  onClick={() => setAgreement(!agreement)}
                  removeTooltip
                  text={alert.agreementText}
                  color='var(--primary4)' />
            }

            { alert.handleConfirm && !props.disabledButtons
              ? <div className={styles.actions}>
                <Button
                  buttonType={alert.cancelType || props.cancelType || 'default'}
                  round
                  size='flat'
                  title={alert.cancelText || t('general.no')}
                  onClick={() => (props.onCancelClick ? props.onCancelClick() : setAlert())}
                />
                <Button
                  buttonType={alert.confirmType || props.confirmType || 'primary'}
                  round
                  autoFocus={props.autoFocus}
                  size='flat'
                  title={alert.confirmText || t('general.yes')}
                  onClick={props.onConfirmClick ? props.onConfirmClick : alert.handleConfirm}
                  disabled={alert.agreementText !== undefined && !agreement}
                />
              </div>
              : null
            }
          </div>
        </div>
      </ModalMui>
    );
  }
  return <></>;
}
