import { ReactNode } from 'react';
import styles from './styles.module.scss';

type ReportCardProps = {
  iconTitle?: any;
  title: string;
  largeClass: string;
  grayStyle?: boolean
  custom?: boolean
  info?: boolean
  subtitle?: string;
  children: ReactNode;
};

export function Card(props: ReportCardProps) {
  return (
    <div className={`${styles.card} ${props.largeClass}`}>
      <div className={`${styles.headerCard} ${props.custom ? styles.custom : ''} ${props.info ? styles.info : ''} ${props.grayStyle && styles.grayStyle}`}>
        <div className={styles.cardTitle}>
          <span className={styles.iconTitle}>{props.iconTitle}</span>
          <h4>{props.title}</h4>
        </div>
        {props.subtitle && <i>{props.subtitle}</i>}
      </div>
      <div className={styles.children}>
        {props.children}
      </div>
    </div>
  );
}
