import axios from 'axios';
import { DEBITBOX_AGILIS_API_URL, AGILIS_ACCESS_TOKEN } from './constants';

const instance = axios.create({
  baseURL: DEBITBOX_AGILIS_API_URL,
  timeout: 3600000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Barrer ${AGILIS_ACCESS_TOKEN}`,
  },
});

if (localStorage.getItem('currentUserToken')) {
  instance.defaults.headers.common['Session-Token'] = `${localStorage.getItem('currentUserToken')}`;
}

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    const url = new URL(error.response?.request?.responseURL).pathname;
    if (url !== '/api/me/sign_in' && !window.location.pathname.includes('forgot_password')) {
      window.location.href = '/';
    }
  }
  return Promise.reject(error);
});

export default instance;
