import { t } from 'i18next';
import styles from './styles.module.scss';
import { Toast } from '../Toast';

export default function BlockedAccess() {
  return (
    <div className={styles.container}>
      <Toast
        open={true}
        type={'warning'}
        text={t('debtBox.tax.selicBlock')}
        widthFull
      />
    </div>
  );
}
