import {
  CalculationPatterns,
  ContributorCdasResponseType,
  ContributorSearch,
  DebtByInscriptionResponse,
  PaymentGroupResponseType,
  PaymentRequestDetailsResponse,
  PaymentRequestGeneratedBilletsResponse,
  PaymentRequestGeneratedPendenciesResponse,
  PaymentRequestPaidBilletsResponse,
  PaymentRequestUnpaidBilletsResponse,
} from '../@types/activeDebt/debtBox';
import { AttachedDocument, InscriptionsAdministrativeDataResumeType } from '../@types/activeDebt/inscription';
import {
  AproveRequeriment, NewPaymentRequest, NewPaymentRequestResponse, Simulation,
} from '../@types/activeDebt/paymentRequest';
import Axios from '../config/api';
import { SelectedInscriptions } from '../pages/DebtBoxSearch/Footer';

export default class DebtBoxService {
  public static async getContributorList(text: string) {
    return Axios.get(`api/autocomplete/active_debt/contributors?q[contributors_data_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByProcessNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[judicial_process_number_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[administrative_inscription_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByCdaNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[cda_number_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByProtestProtocolList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/payment_requests?q[protest_protocol_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByInstallmentNumberList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/payment_requests?q[code_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getInscriptionsByKeyList(text: string) {
    return Axios.get(`/api/autocomplete/active_debt/inscriptions?q[sefin_key_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getContributors(query: string) {
    return Axios.get<ContributorSearch>(`api/inscriptions/contributors?${query}`)
      .then((res) => res.data);
  }

  public static async getReceiptTypesByContributor(contributorId: number, inscriptions: number[]) {
    const body = {
      inscriptions_ids: inscriptions,
      contributor_id: contributorId,
    };
    return Axios.post('api/inscriptions/receipt_types_by_contributor', body)
      .then((res) => res.data);
  }

  public static async getCdasExtractByInscriptions(inscriptions: number[]) {
    const body = {
      inscriptions_ids: inscriptions,
    };
    return Axios.post<ContributorCdasResponseType>('api/inscriptions/contributor_cdas_actives_and_extincts', body)
      .then((res) => res.data);
  }

  public static async getPaymentGroupsByContributorAndReceiptType(
    contributorId: number,
    receiptType_id: number,
    inscriptionIds?: number[],
  ) {
    const body = {
      contributor_id: contributorId,
      receipt_type_id: receiptType_id,
      inscriptions_ids: inscriptionIds,
    };
    return Axios.post<PaymentGroupResponseType>('api/inscriptions/payment_groups', body)
      .then((res) => res.data);
  }

  public static async getInscriptionsByPaymentGroup(inscriptions: number[], page?: number) {
    const body = {
      inscriptions_ids: inscriptions,
      page,
    };
    return Axios.post('api/inscriptions/consult_debts_lists', body)
      .then((res) => res.data);
  }

  public static async getResumeInscriptions(data: any) {
    return Axios.post<InscriptionsAdministrativeDataResumeType>('api/inscriptions/resume', data)
      .then((res) => res.data);
  }

  public static async getDebtsByInscription(inscription: number) {
    return Axios.get<DebtByInscriptionResponse>(`api/inscriptions/${inscription}/debt_list`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestDetails(id: number) {
    return Axios.get<PaymentRequestDetailsResponse>(`api/payment_requests/${id}/details`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestPaidBillets(id: number) {
    return Axios.get<PaymentRequestPaidBilletsResponse[]>(`api/payment_requests/${id}/paid_billets`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestUnpaidBillets(id: number) {
    return Axios.get<PaymentRequestUnpaidBilletsResponse>(`api/payment_requests/${id}/unpaid_installments`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestGeneratedBillets(id: number) {
    return Axios.get<PaymentRequestGeneratedBilletsResponse>(`api/payment_requests/${id}/generated_dams`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestPendencies(id: number) {
    return Axios.get<PaymentRequestGeneratedPendenciesResponse>(`api/payment_requests/${id}/generated_dam_pending_list`)
      .then((res) => res.data);
  }

  public static async paymentRequestGenerateDocument(id: number) {
    return Axios.post(`api/payment_requests/${id}/recognition_agreement`)
      .then((res) => res.data);
  }

  public static async getPaymentRequestInstallmentTerm(id: number, documentId: number) {
    return Axios.get(`api/payment_requests/${id}/recognition_agreement?document_id=${documentId}`)
      .then((res) => res.data);
  }

  public static async attachPaymentRequestDocuments(id: number, attach_documents: AttachedDocument[]) {
    const formData = new FormData();
    attach_documents.map((files: AttachedDocument) => {
      formData.append('attach_documents[][archive]', files.file);
      formData.append('attach_documents[][status]', files.type);
    });
    return Axios.post(`api/payment_requests/${id}/attach_documents`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res);
  }

  public static async deletePaymentRequestDocument(id: number, documentId: number) {
    return Axios.delete(`api/payment_requests/${id}/attach_document/${documentId}`)
      .then((res) => res.data);
  }

  public static async getSelectedInscriptionsInfo(contributorId: number, receiptTypeId: number, ids: number[]) {
    return Axios.post<SelectedInscriptions[]>('api/inscriptions/total_inscriptions_by_payment_groups', {
      contributor_id: contributorId,
      receipt_type_id: receiptTypeId,
      inscriptions_ids: ids,
    }).then((res) => res.data);
  }

  public static async getTotalValueByInscriptions(contributorId: number, receiptTypeId: number, ids: number[]) {
    return Axios.post('api/payment_requests/inscriptions', {
      contributor_id: contributorId,
      receipt_type_id: receiptTypeId,
      inscriptions_ids: ids,
    }).then((res) => res.data);
  }

  public static async getPaymentRequestSimulation(data: any) {
    return Axios.post<Simulation>('api/payment_requests/simulate', data)
      .then((res) => res.data);
  }

  public static async getInscriptionDetails(id: string) {
    return Axios.get(`api/inscriptions/${id}`)
      .then((res) => res.data);
  }

  public static async getBilletByPaymentRequest(paymentRequestId: number, numbers: number[]) {
    const body = {
      payment_request_id: paymentRequestId,
      number_installments: numbers,
    };
    return Axios.post('api/billets/payment_request', body)
      .then((res) => res.data);
  }

  public static async getCalculationPattners(id: number) {
    return Axios.get<CalculationPatterns>(`api/calculation_patterns/debt/${id}`)
      .then((res) => res.data);
  }

  public static async createPaymentRequest(data: NewPaymentRequest) {
    return Axios.post<NewPaymentRequestResponse>('api/payment_requests', data)
      .then((res) => res.data);
  }

  public static async aproveRequeriment(ids: number[]) {
    const body = { inscriptions_ids: ids };
    return Axios.post<AproveRequeriment>('api/inscriptions/request_approve', body)
      .then((res) => res.data);
  }

  public static async generateInCashBillet(contributorId: number, receiptTypeId: number, ids: number[]) {
    return Axios.post('api/billets/inscriptions', {
      contributor_id: contributorId,
      receipt_type_id: receiptTypeId,
      inscriptions_ids: ids,
    }).then((res) => res.data);
  }

  public static async cancelPaymentRequest(id: number, reason?: string) {
    return Axios.put(`api/payment_requests/cancel/${id}`, { reason })
      .then((res) => res.data);
  }

  public static async inscriptDebts(ids: number[]) {
    const body = { inscriptions_ids: ids };
    return Axios.post<any>('api/inscriptions/inscript_requirement', body)
      .then((res) => res.data);
  }

  public static async deleteInscriptionDocument(inscriptionId: number, documentId: number) {
    return Axios.delete(`api/inscriptions/${inscriptionId}/attach_document/${documentId}`)
      .then((res) => res.data);
  }

  public static async getRepresentativeList(text: string) {
    return Axios.get(`/api/payment_request_representatives/autocomplete?q[representative_data_cont]=${text}`)
      .then((res) => res.data);
  }

  public static async getRepresentativeData(id: number) {
    return Axios.get(`/api/payment_request_representatives/${id}`)
      .then((res) => res.data);
  }

  public static async negotiationRepresentative(representativeForm: any) {
    const body = representativeForm;
    return Axios.post('/api/payment_request_representatives/', body)
      .then((res) => res.data);
  }

  public static async negotiationRepresentativeDocument(id: number) {
    return Axios.get(`/api/shared/documents/${id}`)
      .then((res) => res.data);
  }

  public static async getRepresentativeType() {
    return Axios.get('/api/payment_request_representatives/new')
      .then((res) => res.data);
  }
}
