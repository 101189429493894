import {
  differenceInDays, isPast, isValid, parse,
} from 'date-fns';
import { ReactNode } from 'react';
import { FaClock } from 'react-icons/fa';
import { t } from 'i18next';
import { Table } from '../Table';
import styles from './styles.module.scss';
import { DebtInfo } from './DebtInfo/DebtInfo';
import { CalculationDefaultInfo } from './CalculationDefaultInfo';
import { DebtByInscription } from '../../@types/activeDebt/debtBox';

type DefaultCalculationProps = {
  tableData: DebtByInscription;
};

export function DefaultCalculation(props: DefaultCalculationProps) {
  const renderPrescriptionItem = (prescription: string, icon: ReactNode) => (
    <div className={styles.prescription}>
      {icon}
      <a>{prescription}</a>
    </div>
  );

  const renderPrescription = (prescription: string) => {
    const date = parse(prescription, 'dd/MM/yyyy', new Date());
    let className = '';
    if (!isValid(date)) {
      return renderPrescriptionItem(prescription, <FaClock />);
    }

    if (isPast(date)) {
      className = styles.past;
    } else {
      const differenceDays = differenceInDays(date, new Date());
      className = differenceDays >= 180 ? styles.future : styles.next;
    }

    return renderPrescriptionItem(prescription, <FaClock className={className} />);
  };

  return (
    <div data-cy = 'data-cy-summary-debts' className={styles.summaryDebtsContainer}>
      <span className={styles.subTitle}>{t('debtBox.results.inscriptions.debts.defaultCalculation.debtDecription')}</span>
      <div data-cy='data-cy-table-debts' className={styles.tableContainer}>
        <div className={styles.table}>
          <Table>
            <thead>
              <tr>
                <th className={styles.bold}>
                  {t('debtBox.results.inscriptions.debts.date')}
                </th>
                <th className={styles.bold}>
                  {t('debtBox.results.inscriptions.debts.mainValue')}
                </th>
                <th className={styles.bold}>
                  {t('debtBox.results.inscriptions.debts.fines')}
                </th>
                <th className={styles.leftBorder}>
                  {t('debtBox.results.inscriptions.debts.mainBalance')}
                </th>
                <th>
                  {t('debtBox.results.inscriptions.debts.finesBalance')}
                </th>
                <th>
                  {t('debtBox.results.inscriptions.debts.monetaryCorrection')}
                </th>
                <th>
                  {t('debtBox.results.inscriptions.debts.interestValue')}
                </th>
                <th>
                  {t('debtBox.results.inscriptions.debts.moratoriumFines')}
                </th>
                <th>
                  {t('debtBox.results.inscriptions.debts.feeValue')}
                </th>
                <th className={styles.bold}>
                  {t('debtBox.results.inscriptions.debts.balance')}
                </th>
                <th className={`${styles.bold} ${styles.leftBorder}`}>
                  {t('debtBox.results.inscriptions.debts.prescription')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.dark}>{props.tableData.due_date}</td>
                <td className={styles.dark}>{props.tableData.main_value}</td>
                <td className={styles.dark}>{props.tableData.fines}</td>
                <td className={styles.leftBorder}>{props.tableData.main_balance}</td>
                <td>{props.tableData.fines_balance}</td>
                <td>{props.tableData.total_monetary_correction}</td>
                <td>{props.tableData.total_interest_value}</td>
                <td>{props.tableData.moratorium_fines}</td>
                <td>{props.tableData.fee_value}</td>
                <td className={`${styles.dark} ${styles.bold}`}>{props.tableData.balance}</td>
                <td className={`${styles.dark} ${styles.leftBorder}`}>{renderPrescription(props.tableData.prescription_date)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className={styles.debtInfo}>
        <DebtInfo />
      </div>
      <div>
        <CalculationDefaultInfo debtId={props.tableData.id} />
      </div>
    </div>
  );
}
