import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Footer } from '../../../components/Reports/Footer';
import { Header } from '../../../components/Reports/Header';
import { Main } from '../../../components/Reports/Main';
import { GeneralInfo } from './GeneralInfos';
import { ReceiptTypeSumary } from './ReceiptTypeSumary';
import { PortalFooter } from './PortalFooter';
import Service from '../../../services/inscriptionService';
import styles from './styles.module.scss';

export function Custom() {
  document.body.classList.remove('darkModeTheme');
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');
  const searchObject = search ? JSON.parse(decodeURIComponent(search)) : null;
  const { id } = useParams();

  const addQuery = (query: string, field: string) => {
    const result = query.length > 0 ? query.concat(`&${field}`) : query.concat(`?${field}`);
    return result;
  };

  const getQuery = () => {
    let query = '';
    if (searchObject.contributor) {
      query = addQuery(query, `q[contributor_id_eq]=${searchObject.contributor.value}`);
    }
    if (searchObject.processNumber) {
      query = addQuery(query, `q[judicial_process_number_eq]=${searchObject.processNumber}`);
    }
    if (searchObject.inscription && searchObject.inscription.length > 0) {
      const inscriptions = searchObject.inscription.map((i: any) => `q[administrative_inscription_in][]=${i.label}`);
      query = addQuery(query, inscriptions.join('&'));
    }
    if (searchObject.protestProtocol) {
      query = addQuery(query, `q[payment_requests_protest_protocol_eq]=${searchObject.protestProtocol}`);
    }
    if (searchObject.cda) {
      query = addQuery(query, `q[cda_number_eq]=${searchObject.cda}`);
    }
    if (searchObject.installmentNumber) {
      query = addQuery(query, `q[payment_requests_code_eq]=${searchObject.installmentNumber}`);
    }
    if (searchObject.origin) {
      query = addQuery(query, `q[sefin_key_eq]=${searchObject.origin}`);
    }
    if (searchObject.receiptTypeIdIn) {
      const receiptTypeIds = searchObject.receiptTypeIdIn.map((i: any) => `q[receipt_type_id_in][]=${i.value}`).join('&');
      query = addQuery(query, receiptTypeIds);
    }
    if (searchObject.inscriptionIds) {
      const inscriptionIds = searchObject.inscriptionIds.map((i: any) => `q[id_in][]=${i}`).join('&');
      query = addQuery(query, inscriptionIds);
    }
    if (searchObject.stateIn) {
      const stateIds = searchObject.stateIn.map((i: any) => `[state_ids][]=${i.value}`).join('&');
      query = addQuery(query, stateIds);
    }
    if (searchObject.displayProtest) {
      query = addQuery(query, `display_protest=${searchObject.displayProtest}`);
    }
    if (searchObject.displayLegalAction) {
      query = addQuery(query, `display_legal_action=${searchObject.displayLegalAction}`);
    }
    if (!searchObject.showExtinctDebits && !searchObject.fromModal) {
      query = addQuery(query, `q[all_active]=${!searchObject.showExtinctDebits}`);
    }
    return query;
  };

  const parseFilterObject = () => {
    if (searchObject.fromModal) {
      return [
        {
          key: 'reports.custom.modal.legalAction',
          enabled: searchObject.displayLegalAction,
        },
        {
          key: 'reports.custom.modal.protest',
          enabled: searchObject.displayProtest,
        },
      ];
    }
    return undefined;
  };

  const getContributorData = async () => {
    if (id) {
      const query = getQuery();
      const contributorId = parseInt(id, 10);
      const result = await Service.getContributorSummaryData(contributorId, query);
      return result;
    }
    return '';
  };

  const {
    data,
  } = useQuery({
    queryKey: ['paymentGroups', id, getQuery()],
    queryFn: getContributorData,
    enabled: true,
  });

  const getSearchItems = () => {
    const receiptTypes = searchObject.receiptTypeIdIn?.map((rt: any) => rt.label) || [];
    const states = searchObject.stateIn?.map((rt: any) => rt.label) || [];
    return [...receiptTypes, ...states];
  };

  return (
    <div className={styles.container}>
      <Header
        type={'custom'}
        title={t('reports.fullStatement.title')}
        subtitle={t('reports.custom.subtitle')}
      />
      <Main>
      {data && (
        <GeneralInfo
          data={data}
          fromModal={searchObject.fromModal}
          filter={parseFilterObject()}
          searchItems={getSearchItems()}
        />
      )}
      </Main>
      {data && data.receipt_types.map((rt: any) => (
        <ReceiptTypeSumary
          receipTypeDescription={rt}
          contributorId={id}
          activeInscriptions={rt.active_inscriptions_ids}
          negotiationInscriptions={rt.active_negotiation_inscriptions_ids}
          extinctiInscriptions={rt.extinct_inscriptions_ids}
        />
      ))}
      <Main>
        <PortalFooter />
      </Main>
      <Footer />
    </div>
  );
}
