import { ReactNode } from 'react';
import styles from './styles.module.scss';

type TableProps = {
  children?: ReactNode;
  className?: string;
  flat?: boolean;
  dataCy?: any;
};

export function Table(props: TableProps) {
  return (
    <div data-cy={props.dataCy} className={`${props.flat ? styles.flat : styles.container}`}>
      <table className={`${styles.table} ${props.className ? props.className : ''}`}>
        {props.children}
      </table>
    </div>
  );
}
