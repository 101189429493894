import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FaEdit, FaUserAlt } from 'react-icons/fa';
import { t } from 'i18next';
import { useContext } from 'react';
import { OptionType } from '../../../@types/config';
import ContributorService from '../../../services/contributorService';
import { InputText } from '../../../components/InputText';
import { maskCpfOrCnpj } from '../../../helpers/masks';
import { CurrentUser } from '../../../@types/currentUser';
import Show from '../../../components/Show';
import { Contributor } from '../../../@types/contributor';
import { Tabs } from '../../../components/Tabs';
import { AddressList } from './AddressList';
import { ContactList } from './ContactList';
import { AttendanceList } from './AttendanceList';
import { HomeContext } from '../../../context/homeContext';
import { EditContributorForm } from './EditContributorForm';
import styles from './styles.module.scss';

type ContributorProps = {
  contributorData: OptionType;
};

export function ContributorDetails(props: ContributorProps) {
  const { setModal } = useContext(HomeContext);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const loadData = async () => {
    if (props.contributorData.value) {
      const result = await ContributorService.getContributor(props.contributorData.value);
      return result;
    }
    return null;
  };

  const {
    data, isLoading, refetch,
  } = useQuery({
    queryKey: ['contributorDetails', props.contributorData.value],
    queryFn: loadData,
  });

  const handleLegalPerson = (type: OptionType, document: string) => {
    if (type) {
      return type.label;
    }
    const cleanValue = document.replace(/\D/g, '');
    if (cleanValue.length > 11) {
      return t('contributorBox.details.legalType.legalPerson');
    }
    return t('contributorBox.details.legalType.physicalPerson');
  };

  const openEditContributorModal = (contributorData: Contributor) => {
    setModal({
      visible: true,
      icon: <FaUserAlt />,
      title: t('contributorBox.form.basic.edit'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'large',
      children: (
        <EditContributorForm
          contributor={contributorData}
          closeModal={() => setModal(undefined)}
          refetch={() => refetch()}
        />
      ),
    });
  };

  const renderTabs = (dataRender: Contributor) => {
    const tabs = [
      {
        id: 1,
        title: t('contributorBox.details.address.title'),
        content: (
          <AddressList
            contributor={dataRender}
            refetch={() => refetch()}
          />
        ),
      },
      {
        id: 2,
        title: t('contributorBox.details.contact.title'),
        content: (
          <ContactList
            contributor={dataRender}
            refetch={() => refetch()}
          />
        ),
      },
      {
        id: 3,
        title: t('contributorBox.details.attendance.title'),
        content: (
          <AttendanceList
            contributor={dataRender}
          />
        ),
      },
    ];
    return <Tabs tabs={tabs} />;
  };

  return (
    <div>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={1}
        className={styles.container}
      >
        <Grid item xs={2} sm={6} md={12}>
          {(!data && isLoading) && (
            <Skeleton height={350} variant='rectangular' />
          )}
          {data && (
            <div className={styles.content}>
            <Grid
              container
              columns={{ xs: 2, sm: 6, md: 12 }}
              spacing={2}
            >
              <Grid item xs={2} sm={6} md={12}>
                <div className={styles.header}>
                  <span className={styles.title}>{t('contributorBox.details.label')}</span>
                  <Show if={Boolean(currentUser.can_contributor_edit)}>
                    <span
                      data-cy="cypress-edit-contributor-button"
                      className={styles.actionIcon}
                      onClick={() => openEditContributorModal(data)}
                    >
                      <FaEdit/>
                    </span>
                  </Show>
                </div>
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <InputText
                  dataCy={'cypress-contributor-name-text'}
                  label={t('contributorBox.details.name')}
                  labelBold
                  value={data.name}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={2} md={3}>
                <InputText
                  dataCy={'cypress-contributor-document-text'}
                  label={t('contributorBox.details.document')}
                  labelBold
                  value={maskCpfOrCnpj(data.cpf_cnpj).mask()}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={2} md={3}>
                <InputText
                  dataCy={'cypress-contributor-legal-type-text'}
                  label={t('contributorBox.details.legalType.label')}
                  labelBold
                  value={handleLegalPerson(data.legal_type, data.cpf_cnpj)}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className={styles.tabContainer}>
                {renderTabs(data)}
              </Grid>
            </Grid>
          </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
