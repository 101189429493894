export default {
  translations: {
    faq: {
      title: 'Faq',
      filter: 'Pesquise o que deseja saber...',
      accordion: {
        1: {
          separator: 'Sobre processos',
          title: '1. O que são <bold>processos?</bold>',
          text: 'É um termo que indica a ação de avançar, ir para frente e é um conjunto sequencial e particular de ações com objetivo comum. Na PGM, esses processos são voltados para a defesa judicial e extrajudicial do Município de Fortaleza, pela consultoria jurídica dos órgãos municipais, bem como pela inscrição e cobrança da dívida ativa municipal.',
        },
        2: {
          title: '2. O que é e como é gerado o <bold>nº do processo?</bold>',
          text: 'O número do processo é o código de identificação de um processo administrativo ou judicial. Ele é criado internamente na PGM ou pode ser criado previamente externamente (ex: SPU, ESAJ, IPM etc). Quando é gerado internamente, o número é cadastrado manualmente pela área que o está criando. Já quando é gerado externamente, ele é preenchido manualmente ou vem de forma automática, dependendo do tipo de processo (alguns têm comunicação externa, então o número vem via serviço).',
        },
        3: {
          title: '3. O que são os <bold>fluxos dos processos?</bold>',
          text: 'O fluxo de processo é o conjunto de tarefas que são necessárias para que uma determinada atividade seja realizada.',
        },
        4: {
          title: '4. O que são os <bold>marcadores de prioridade?</bold>',
          text: 'São etiquetas predefinidas utilizadas para categorizar os processos numa escala de baixa, média ou alta prioridade.',
          video: 'KKBoQ-DmZVs',
        },
        5: {
          title: '5. O que são os <bold>marcadores de pendências?</bold>',
          text: 'São etiquetas predefinidas utilizadas para indicar quais as pendências dos processos, podendo ser: documentos, informações ou arquivamento.',
          video: 'yYWOZ3hX0x8',
        },
        6: {
          title: '6. O que são <bold>marcadores personalizados?</bold>',
          text: 'São etiquetas personalizadas vinculadas ao processo. Os marcados personalizados podem ser públicos ou privados. Ao criar um marcador público, ele fica visível para todos os usuários. Ao criar um marcador privado fica visível apenas para o usuário que o criou.',
        },
        7: {
          title: '7. Como <bold>buscar um processo na minha caixa de entrada?</bold>',
          text: 'Coloque o número do processo no campo de “Localizar Processo” que fica no menu superior e busque pelo número desejado. Também é possível procurar um processo que está na sua caixa de entrada, caixa de saída ou arquivado por meio do filtro da sua listagem.',
          video: 'aEMtfdnweUI',
        },
        8: {
          title: '8. Como <bold>buscar um processo no acervo do Ágilis?</bold>',
          text: 'Vá no menu "Acervo de processos" e no campo de busca coloque o dado do processo no campo de “Localizar Processo" e busque por um dado do processo. Também é possível refinar a busca por meio dos filtros.',
          video: 'sv91_Wdlz-g',
        },
        9: {
          title: '9. Quais <bold>andamentos</bold> posso realizar no(s) processo(s)?',
          text: `
          Selecione um ou mais processos que deseja realizar andamento. Ao selecionar irá aparecer no topo as opções de andamentos. Escolha o que deseja executar e clique na opção desejada. Opções de andamento:
          <list>
            <listItem>Definir prazo</listItem>
            <listItem>Adicionar/remover marcadores</listItem>
            <listItem>Tramitar processo </listItem>
            <listItem>Avocar processo</listItem>
            <listItem>Elaborar/editar documento</listItem>
            <listItem>Anexar documento</listItem>
            <listItem>Juntar documentos</listItem>
            <listItem>Assinar documento</listItem>
            <listItem>Excluir documento</listItem>
            <listItem>Arquivar processo</listItem>
            <listItem>Desarquivar processo</listItem>
            <listItem>Editar cadastro</listItem>
            <listItem>Criar subprocesso</listItem>
              <list>
                <listItem>Ofício judicial</listItem>
                <listItem>Ordem de pagamento judicial</listItem>
                <listItem>Pedido eletrônico de cálculo</listItem>
              </list>
            <listItem>Tribunal</listItem>
              <list>
                <listItem>Petição inicial ESAJ e PJE</listItem>
                <listItem>Petição intermediária ESAJ e PJE</listItem>
              </list>
            <listItem>SPU</listItem>
              <list>  
                <listItem>Enviar ofício judicial</listItem>
                <listItem>Responder via SPU</listItem>
              </list>
            <listItem>Encaminhar para IPM</listItem>
          </list>
          `,
        },
        10: {
          title: '10. O que é <bold>ESAJ?</bold>',
          text: `<bold>SAJ - Sistema de Automação Judicial</bold> - <a href="https://esaj.tjce.jus.br/esaj/portal.do?servico=740000">O portal e-SAJ</a> é uma solução especialmente desenvolvida para a Internet, que facilita a troca de informações e agiliza o trâmite processual por meio de diversos serviços WEB voltados para os advogados, cidadãos e serventuários da justiça. O portal constitui-se de um importante canal de comunicação entre o jurisdicionado e a justiça, que fornecerá respostas mais rápidas e eficientes.
          O ePGM comunica-se com o e-SAJ com a finalidade de criar novos processos, enviar arquivos, receber atualizações processuais e receber arquivos de retorno.`,
        },
        11: {
          title: '11. O que é <bold>SPU?</bold>',
          text: `<bold>SPU - Sistema de Protocolo Único</bold> - O <a href="https://spuvirtual.sepog.fortaleza.ce.gov.br/">SPU Virtual</a> permite que o usuário acompanhe o status de seus processos, disponibilizando cores diferentes para cada um, conforme a seguir: No status “Arquivado”, o processo estará concluído, deferido ou indeferido. O usuário deverá consultar os detalhes para saber o encaminhamento do processo.
          O ePGM comunica-se com o SPU com a finalidade de criar novos processos, enviar arquivos, receber atualizações processuais e receber arquivos de retorno.`,
        },
        12: {
          title: '12. O que é <bold>IPM?</bold>',
          text: `<bold>IPM - Instituto de Previdência do Município</bold> - O <a href="https://app.ipmfor.ce.gov.br/#/home">IPM</a> tem por objetivo proporcionar assistência de saúde aos servidores do município de Fortaleza, conforme o regulamento aprovado pelo decreto 11.700, em 2004.
          O ePGM comunica-se com o IPM para receber processos de aposentadoria eletrônica, solicitar diligências, indeferir, indeferir com ressalvas e deferir. Além disso, a comunicação feita entre o IPM e a PGM também reflete no histórico de andamentos do SPU.
          `,
        },
        13: {
          separator: 'Sobre Dívida Ativa',
          title: '13. O que são <bold>dívidas ativas?</bold>',
          text: 'A dívida ativa é uma base de dados que todo governo - federal, estadual e municipal - tem de pessoas ou empresas que estão em inadimplência. Os créditos são inscritos em dívida ativa quando a pessoa (física ou jurídica) deixa de pagar impostos, multas, indenizações, etc.',
        },
        14: {
          title: '14. O que é a <bold>situação da dívida?</bold>',
          text: `A situação da dívida é a conjuntura em que ela se encontra naquele momento no órgão de origem, podendo ser: adimplente, inadimplente, parcial ou sem situação definida.
                <table>
                  <tbody>
                    <tr>
                      <td>A</td>
                      <td>Adimplente</td>
                      <td>Para débitos quitados. Quando uma chave SEFIN está com todos os seus débitos adimplentes.</td>
                    </tr>
                    <tr>
                      <td>I</td>
                      <td>Inadimplente</td>
                      <td>Para débitos em abertos encaminhados, inscritos, ajuizados e etc.</td>
                    </tr>
                    <tr>
                      <td>P</td>
                      <td>Parcial</td>
                      <td>Aplicado aos casos de negociações como parcelamentos ou acordos.</td>
                    </tr>
                    <tr>
                      <td>-</td>
                      <td>Sem situação definida</td>
                      <td>Para os casos de cancelamento de inscrições, onde não é informado o status do débito.</td>
                    </tr>
                  </tbody>
                </table>`,
        },
        15: {
          title: '15. O que é a <bold>fase da dívida?</bold>',
          text: `A fase da dívida é o estágio em que está seu andamento. Há 34 fases previstas na linha do tempo de uma dívida. Elas podem ser agrupadas resumidamente em 5 categorias: <br /> 
          <br /> <bold>Requerimento, Parcelamento ou Acordo, Ajuizamento, Protesto e Extinção.</bold><br />
          <tableInscript>
            <tr>
              <td>Nome da fase</td>
              <td>Descrição</td>
              <td>Situação</td>
            </tr>
            <tr>
              <td>Requerimento solicitado</td>
              <td>O crédito entra nesta fase quando ele acabou de chegar na PGM como um requerimento de solicitação para inscrição em dívida.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Requerimento em análise</td>
              <td>O crédito entra nesta fase quando a análise do requerimento se inicia, um processo é criado e repassado para um procurador analisar.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Requerimento aprovado</td>
              <td>O crédito entra nesta fase após o procurador aprovar o requerimento.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Requerimento indeferido</td>
              <td>O crédito entra nesta fase quando o requerimento está com informações incompletas ou inconsistentes, o que leva ele a ser indeferido.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Inscrito na dívida ativa</td>
              <td>O crédito entra nesta fase após ter seu requerimento aprovado e efetivamente ser inscrito em dívida ativa.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Parcelamento solicitado</td>
              <td>O crédito entra nesta fase quando um parcelamento é gerado e a 1º parcela não foi paga ainda.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Parcelado</td>
              <td>O crédito entra nesta fase quando um parcelamento é gerado e a 1º parcela é paga</td>
              <td>P</td>
            </tr>
            <tr>
              <td>Parcelamento cancelado*</td>
              <td>O crédito entra nesta fase quando o parcelamento é cancelado pela 1º parcela não ter sido paga. A 1º parcela tem que ser paga até o 5º dia útil do mês subsequente, após isso, se não for paga, é cancelada.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Parcelamento perdido</td>
              <td>
              O crédito entra nesta fase quando o parcelamento é perdido. Ele é perdido quando não há pagamentos das parcelas em até 90 dias.<br />

              Obs¹: Existem regras específicas para programas de desconto e refinanciamento.<br />
              
              Obs²: Em parcelamentos sem descontos, cada parcelamento perdido gera uma punição. Ao perder, o sistema diminui a possibilidade de parcelar em 6x. Por exemplo, o crédito poderá ser parcelado em 30x, após perder a primeira vez, ele só poderá parcelar em 24x.<br />
              </td>
              <td>I</td>
            </tr>
            <tr>
              <td>Parcelamento em atraso</td>
              <td>Pedido de parcelamento cancelado</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Parcelamento suspenso</td>
              <td>?</td>
              <td>i</td>
            </tr>
            <tr>
              <td>Acordo solicitado</td>
              <td>O crédito, já ajuizado, entra nesta fase quando é parcelado e a 1º parcela <bold>não foi paga.</bold></td>
              <td>I</td>
            </tr>
            <tr>
              <td>Acordo</td>
              <td>O crédito, já ajuizado, entra nesta fase quando é parcelado e a 1º parcela <bold>foi paga.</bold></td>
              <td>P</td>
            </tr>
            <tr>
              <td>Acordo cancelado*</td>
              <td>O crédito, já ajuizado, entra nesta fase quando o parcelamento é cancelado pela 1º parcela não ter sido paga. A 1º parcela tem que ser paga até o 5º dia útil do mês subsequente, após isso, se não for paga, é cancelada.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Acordo perdido</td>
              <td>
              O crédito, já ajuizado, entra nesta fase quando o parcelamento é perdido. Ele é perdido quando não há pagamentos das parcelas em até 90 dias.<br />

              Obs¹: Existem regras específicas para programas de desconto e refinanciamento.<br />
              
              Obs²: Em parcelamentos sem descontos, cada parcelamento perdido gera uma punição. Ao perder, o sistema diminui a possibilidade de parcelar em 6x. Por exemplo, o crédito poderá ser parcelado em 30x, após perder a primeira vez, ele só poderá parcelar em 24x.<br /></td>
              <td>I</td>
            </tr>
            <tr>
              <td>Acordo em atraso</td>
              <td>O crédito, já ajuizado, entra nesta fase quando o sistema não identifica o pagamento de alguma parcela, que não seja a 1º parcela.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Encaminhada para ajuizamento</td>
              <td>O crédito entra nesta fase quando é criado um processo interno na PGM para que seja peticionado para o ESAJ, ajuizando o TJ sobre a dívida.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Ajuizado</td>
              <td>O crédito entra nesta fase quando o processo é ajuizado e o fluxo de execução fiscal se inicia para o processo ao qual ele está vinculado.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Encaminhada para protesto</td>
              <td>O crédito entra nesta fase quando apontado para o cartório para ser protestado.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Protestado</td>
              <td>O crédito entra nesta fase quando ele não foi pago no apontamento e o cartório devolve a informação de que o protesto foi realizado com êxito.</td>
              <td>I</td>
            </tr>
            <tr>
              <td>Quitado</td>
              <td>O crédito entra nesta fase quando ele foi extinto por pagamento.</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Extinto</td>
              <td>O crédito entra nesta fase quando ele foi extinto.</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Remido</td>
              <td>O crédito entra nesta fase quando ele foi extinto por remição.</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Prescrito</td>
              <td>O crédito entra nesta fase quando ele foi extinto por prescrição.</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Cancelado</td>
              <td>O crédito entra nesta fase quando ele foi extinto por cancelamento.</td>
              <td>A</td>
            </tr>
            <tr>
              <td>Devolvido por suspensão administrativa</td>
              <td>O crédito entra nesta fase quando o órgão de origem solicita a devolução do crédito para análise.</td>
              <td>?</td>
            </tr>
            <tr>
              <td>Exigibilidade suspensa</td>
              <td>O crédito entra nesta fase quando a uma determinação administrativa ou judicial determinando a suspensão da cobrança.</td>
              <td>?</td>
            </tr>
          </tableInscript>
          `,
        },
        16: {
          title: '16. Como é calculado o valor do estoque da dívida ativa?',
          text: `
          O estoque da dívida ativa inclui a soma de todas as fases das CDAs. Ou seja, existe um montante de dívida na fase Protestado, outro em Prescrito, Remido etc. O valor total do estoque será esse somatório. Entenda os valores distribuídos em cada fase:<br />
          <br/>Protestado:
          <listItem>O valor considera todas as CDA’s ativas que estão na fase de “Solicitado para Protesto” ou “Protestado”.</listItem>

          Solicitação ajuizar:
          <listItem>O valor considera todas as CDA’s ativas que estão na fase de “Solicitado para Ajuizamento”.</listItem>

          Prescrito:
          <listItem>O valor considera todas as CDA’s extintas por prescrição que estão na fase de “Extinta por Prescrição”.</listItem>

          Remido:
          <listItem>O valor considera todas as CDA’s extintas por remissão que estão na fase de “Extinta por Remissão”.</listItem>

          Cancelado:
          <listItem>O valor considera todas as CDA’s extintas por cancelamento que estão na fase de “Extinta por Cancelamento”.</listItem>`,
        },
        17: {
          title: '17. O que é e como é gerado o <bold>nº da Chave SEFIN?</bold>',
          text: 'É o número de identificação do crédito na Secretaria Municipal das Finanças (SEFIN). O número é gerado no órgão de origem do crédito. Cada chave refere-se a um crédito. Por exemplo, se o contribuinte João tem um débito de IPTU, esse crédito é identificado pelo nº da chave.',
        },
        18: {
          title: '18. O que é e como é gerado o <bold>nº da Inscrição Administrativa?</bold>',
          text: ' o número do conjunto de créditos de um bem do contribuinte. O número é gerado no órgão de origem do crédito. Cada inscrição refere-se a um bem com um ou mais débitos. Por exemplo, se o contribuinte João tem três imóveis com débitos, logo existe uma inscrição por imóvel e cada uma pode ter um ou mais débitos vinculados.',
        },
        19: {
          title: '19. O que é e como é gerado o <bold>nº da CDA?</bold>',
          text: 'É o número que vincula a dívida à dívida ativa. O número é gerado no sistema ePGM quando a dívida é cadastrada no mesmo. Cada CDA refere-se a uma chave de origem. Por exemplo, se o contribuinte João tem 5 dívidas, isso quer dizer que ele chegou na PGM com 5 chaves e consequentemente terá 5 CDAs cadastradas.',
        },
        20: {
          title: '20. Como <bold>buscar uma dívida?</bold>',
          text: 'A dívida pode ser consultada pelo Portal do Contribuinte ou via sistema ePGM. Por meio do portal clique na aba de serviços e selecione a opção de consultar débitos. Já pelo ePGM será necessário acessar a tela de extrato de débitos. Por ela será possível buscar pela dívida desejada.',
        },
        21: {
          separator: 'Sobre outros assuntos',
          title: '21. Por que tenho meu <bold>acesso bloqueado para ver o dashboard de dívida ativa?</bold>',
          text: 'O acesso ao dashboard da Dívida Ativa é liberado de acordo com o perfil de acesso do usuário. Ele só está disponível para usuários que têm perfil vinculado ao módulo de dívida ativa.',
        },
        22: {
          title: '22. Por que tenho meu <bold>acesso bloqueado para ver o dashboard de processos?</bold>',
          text: 'O acesso ao dashboard de Processo Digitais é liberado de acordo com o perfil de acesso do usuário. Ele só está disponível para usuários que têm perfil vinculado ao módulo de processos digitais.',
        },
      },
    },
  },
};
