import {
  useContext, useEffect, useState,
} from 'react';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { t } from 'i18next';
import { Tooltip } from '@mui/material';
import { InscriptionsByPaymentGroupResponse } from '../../../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import Format from '../../../../../helpers/format';
import { CheckBox } from '../../../../../components/CheckBox';
import { Tags } from '../Tags';
import { PillInfo } from '../../../../../components/PillInfo';
import { HomeContext } from '../../../../../context/homeContext';
import Show from '../../../../../components/Show';
import { DebtsTable } from '../../../../../components/DebtsTable';
import { Link } from '../../../../../components/Link';
import { StatesHistory } from '../../../../../components/StatesHistory';

type InscriptionProps = {
  inscription: InscriptionsByPaymentGroupResponse;
  inPaymentRequest?: boolean;
  openList?: boolean;
  showDeleteButton?: boolean;
  hasRestriction?: boolean;
  onClick?: (open: boolean) => void;
};

export function Inscription(props: InscriptionProps) {
  const { inscriptionsSelected, addInscription, removeInscriptions } = useContext(HomeContext);
  const [open, setOpen] = useState(true);
  const [historyModal, setHistoryModal] = useState(false);
  const state = props.inscription.pills.find((i) => i.key === 'state');

  useEffect(() => {
    setOpen(props.openList || false);
  }, [props.openList]);

  useEffect(() => {
    if (props.onClick) props.onClick(open);
  }, [open]);

  const setPhaseModalState = () => {
    setHistoryModal(!historyModal);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Show if={Boolean(!props.inPaymentRequest)}>
          <CheckBox
            value={inscriptionsSelected.includes(props.inscription)}
            uncheckedColor='var(--neutral6)'
            color='var(--primary4)'
            disabled={props.hasRestriction}
            onClick={() => {
              addInscription(props.inscription);
            }}
            dataCy='checkbox-inscription'
          />
        </Show>
        <a
          className={styles.button}
          onClick={() => setOpen(!open)}
        >
          <div className={styles.firstLine}>
            <div className={styles.number}>
              <Link
                href={`/inscription/${props.inscription.id}`}
                target='_blank'
                onClick={(e) => e.stopPropagation()}
                className={styles.inscriptionNumber}
              >
                {props.inscription.cda_number || t('debtBox.results.inscriptions.emptyNumber')}
              </Link>
              <PillInfo
                id={'debt_state'}
                label={`Fase: ${state?.label || ''}`}
                title={state?.title || ''}
                text={state?.description || ''}
                textLink={t('inscription.stateHistory')}
                onClick={setPhaseModalState}
              />
            </div>
            <div className={styles.totals}>
              {props.inscription.pills.filter((p) => ['billets', 'dams'].includes(p.key)).map((tag) => (
                <PillInfo
                  id={tag.key}
                  text={tag.description}
                  other_description={tag.other_description}
                  title={tag.title}
                  label={tag.label}
                />
              ))}
              <span className={styles.totalBalance}>{Format.currency(props.inscription.balance)}</span>
            </div>
          </div>
          <Tags inscription={props.inscription}/>
        </a>
        <a
          className={styles.arrow}
          onClick={() => setOpen(!open)}
        >
          {open ? <FaChevronUp /> : <FaChevronDown />}
        </a>
        <Show if={!!props.showDeleteButton && inscriptionsSelected.length > 1}>
          <Tooltip title={t('debtBox.newNegotiation.removeCda')}>
            <a
              className={styles.removeButton}
              onClick={() => removeInscriptions([props.inscription])}
            >
              <FaTimes />
            </a>
          </Tooltip>
        </Show>
      </div>
      {open && (
        <DebtsTable
          inscription={props.inscription.id}
        />
      )}
      <StatesHistory
        data={props.inscription.history_inscription_states || []}
        modalState={historyModal}
        openPhasesModal={setPhaseModalState}
      />
    </div>
  );
}
