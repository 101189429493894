import { ErrorField } from '../components/ErrorField';

export default class FormHelper {
  public static renderErrorField = (field: string, errors: any) => {
    if (errors) {
      if (errors[field]) {
        const errorDescription = errors[field][0];
        return <ErrorField text={errorDescription}/>;
      }
    }
    return false;
  };
}
