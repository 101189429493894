import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Skeleton } from '@mui/material';
import { Contributor } from '../../../@types/activeDebt/debtBox';
import styles from './styles.module.scss';
import { maskCpfOrCnpj } from '../../../helpers/masks';

type ContributorsTabsType = {
  contributors: Contributor[];
  onChange: (id: number) => void;
  loading?: boolean;
};

export function ContributorsTabs(props: ContributorsTabsType) {
  const [selected, setSelected] = useState<number>(props.contributors[0]?.id);

  useEffect(() => {
    if (props.contributors[0]) {
      setSelected(props.contributors[0].id);
      if (props.onChange) {
        props.onChange(props.contributors[0].id);
      }
    }
  }, [props.contributors]);

  const formatAddress = (contributor: Contributor) => {
    const address = [contributor.street, contributor.street_number, contributor.address_complement, contributor.neighborhood, contributor.city];
    return address.filter((item) => item !== null && item !== undefined && item !== '').join(', ');
  };

  const handleClick = (id: number) => {
    setSelected(id);
    if (props.onChange) {
      props.onChange(id);
    }
  };

  const openContributorDetails = (data: Contributor) => {
    const contributor = {
      value: data.id.toString(),
      label: data.name,
    };

    const params = new URLSearchParams(contributor).toString();
    const url = `/contributor_box?${params}`;

    window.open(url, '_blank');
  };

  if (props.loading) {
    return (
      <Skeleton height={120}/>
    );
  }

  return (
    <div className={ styles.container }>
      {
        props.contributors.map((contributor) => (
          <div className={`${styles.content} ${selected === contributor.id && styles.selected}`}>
            <a
              onClick={() => handleClick(contributor.id)}
              className={styles.button}
            >
              <h3
                className={styles.name}
                onClick={() => openContributorDetails(contributor)}
              >
                {contributor.name} - {maskCpfOrCnpj(contributor.document_contributor).mask()}
              </h3>
              <span className={styles.address}>
                <b>{t('debtBox.results.address')}</b>
                {formatAddress(contributor)}
              </span>
            </a>
          </div>
        ))
      }
    </div>
  );
}
