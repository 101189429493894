import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import styles from './styles.module.scss';
import { InscriptionsByPaymentGroupResponse, PaymentGroupType } from '../../../../@types/activeDebt/debtBox';
import DebtBoxService from '../../../../services/debtBoxService';
import { Inscription } from './Inscription';
import Show from '../../../../components/Show';
import { Link } from '../../../../components/Link';
import { CheckBox } from '../../../../components/CheckBox';
import { HomeContext } from '../../../../context/homeContext';
import { DropdownButton, ItemType } from '../../../../components/DropdownButton';
import Format from '../../../../helpers/format';
import { CurrentUser } from '../../../../@types/currentUser';
import {
  displayApproveRequirement, displayInscriptCda, displayExtract, displayBillet,
  displayDownloadCda, displayPaymentRequest, displayPaymentRequestBillet,
  displayCancelPaymentRequest, hasMultiplePaymentGroup, checkInscriptionsGroup,
  canGeneratePaymentRequestBillet, hasPaymentGroupInscription,
  checkGlobalRestriction,
} from '../../../../helpers/inscriptionActions';
import { downloadFile } from '../../../../helpers/downloadPDF';
import InscriptionService from '../../../../services/inscriptionService';
import { ModalToCancel } from './ModalToCancel';
import { ToastProps } from '../../../../@types/config';
import { ChangeInscriptionsState } from './ChangeInscriptionsState';

type PaymentGroupProps = {
  group: PaymentGroupType;
  onNegotiation?: () => void;
  contributorId: number | undefined;
  receiptTypeId: number | undefined;
  onRefetch?: () => void;
  count?: number;
};

export function PaymentGroup(props: PaymentGroupProps) {
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const [open, setOpen] = useState(false);
  const [openAllList, setOpenAllList] = useState(true);
  const [openCancelNegotiationModal, setOpenCancelNegotiationModal] = useState(false);
  const [inscriptionsOpen, setInscriptionsOpen] = useState<number[]>([]);
  const {
    inscriptionsSelected, addInscriptions, removeInscriptions,
    setAlert, addToasts, removeAllInscriptions, setModal,
  } = useContext(HomeContext);
  const inscriptionsSelectedIds = inscriptionsSelected.map((i) => i.id);
  const scrollItem = useRef<HTMLDivElement>(null);
  const selectedInscriptions = inscriptionsSelected.map((item: { id: number; }) => item.id);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');
  const requerimentAlert = 'paymentRequest.unpaidBillets.requeriment.alert';
  const inscriptDebtstAlert = 'paymentRequest.unpaidBillets.inscriptDebt.alert';

  const requirementCodes = [
    'debts_in_approved_requirement',
    'debts_in_rejected_requirement',
    'debts_in_requested_requirement',
  ];

  const invalidCodes = [
    'debts_with_restriction',
  ];

  const loadData = async ({ pageParam }: { pageParam: number }) => {
    if (props.group.inscriptions_ids) {
      const result = await DebtBoxService.getInscriptionsByPaymentGroup(props.group.inscriptions_ids, pageParam);
      const updatedResult = result.map((item: InscriptionsByPaymentGroupResponse) => ({
        ...item,
        payment_group_code: props.group.payment_group_code,
      }));
      return updatedResult;
    }
    return null;
  };

  const {
    data, isRefetching, isLoading, fetchNextPage, refetch, hasNextPage, isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [`paymentGroup-${props.group.payment_group_code}}-${props.group.payment_request_id}-${props.count}`],
    queryFn: loadData,
    enabled: false,
    initialPageParam: 1,
    getNextPageParam: (_, allPages) => {
      return allPages.flat().length < props.group.total_inscriptions ? allPages.length + 1 : undefined;
    },
  });

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const controllAlertsAndToasts = (result: boolean, successMessage?: string, errorMessage?: string) => {
    if (result && successMessage) {
      setAlert(undefined);
      addToasts({
        type: 'success',
        text: t(successMessage),
        widthFull: true,
      });
      if (props.onRefetch && data && data.pages.flat()) {
        props.onRefetch();
        removeInscriptions(data.pages.flat());
      }
    } else if (errorMessage) {
      setAlert(undefined);
      addToasts({
        type: 'error',
        text: t(errorMessage),
        widthFull: true,
      });
    }
  };

  const handleTotalBalance = () => {
    if (checkInscriptionsGroup(inscriptionsSelected, props.group)) {
      const ids = props.group.inscriptions_ids;
      const filtredInscriptions = inscriptionsSelected.filter((inscription) => ids.includes(inscription.id));

      const totalBalance = filtredInscriptions.reduce(
        (sum, inscription) => sum + parseFloat(inscription.balance.toString()),
        0,
      );

      return (
        <span className={styles.totalValue}>
          {t('debtBox.results.groups.totalSelected')}
          <span className={`${styles.value} ${styles.selected}`}>
            {Format.currency(totalBalance)}
          </span>
        </span>
      );
    }

    return (
      <span className={styles.totalValue}>
        {t('debtBox.results.groups.totalBalance')}
        <span className={styles.value}>
          {Format.currency(props.group.total_balance)}
        </span>
      </span>
    );
  };

  useEffect(() => {
    const pages = data?.pages.flat() || [];

    if (open && pages.length < 1) {
      refetch();
    }
  }, [open, isLoading, isRefetching]);

  useEffect(() => {
    if (open) refetch();
  }, [props.group.inscriptions_ids]);

  const inscriptionsSelectedByPaymentGroup = () => {
    if (data && data.pages.flat()) {
      const inscriptionsToSelect = data.pages.flat().filter((i) => !i.block_payment);
      const count = inscriptionsToSelect.filter((i: InscriptionsByPaymentGroupResponse) => inscriptionsSelected.includes(i)).length;
      if (count === 0) {
        return 'none';
      }
      if (count === inscriptionsToSelect.length) {
        return 'all';
      }
      if (count < inscriptionsToSelect.length) {
        return 'partial';
      }
    }
    return '';
  };

  const fetchAproveRequeriment = async (id: number[]) => {
    setAlert(undefined);
    const successInfo = 'paymentRequest.unpaidBillets.requeriment.toast';
    const errorInfo = 'paymentRequest.unpaidBillets.requeriment.toastError';
    await DebtBoxService.aproveRequeriment(id)
      .then((response) => {
        controllAlertsAndToasts(response.result.success, successInfo);
      })
      .catch((error) => {
        controllAlertsAndToasts(error.response.data.result.success, errorInfo);
      });
  };

  const fetchInscriptDebt = async (id: number[]) => {
    setAlert(undefined);
    const successInfo = 'paymentRequest.unpaidBillets.inscriptDebt.toast';
    const errorInfo = 'paymentRequest.unpaidBillets.inscriptDebt.toastError';
    await DebtBoxService.inscriptDebts(id)
      .then((response) => {
        controllAlertsAndToasts(response === 201, successInfo);
      })
      .catch(() => {
        controllAlertsAndToasts(false, errorInfo);
      });
  };

  const setAlerts = (handleConfirmation: () => Promise<void>, alertTitle: string, alertSubTitle: string, agreement: boolean, cyId: string) => {
    const totalBalance = inscriptionsSelected.reduce((sum: number, item: { balance: any; }) => sum + parseFloat(`${item.balance}`), 0);
    setAlert({
      visible: true,
      handleConfirm: () => (handleConfirmation()),
      title: (
        <Trans
          i18nKey={alertTitle}
          components={{ bold: <strong />, br: <br /> }}
        />
      ),
      text: (
        <Trans
          data-cy={`data-cy-${cyId}-alert`}
          className={styles.balance}
          i18nKey={alertSubTitle}
          components={{ bold: <strong />, br: <br /> }}
          values={{
            inscriptionsLength: inscriptionsSelected.length,
            inscriptionGroup: props.group.name,
            total: Format.currency(totalBalance),
          }}
        />
      ),
      ...(agreement && { agreementText: t('paymentRequest.unpaidBillets.inscriptDebt.agreeText') }),
    });
  };

  const downloadPaymentRequestSimulation = async (inscriptions_ids: number[]) => {
    if (inscriptionsSelected.length !== 0 && props.contributorId) {
      const { contributorId } = props;
      const receiptTypeId = inscriptionsSelected ? inscriptionsSelected[0].receipt_type_id : 0;
      const url = `/reports/payment_requests/simulate/${contributorId}/${receiptTypeId}/${inscriptions_ids}`;
      window.open(url, '_blank');
    }
  };

  const handleSelectAllInscriptions = () => {
    if (data && data.pages.flat()) {
      if (inscriptionsSelectedByPaymentGroup() === 'all') {
        removeInscriptions(data.pages.flat());
      } else {
        const inscriptions = data.pages.flat().filter((i) => !i.block_payment);
        addInscriptions(inscriptions);
      }
    }
  };

  const handleOnNegocioation = () => {
    if (props.onNegotiation) {
      props.onNegotiation();
    }
  };

  const handleExtract = async () => {
    if (props.contributorId && inscriptionsSelected.length > 0) {
      let searchObject = search ? JSON.parse(decodeURIComponent(search)) : {};
      const params = {
        inscriptionIds: inscriptionsSelected.map((item) => item.id),
      };
      searchObject = { ...searchObject, ...params };
      window.open(`/reports/custom/${props.contributorId}?search=${encodeURIComponent(JSON.stringify(searchObject))}`, '_blank');
    }
  };

  const downloadCDA = async () => {
    if (selectedInscriptions.length === 1) {
      const res = await InscriptionService.generateCDA(selectedInscriptions[0].toString());
      downloadFile(res, 'certidao_de_divida_ativa.pdf');
    }
  };

  const handleCancelPaymentRequest = () => {
    setOpenCancelNegotiationModal(true);
  };

  const handleInscriptionStatedChanged = (error?: boolean) => {
    setModal(undefined);
    if (props.onRefetch) props.onRefetch();
    if (error) {
      addToasts({
        type: 'error',
        text: t('debtBox.results.actions.changeState.error'),
      });
    } else {
      addToasts({
        type: 'success',
        text: t('debtBox.results.actions.changeState.success'),
      });
    }
  };

  const handleChangeState = () => {
    setModal({
      open: true,
      visible: true,
      children: (
        <ChangeInscriptionsState
          onSuccess={() => handleInscriptionStatedChanged()}
          onFailed={() => handleInscriptionStatedChanged(true)}
        />
      ),
      title: t('debtBox.results.actions.changeState.title'),
      handleClose: () => setModal(undefined),
    });
  };

  const handleInCashBillet = async () => {
    if (props.contributorId && props.receiptTypeId && inscriptionsSelected.length > 0) {
      try {
        const ids = inscriptionsSelected.map((inscription) => inscription.id);
        const res = await DebtBoxService.generateInCashBillet(props.contributorId, props.receiptTypeId, ids);
        downloadFile(res, 'boleto.pdf');
        addToasts({
          type: 'success',
          text: t('debtBox.results.actions.billet.success'),
          widthFull: true,
        });
        removeAllInscriptions();
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.exception) {
          addToasts({
            type: 'error',
            text: t('debtBox.results.actions.billet.error', { value: error.response.data.exception }),
          });
        } else {
          addToasts({
            type: 'error',
            text: t('debtBox.results.actions.billet.error', { value: '' }),
          });
        }
      }
    }
  };

  const handleActionItems = (): ItemType[] => {
    const code = props.group.payment_group_code;
    return [
      {
        label: t('debtBox.results.actions.approveRequirement'),
        onClick: () => setAlerts(() => fetchAproveRequeriment(selectedInscriptions), t(`${requerimentAlert}.title`), t(`${requerimentAlert}.subtitle`), false, 'requeriment'),
        display: displayApproveRequirement(code) && Boolean(currentUser.permission_approve_requirements),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.inscript'),
        onClick: () => setAlerts(() => fetchInscriptDebt(selectedInscriptions), t(`${inscriptDebtstAlert}.title`), t(`${inscriptDebtstAlert}.subtitle`), true, 'inscript-debts'),
        display: displayInscriptCda(code) && Boolean(currentUser.permission_approve_requirements),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.extract.label'),
        onClick: () => handleExtract(),
        display: displayExtract(code),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.billet.label'),
        onClick: () => handleInCashBillet(),
        display: displayBillet(code),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.downloadCDA'),
        onClick: () => downloadCDA(),
        display: displayDownloadCda(code),
        enabled: selectedInscriptions.length === 1,
      },
      {
        label: t('debtBox.results.actions.paymentRequestSimulation'),
        onClick: () => downloadPaymentRequestSimulation(inscriptionsSelectedIds),
        display: displayPaymentRequest(code),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.paymentRequest'),
        onClick: () => handleOnNegocioation(),
        display: displayPaymentRequest(code),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.paymentRequestBillet'),
        onClick: (e: any) => {},
        display: displayPaymentRequestBillet(code),
        enabled: canGeneratePaymentRequestBillet(inscriptionsSelected, props.group.inscriptions_ids),
      },
      {
        label: t('debtBox.results.actions.cancelPaymentRequest'),
        onClick: () => handleCancelPaymentRequest(),
        display: displayCancelPaymentRequest(code) && Boolean(props.group.can_cancel_agreement),
        enabled: canGeneratePaymentRequestBillet(inscriptionsSelected, props.group.inscriptions_ids),
      },
      {
        label: t('debtBox.results.actions.changeState.title'),
        onClick: () => handleChangeState(),
        display: Boolean(currentUser.can_update_states),
        enabled: Boolean(currentUser.can_update_states),
      },
    ];
  };

  const handleShowPaymentRequest = (id: number) => {
    window.open(`/payment_request/${id}`, '_blank');
  };

  const handleNegotiationCanceled = (success: boolean) => {
    let toast: ToastProps = { type: 'success', text: t('debtBox.cancelNegotiation.success') };
    if (!success) toast = { type: 'error', text: t('debtBox.cancelNegotiation.fail') };
    addToasts(toast);
    setOpenCancelNegotiationModal(false);
    removeAllInscriptions();
    if (props.onRefetch) props.onRefetch();
  };

  useEffect(() => {
    setInscriptionsOpen(openAllList ? props.group.inscriptions_ids : []);
  }, [openAllList]);

  const handleOpenInscription = (id: number, inscriptionOpen: boolean) => {
    const list = inscriptionOpen ? [...inscriptionsOpen, id] : inscriptionsOpen.filter((i) => i !== id);
    setInscriptionsOpen(list);
    if (list.length === props.group.inscriptions_ids.length) setOpenAllList(true);
    if (list.length === 0) setOpenAllList(false);
  };

  return (
    <div className={styles.container} ref={scrollItem}>
      <a
        onClick={() => setOpen(!open)}
        className={styles.button}
        data-cy="payment-group-selector"
      >
        <span>
          {props.group.payment_request_id ? (
            <span className={styles.paymentGroupName}>
              {t('debtBox.newNegotiation.negotiation.number')}
              <Link
                className={styles.paymentGroupName}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowPaymentRequest(props.group.payment_request_id);
                }}
              >
                {props.group.payment_request_code}
              </Link>
            </span>
          ) : (
            <span className={styles.paymentGroupName}>{props.group.name}</span>
          )}
          <span className={styles.paymentGroupCount}> ({props.group.total_inscriptions})</span>
        </span>
        <div className={styles.actions}>
          <span className={styles.paymentGroupTotal}>{handleTotalBalance()}</span>
          {open ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </a>
      {open && (
        <div className={styles.content}>
          {
            (isRefetching || isLoading) && (
              <Skeleton variant='rectangular' height={100}/>
            )
          }

          {
            (data && (!isLoading && !isRefetching)) && (
              <div>
                <div className={styles.selectActions}>
                  <Show if={!hasNextPage}>
                    <Show if={!checkGlobalRestriction(
                      props.group.payment_group_code,
                      props.group.payment_request_code,
                      inscriptionsSelected,
                      Boolean(currentUser.permission_approve_requirements),
                    )}>
                      <Link
                        onClick={() => handleSelectAllInscriptions()}
                      >
                        <CheckBox
                          partially={inscriptionsSelectedByPaymentGroup() === 'partial'}
                          value={['partial', 'all'].includes(inscriptionsSelectedByPaymentGroup())}
                          color='var(--primary4)'
                        />
                        {t(`debtBox.results.inscriptions.${inscriptionsSelectedByPaymentGroup() === 'all' ? 'removeAll' : 'selectAll'}`)}
                      </Link>
                    </Show>
                    <Link onClick={() => setOpenAllList(!openAllList)}>
                      {t(`debtBox.results.inscriptions.${openAllList ? 'closeList' : 'openList'}`)}
                    </Link>
                  </Show>
                  <DropdownButton
                    title={t('debtBox.results.actions.label')}
                    items={handleActionItems()}
                    enabled={
                      hasPaymentGroupInscription(inscriptionsSelected, props.group.inscriptions_ids)
                      && !hasMultiplePaymentGroup(inscriptionsSelected)
                    }
                  />
                </div>
                <div data-cy='cypress-debt-list' id={'debtList'} className={styles.inscriptionList}>
                  {data.pages.flat().map((inscription: InscriptionsByPaymentGroupResponse) => (
                    <Inscription
                      inscription={inscription}
                      openList={openAllList}
                      onClick={(inscriptionOpen) => handleOpenInscription(inscription.id, inscriptionOpen)}
                      hasRestriction={(requirementCodes.includes(inscription.payment_group_code)
                        && !currentUser.permission_approve_requirements)
                        || inscription.block_payment || invalidCodes.includes(inscription.payment_group_code)
                      }
                    />
                  ))}
                </div>
              </div>
            )
          }
        </div>
      )}
      <ModalToCancel
        open={openCancelNegotiationModal}
        onClose={() => setOpenCancelNegotiationModal(false)}
        id={props.group.payment_request_id}
        code={props.group.payment_request_code}
        onSuccess={() => handleNegotiationCanceled(true)}
        onFail={() => handleNegotiationCanceled(false)}
      />
    </div>
  );
}
