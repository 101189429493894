import { t } from 'i18next';
import { TableData, TableItem } from '../../../../components/TableData';
import { SharedResponsibles as SharedResponsiblesType } from '../../../../@types/activeDebt/inscription';
import styles from './styles.module.scss';

type SharedResponsiblesProps = {
  data: SharedResponsiblesType[];
};

export function SharedResponsibles(props: SharedResponsiblesProps) {
  const convertToTableData = (data: SharedResponsiblesType[]): TableItem[] => {
    const items: TableItem[] = [
      {
        label: '',
        value: <span className={styles.header}>{t('inscription.data.sharedResponsibles.title')}</span>,
      },
    ];

    if (data.length === 0) {
      items.push({ label: '', value: t('inscription.data.sharedResponsibles.noInfo') });
      return items;
    }

    data.forEach((sharedResponsible: SharedResponsiblesType, index: number) => {
      const label = '';
      const value = (
        <span className={styles.item}>
          {sharedResponsible.name} - {sharedResponsible.document_information}
        </span>
      );
      items.push({ label, value });
    });

    return items;
  };

  return (
    <div className={styles.container}>
      <TableData
        items={convertToTableData(props.data)}
        singleColumnTable
      />
    </div>
  );
}
