import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InputText } from '../../../../components/InputText';
import { Subtitle } from '../../../../components/Subtitle';
import Show from '../../../../components/Show';
import Select from '../../../../components/Select';
import { InputDate } from '../../../../components/InputData';
import { Errors, OptionType } from '../../../../@types/config';
import FormHelper from '../../../../helpers/form';
import ContributorService from '../../../../services/contributorService';

export type BasicDataType = {
  contributor_name: string;
  cpf: string;
  cnpj: string;
  legal_type: OptionType;
  profession?: string;
  marital_status?: OptionType;
  educational_stage?: OptionType;
  gender: OptionType;
  birth_date?: string;
  death_date?: string;
};

type BasicDataProps = {
  errors: Errors | undefined;
  data?: BasicDataType;
};

export function BasicData(props: BasicDataProps) {
  const { control, watch, setValue } = useFormContext();
  const legalType = watch('legal_type', 'individual');

  const loadData = async () => {
    const result = await ContributorService.getNewContributorParams();
    return result;
  };

  const {
    data,
  } = useQuery({
    queryKey: ['newContributorParams'],
    queryFn: loadData,
  });

  const handleValidValues = (value: string | undefined) => {
    return props.data?.legal_type.value === 'individual' ? value : null;
  };

  useEffect(() => {
    if (props.data) {
      setValue('contributor_name', props.data.contributor_name);
      setValue('legal_type', props.data.legal_type.value);
      setValue('cpf', props.data.cpf);
      setValue('cnpj', props.data.cnpj);
      setValue('profession', props.data.profession);
      setValue('marital_status', handleValidValues(props.data.marital_status?.value));
      setValue('educational_stage', handleValidValues(props.data.educational_stage?.value));
      setValue('gender', handleValidValues(props.data.gender?.value));
      setValue('birth_date', handleValidValues(props.data.birth_date));
      setValue('death_date', handleValidValues(props.data.death_date));
    } else {
      setValue('legal_type', 'individual');
    }
  }, []);

  return (
    <>
      <Grid
        item
        xs={4}
        sm={12}
        md={12}
      >
        <Subtitle text={t('contributorBox.form.basic.label')} />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={8}
      >
        <Controller
          control={control}
          name="contributor_name"
          render={({ field }) => (
            <InputText
              {...field}
              placeholder={t('contributorBox.form.basic.namePlaceholder')}
              label={t('contributorBox.form.basic.name')}
              dataCy={'cypress-contributor-name-input-text'}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Show if={data?.data.legal_type !== undefined}>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name={'legal_type'}
            render={({ field: { onChange, value } }) => (
              <Select
                options={data?.data.legal_type || []}
                label={t('contributorBox.form.basic.legalType.label')}
                onChange={(selectedOption) => onChange(selectedOption?.value ?? '')}
                value={value ? data?.data.legal_type.find((option) => option.value === value) : data?.data.legal_type[0]}
                defaultValue={props.data?.legal_type || data?.data.legal_type[0]}
                dataCy={'cypress-contributor-legal-type-select'}
                labelBold
                required
                disableClearable
              />
            )}
          />
        </Grid>
      </Show>
      <Show if={legalType === 'individual'}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
        >
          <Controller
            control={control}
            name="cpf"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('contributorBox.form.basic.cpf')}
                mask={'999.999.999-99'}
                placeholder='000.000.000-00'
                dataCy={'cypress-contributor-cpf-input-text'}
                onInvalid={(e) => (
                  (e.target as HTMLInputElement).setCustomValidity(t('contributorBox.form.basic.required'))
                )}
                onInput={(e) => (
                  (e.target as HTMLInputElement).setCustomValidity('')
                )}
                labelBold
                required
              />
            )}
          />
          {FormHelper.renderErrorField('cpf', props.errors)}
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
        >
          <Controller
            control={control}
            name="profession"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('contributorBox.form.basic.profession')}
                dataCy={'cypress-contributor-profession-input-text'}
                labelBold
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name={'marital_status'}
            render={({ field: { onChange, value } }) => (
              <Select
                options={data?.data.marital_status || []}
                label={t('contributorBox.form.basic.maritalStatus')}
                onChange={(selectedOption) => onChange(selectedOption?.value ?? '')}
                value={data?.data.marital_status.find((option) => option.value === value)}
                defaultValue={props.data?.marital_status}
                dataCy={'cypress-contributor-marital-status-select'}
                labelBold
                disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name={'educational_stage'}
            render={({ field: { onChange, value } }) => (
              <Select
                options={data?.data.educational_stage || []}
                label={t('contributorBox.form.basic.educationalStage')}
                onChange={(selectedOption) => onChange(selectedOption?.value ?? '')}
                value={data?.data.educational_stage.find((option) => option.value === value)}
                defaultValue={props.data?.educational_stage}
                dataCy={'cypress-contributor-educational-stage-select'}
                labelBold
                disableClearable
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            control={control}
            name={'gender'}
            render={({ field: { onChange, value } }) => (
              <Select
                options={data?.data.gender || []}
                label={t('contributorBox.form.basic.gender')}
                onChange={(selectedOption) => onChange(selectedOption?.value ?? '')}
                value={data?.data.gender.find((option) => option.value === value)}
                defaultValue={props.data?.gender}
                dataCy={'cypress-contributor-gender-select'}
                labelBold
                disableClearable
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
        >
          <Controller
            control={control}
            name="birth_date"
            render={({ field }) => (
              <InputDate
                {...field}
                dataCy={'cypress-inputdate-birth-date'}
                label={t('contributorBox.form.basic.birthDate')}
                placeholder={t('contributorBox.form.address.datePlaceholder')}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
        >
          <Controller
            control={control}
            name="death_date"
            render={({ field }) => (
              <InputDate
                {...field}
                dataCy={'cypress-inputdate-death-date'}
                label={t('contributorBox.form.basic.deathDate')}
                placeholder={t('contributorBox.form.address.datePlaceholder')}
              />
            )}
          />
        </Grid>
      </Show>
      <Show if={legalType !== 'individual'}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
        >
          <Controller
            control={control}
            name="cnpj"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('contributorBox.form.basic.cnpj')}
                mask={'99.999.999/9999-99'}
                placeholder='00.000.000/0000-00'
                dataCy={'cypress-contributor-cnpj-input-text'}
                onInvalid={(e) => (
                  (e.target as HTMLInputElement).setCustomValidity(t('contributorBox.form.basic.required'))
                )}
                onInput={(e) => (
                  (e.target as HTMLInputElement).setCustomValidity('')
                )}
                labelBold
                required
              />
            )}
          />
          {FormHelper.renderErrorField('cnpj', props.errors)}
        </Grid>
      </Show>
    </>
  );
}
