import { t } from 'i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { BasicData, BasicDataType } from '../../Form/BasicData';
import { Button } from '../../../../components/Button';
import { Contributor } from '../../../../@types/contributor';
import { HomeContext } from '../../../../context/homeContext';
import ContributorService from '../../../../services/contributorService';
import styles from './styles.module.scss';

interface FormValues {
  basicData: any;
}

type EditContributorFormProps = {
  contributor: Contributor;
  closeModal: () => void;
  refetch: () => void;
};

export function EditContributorForm(props: EditContributorFormProps) {
  const { addToasts } = useContext(HomeContext);
  const [errors, setErrors] = useState();
  const methods = useForm<FormValues>();

  const contributorData: BasicDataType = {
    contributor_name: props.contributor.name,
    cpf: props.contributor.legal_type.value === 'individual' ? props.contributor.cpf_cnpj : '',
    cnpj: props.contributor.legal_type.value === 'individual' ? '' : props.contributor.cpf_cnpj,
    legal_type: props.contributor.legal_type,
    profession: props.contributor.profession,
    marital_status: props.contributor.marital_status,
    educational_stage: props.contributor.educational_stage,
    gender: props.contributor.gender,
    birth_date: props.contributor.birth_date,
    death_date: props.contributor.death_date,
  };

  const handleValidValues = (legalType: string, value: string) => {
    return legalType === 'individual' ? value : null;
  };

  const submit = async (form: any) => {
    const formData = {
      name: form.contributor_name,
      cpf_cnpj: form.legal_type === 'individual' ? form.cpf : form.cnpj,
      legal_type: form.legal_type,
      profession: handleValidValues(form.legal_type, form.profession),
      marital_status: handleValidValues(form.legal_type, form.marital_status),
      educational_stage: handleValidValues(form.legal_type, form.educational_stage),
      gender: handleValidValues(form.legal_type, form.gender),
      birth_date: handleValidValues(form.legal_type, form.birth_date),
      death_date: handleValidValues(form.legal_type, form.death_date),
    };

    try {
      const res = await ContributorService.updateBasicData(props.contributor.id, formData);
      if (res.status === 200) {
        addToasts({
          type: 'success',
          text: t('contributorBox.form.basic.success'),
          widthFull: true,
        });
        props.refetch();
        props.closeModal();
      }
    } catch (error) {
      setErrors(undefined);
      addToasts({
        type: 'error',
        text: t('contributorBox.form.basic.error'),
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={methods.handleSubmit(submit)}>
        <Grid
          container
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={{ xs: 2, md: 2 }}
        >
          <BasicData
            errors={errors}
            data={contributorData}
          />
          <Grid className={styles.footer} item xs={4} sm={12} md={12}>
            <Button
              dataCy={'cypress-edit-contributot-button'}
              title={t('contributorBox.form.basic.update')}
              buttonType='primary'
              round
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
