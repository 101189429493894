import styles from './styles.module.scss';

import iconAlert from '../../../assets/icon-alert.svg';

type ReportAlertProps = {
  description: string;
  show: boolean;
};

export function Alert(props: ReportAlertProps) {
  return props.show ? (
    <div className={styles.msgAlert}>
      <img src={iconAlert} alt="Alert Icon" />
      <p>{props.description}</p>
    </div>
  ) : (
    <></>
  );
}
