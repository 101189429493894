import {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';
import { ThemeMode } from '../@types/config';
import { ThemeContextType } from '../@types/themeContext';

const contextDefaultValues: ThemeContextType = {
  themeMode: 'light',
  setThemeMode: () => {},
};

export const ThemeContext = createContext<ThemeContextType>(
  contextDefaultValues,
);

const ThemeProvider: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    localStorage.getItem('themeMode') === 'dark' ? 'dark' : 'light',
  );

  useEffect(() => {
    if (themeMode === 'light') document.body.classList.remove('darkModeTheme');
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);
  return (
    <ThemeContext.Provider
      value={{
        themeMode,
        setThemeMode,
      }}>
        {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
