import { InputHTMLAttributes, useState } from 'react';
import { FaRegQuestionCircle, FaEye } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';
import styles from './styles.module.scss';
import Show from '../Show';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelBold?: boolean;
  tooltip?: string;
  classNameContainer?: string;
  mask?: string;
  currency?: boolean;
  readOnly?: boolean;
  type?: 'password' | 'text' | 'number';
  disabled?: boolean,
  id?: string;
  dataCy?: string;
}

export function InputText(props: InputTextProps) {
  const [inputType, setInputType] = useState<'password' | 'text' | 'number'>(props.type ? props.type : 'text');

  const changeType = (type: string) => {
    if (type === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div className={ `${styles.container} ${props.classNameContainer}` }>
      <Show if={!!props.label || !!props.tooltip}>
        <div className={ `${styles.header}` }>
          <Label
            text={props.label || ''} required={props.required}
            className={props.labelBold ? styles.labelBold : ''}
          />
          {props.tooltip
            ? <Tooltip title={ props.tooltip }><FaRegQuestionCircle /></Tooltip>
            : null
          }
        </div>
      </Show>
      {
        props.currency ? (
          <NumericFormat
            {...props}
            type={'text'}
            value={props.value ? props.value.toString() : ''}
            defaultValue={props.defaultValue ? props.defaultValue.toString() : ''}
            fixedDecimalScale
            thousandSeparator={'.'}
            decimalSeparator={','}
            data-cy={props.dataCy}
            decimalScale={2}
            allowNegative={false}
            prefix={'R$ '}
            className={`${styles.input} ${props.className}`}
            data-testid={`cypress-input-${props.id}`}
          />
        ) : (
          <div className={`${props.type === 'password' ? styles.inputContainer : ''}`}>
            <InputMask
              {...props}
              mask={props.mask ? props.mask : ''}
              className={`${styles.input} ${props.className} ${props.readOnly ? styles.readOnly : ''}`}
              type={inputType}
              data-cy={props.dataCy}
              disabled={props.disabled}
              data-testid={`cypress-input-${props.id}`}
            />
            {props.type === 'password' && <FaEye onClick={() => changeType(inputType)} className={styles.iconEye}/>}
          </div>
        )
      }
    </div>
  );
}
