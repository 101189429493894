import { t } from 'i18next';

import styles from './styles.module.scss';

import logo from '../../../assets/logo-prefeitura.png';
import icon from '../../../assets/icon-zap.svg';

export function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.txt}>
          <span>{t('reports.footer.site')}</span>
          <p> {t('reports.footer.address')}</p>
          <p className={styles.zap}>
            <img src={icon} alt="" />
            {t('reports.footer.phone')}
          </p>
        </div>
        <div>
          <img src={logo} alt="" />
        </div>
      </div>
    </footer>
  );
}
