import { useLocation, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import styles from './styles.module.scss';
import { Breadcumb } from '../../components/Breadcumb';
import InscriptionService from '../../services/inscriptionService';
import { downloadFile } from '../../helpers/downloadPDF';
import { PillInfo } from '../../components/PillInfo';
import { Tabs } from '../../components/Tabs';
import { Data } from './Data';
import { Inscription, Pill } from '../../@types/activeDebt/inscription';
import { DebtsTable } from '../../components/DebtsTable';
import { Timeline } from './Timeline';
import { LinkedProcedures } from './LinkedProcedures';
import Show from '../../components/Show';
import { Documents } from './Documents';
import { Title } from '../../components/Title';
import { ProtestInfo } from '../../components/ProtestInfos';
import { optionalPropsController } from '../../helpers/optionalPropsController';
import { DropdownButton, ItemType } from '../../components/DropdownButton';
import { displayDownloadCda, displayExtract } from '../../helpers/inscriptionActions';

export function InscriptionDetails() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [linkedProceduresCount, setLinkedProceduresCount] = useState(0);
  const [protestModalState, setProtestModalState] = useState(false);

  const loadData = async () => {
    if (id) {
      const result = await InscriptionService.getInscriptionDetails(id);
      const procedures = await InscriptionService.getInscriptionProcedures(parseInt(id, 10));
      setLinkedProceduresCount(procedures.length);
      return result;
    }
    return null;
  };

  const {
    data, isLoading, refetch,
  } = useQuery({
    queryKey: ['inscriptionDetails'],
    queryFn: loadData,
  });

  const refetchData = () => {
    refetch();
  };

  const downloadCDA = async () => {
    if (id) {
      const res = await InscriptionService.generateCDA(id);
      downloadFile(res, 'certidao_de_divida_ativa.pdf');
    }
  };

  const handleExtract = async () => {
    if (data) {
      const params = {
        inscriptionIds: [data.id],
      };
      const searchObject = { ...params };
      window.open(`/reports/custom/${data.contributor_id}?search=${encodeURIComponent(JSON.stringify(searchObject))}`, '_blank');
    }
  };

  const parseTotalProcedures = () => {
    if (linkedProceduresCount && linkedProceduresCount > 0) {
      return `${t('inscription.linkedProcedures.title')} (${linkedProceduresCount})`;
    }
    return t('inscription.linkedProcedures.title');
  };

  const setPhaseModalState = () => {
    setOpen(!open);
  };

  const getStateInfo = (state: string, pills: Pill[], getTitle: boolean) => {
    const pillProperties = pills.find((pill) => pill.label === state);
    if (getTitle) {
      return pillProperties?.title || '';
    }
    return pillProperties?.description || '';
  };

  const renderTabs = (dataRender: Inscription) => {
    const tabs = [
      {
        id: 1,
        title: t('inscription.data.title'),
        content: (
          <Data
            data={dataRender}
            refetchData={refetchData}
            modalState={open}
            openPhasesModal={setPhaseModalState}
            />
        ),
      },
      {
        id: 2,
        title: t('inscription.debts.title'),
        content: <DebtsTable inscription={dataRender.id} />,
      },
      {
        id: 3,
        title: t('inscription.documents.title'),
        content: <Documents inscriptionId={dataRender.id} />,
      },
      {
        id: 4,
        title: parseTotalProcedures(),
        content: (
          <LinkedProcedures
            inscriptionId={dataRender.id}
            onChange={(count) => setLinkedProceduresCount(count)}
          />
        ),
      },
    ];
    return <Tabs tabs={tabs} />;
  };

  const protestInfoModal = (e?: any) => {
    setProtestModalState(!protestModalState);
    if (e) {
      e.stopPropagation();
    }
  };

  const handleActionItems = (): ItemType[] => {
    const code = data?.payment_group_code || '';
    return [
      {
        label: t('debtBox.results.actions.extract.label'),
        onClick: () => handleExtract(),
        display: displayExtract(code),
        enabled: true,
      },
      {
        label: t('debtBox.results.actions.downloadCDA'),
        onClick: () => downloadCDA(),
        display: displayDownloadCda(code),
        enabled: true,
      },
    ];
  };

  return (
    <div>
      <Grid
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={1}
        className={styles.container}
      >
        <Grid item xs={2} sm={6} md={12}>
          <Title text={ t('inscription.title') } />
          <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          {(!data && isLoading) && (
            <Skeleton height={350} variant='rectangular' />
          )}
          {(data && id) && (
            <div className={styles.content}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10}>
                  <div className={styles.header}>
                    <span className={styles.number}>CDA nº {data.cda_number}</span>
                    <PillInfo
                      id={'debt_state'}
                      label={t('inscription.state', { label: data.state })}
                      title={getStateInfo(data.state, data.pills, true)}
                      text={getStateInfo(data.state, data.pills, false)}
                      textLink={t('inscription.stateHistory')}
                      onClick={setPhaseModalState}
                    />
                  </div>
                  <div className={styles.pills}>
                    {data.pills.map((p: any) => (
                      p.key !== 'state' && (<PillInfo
                        key={p.key}
                        id={p.key}
                        text={p.description}
                        other_description={p.other_description}
                        title={p.title}
                        label={p.label}
                        {...optionalPropsController(p.key, () => protestInfoModal(), data.id)}
                      />
                      )
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className={styles.action}>
                  <DropdownButton
                    title={t('debtBox.results.actions.label')}
                    items={handleActionItems()}
                    enabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {renderTabs(data)}
                </Grid>
                <Show if={data.timeline.length > 0}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Timeline timeline={data.timeline}/>
                  </Grid>
                </Show>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
      {id && (
        <ProtestInfo
          modalState={protestModalState}
          inscriptionsId={parseInt(id, 10)}
          openProtestInfoModal={(e: any) => protestInfoModal(e)}
        />)
      }
    </div>
  );
}
