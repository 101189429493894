import { FaCalendarAlt, FaHistory } from 'react-icons/fa';
import { t } from 'i18next';
import { Modal } from '../Modal';
import styles from './styles.module.scss';
import { PhasesHistory } from '../../@types/activeDebt/inscription';
import Format from '../../helpers/format';
import { Table } from '../Table';

type StatesHistoryProps = {
  data: PhasesHistory[];
  modalState?: boolean;
  openPhasesModal?: () => void;
};

export function StatesHistory(props: StatesHistoryProps) {
  return (
    <Modal
      title={t('inscription.phaseHistoryModal.title')}
      open={props.modalState}
      onClose={props.openPhasesModal}
      icon={<FaHistory/>}
      size='large'
    >
      <Table flat={true}>
        <thead data-cy='data-cy-phases-history-table'>
          <tr>
            <th>{t('inscription.phaseHistoryModal.table.date')}</th>
            <th>{t('inscription.phaseHistoryModal.table.balance')}</th>
            <th>{t('inscription.phaseHistoryModal.table.phase')}</th>
            <th>{t('inscription.phaseHistoryModal.table.individual')}</th>
            <th>{t('inscription.phaseHistoryModal.table.reason')}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item: PhasesHistory) => (
            <tr>
              <td><FaCalendarAlt className={styles.calendarIcon}/>{Format.removeTimeFromDateString(item.created_at)}</td>
              <td>{item.balance ? item.balance : '-'}</td>
              <td>{item.to_state}</td>
              <td>{item.user_name ? item.user_name : '-'}</td>
              <td>{item.reason ? item.reason : '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
}
