import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { t } from 'i18next';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal } from '../../../../../components/Modal';
import styles from './styles.module.scss';
import { Button } from '../../../../../components/Button';
import Select from '../../../../../components/Select';
import { OptionType } from '../../../../../@types/config';
import { TextArea } from '../../../../../components/TextArea';
import Show from '../../../../../components/Show';
import DebtBoxService from '../../../../../services/debtBoxService';

const WARNING_ANIMATION = require('../../../../../assets/lotties/warning.json');

type ModalToCancelProps = {
  open: boolean;
  onClose: () => void;
  id?: number;
  code?: string;
  onSuccess: () => void;
  onFail: () => void;
};

const reasonsToCancel = [
  {
    label: 'Nova negociação',
    value: 1,
  },
  {
    label: 'Transação tributária',
    value: 2,
  },
  {
    label: 'Refis',
    value: 3,
  },
  {
    label: 'Relp',
    value: 4,
  },
  {
    label: 'Outros',
    value: 5,
  },
];

export function ModalToCancel(props: ModalToCancelProps) {
  const [reason, setReason] = useState<OptionType | null>();
  const [reasonText, setReasonText] = useState('');

  const handleConfirm = () => {
    if (props.id) {
      const reasonToSend = reason?.value === 5 ? reasonText : reason?.label;
      DebtBoxService.cancelPaymentRequest(props.id, reasonToSend)
        .then(() => props.onSuccess())
        .catch(() => props.onFail());
    }
  };

  return (
    <Modal
      open={props.open}
      size='small'
      onClose={props.onClose}
    >
      <div className={styles.header}>
        <div className={styles.icon}>
          <Player
            autoplay
            keepLastFrame={true}
            src={WARNING_ANIMATION}
            speed={2}
            className={styles.lottie}
          >
            <Controls visible={false} />
          </Player>
        </div>
        <span className={styles.title}>{t('debtBox.cancelNegotiation.modalTitle')}</span>
        <span className={styles.text}>
          <Trans
            i18nKey='debtBox.cancelNegotiation.text'
            components={{ a: <a /> }}
            values={{
              number: props.code,
              id: props.id,
            }}
          />
        </span>
        <Select
          options={reasonsToCancel}
          label={t('debtBox.cancelNegotiation.reason')}
          onChange={(val) => setReason(val)}
          required
        />
        <Show if={reason?.value === 5}>
          <TextArea
            label={t('debtBox.cancelNegotiation.reasonText')}
            onChange={(val) => setReasonText(val.target.value)}
            required
          />
        </Show>
        <div className={styles.actions}>
          <Button
            title={t('debtBox.cancelNegotiation.close')}
            buttonType='default'
            round
            onClick={props.onClose}
          />
          <Button
            onClick={handleConfirm}
            title={t('debtBox.cancelNegotiation.confirm')}
            buttonType='primary'
            round
            disabled={!reason || (reason.value === 5 && reasonText.trim().length === 0)}
          />
        </div>
      </div>
    </Modal>
  );
}
