import { AnchorHTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.scss';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
}

export function Link(props: LinkProps) {
  return (
    <a
      {...props}
      className={`${props.className} ${styles.container}`}
    >
      {props.children}
    </a>
  );
}
