import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { format } from 'date-fns';
import Screen from '../../../../helpers/screen';
import styles from './styles.module.scss';
import { AttachDocument, OptionType } from '../../../../@types/config';
import { Button } from '../../../../components/Button';
import Show from '../../../../components/Show';
import { DropFiles } from '../../../../components/DropFiles';
import { ListFiles } from '../../../../components/DropFiles/ListFiles';

type AttachFormProps = {
  handleConfirm: () => void;
  onChange: (documents: AttachDocument[]) => void;
  files?: AttachDocument[];
  documentTypes: OptionType[];
};

const ALL_REQUIRED_DOCUMENTS_ID = 28;

export function AttachForm(props: AttachFormProps) {
  const [files, setFiles] = useState<AttachDocument[]>(props.files ?? []);
  const [requiredDocs, setRequiredDocs] = useState('');
  const filesRef = useRef(files);
  filesRef.current = files;

  const isMobile = Screen.isMobile();

  const removeFile = (filesParam: AttachDocument) => {
    const newState = filesRef.current.filter((el) => el.id !== filesParam.id);
    setFiles(newState);
  };

  const handleDrop = async (filesParam: File[]) => {
    const verifiedFiles = filesParam.map(async (file) => {
      const newFile: AttachDocument = {
        id: (Math.random() + 1).toString(36).substring(1),
        file,
        type: ' ',
        created_at: format(new Date(), 'dd/MM/yyyy'),
      };
      return newFile;
    });

    const newFiles = await Promise.all(verifiedFiles);
    setFiles((current) => [...current, ...newFiles]);
  };

  const handleType = (document: AttachDocument, type: string) => {
    const newState = filesRef.current.map((obj) => {
      if (document.id === obj.id) {
        return {
          ...obj, type,
        };
      }
      return obj;
    });
    setFiles(newState);
  };

  const getRequiredDocuments = (selectedFiles: AttachDocument[]) => {
    const requiredDocsTypes = [ALL_REQUIRED_DOCUMENTS_ID];
    const selectedTypes = new Set(selectedFiles.map((file) => file.type));
    const unselectedTypes = props.documentTypes.filter((type) => !selectedTypes.has(type.value));
    const requiredDocuments = unselectedTypes.filter((type) => requiredDocsTypes.includes(type.value)).join(', ');
    setRequiredDocs(requiredDocuments);
  };

  useEffect(() => {
    props.onChange(files);
    getRequiredDocuments(files);
  }, [files]);

  return (
    <div className={ styles.container }>
      <div>
        <div className={styles.header}>
          <span className={styles.title}>{t('debtBox.newNegotiation.modal.documentsTab.attachTitle')}</span>
        </div>
        <Grid
          container
          columns={{ xs: 1, sm: 3, md: 3 }}
          className={styles.content}
        >
          <Grid
            item
            xs={1}
            sm={3}
            md={3}
          >
            <DropFiles onDrop={handleDrop} isMobile={isMobile} />
          </Grid>
          <Show if={files.length > 0}>
            <Grid
              item
              xs={1}
              sm={3}
              md={3}
            >
              <ListFiles
                documents={files}
                removeFile={removeFile}
                handleType={handleType}
                isMobile={isMobile}
                types={props.documentTypes}
              />
            </Grid>
          </Show>
        </Grid>
      </div>
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className={styles.submit}
        >
          <Button
            title={t('debtBox.newNegotiation.modal.documentsTab.attachTitle')}
            textCenter
            round
            buttonType='primary'
            disabled={requiredDocs.length > 0 || files.length === 0}
            onClick={props.handleConfirm}
          />
        </Grid>
      </Grid>
    </div>
  );
}
