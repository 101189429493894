import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useEffect, useState, useContext } from 'react';
import styles from './styles.module.scss';
import { Breadcumb } from '../../components/Breadcumb';
import DebtBoxService from '../../services/debtBoxService';
import { Contributor, ContributorSearch, ReceiptTypeSearch } from '../../@types/activeDebt/debtBox';
import { ContributorsTabs } from './ContributorsTabs';
import { SearchHeader } from './SearchHeader';
import { ReceiptTypesTabs } from './ReceiptTypesTabs';
import { CdasExtract } from './CdasExtract';
import { SearchResults } from './SearchResults';
import { PaymentGroups } from './PaymentGroups';
import { DropdownButton } from '../../components/DropdownButton';
import { HomeContext } from '../../context/homeContext';
import { CustomReport } from '../../components/CustomReport';
import { Title } from '../../components/Title';
import Show from '../../components/Show';
import InscriptionService from '../../services/inscriptionService';
import { downloadBlobXls } from '../../helpers/downloadPDF';

export function DebtBoxSearch() {
  const { setModal } = useContext(HomeContext);
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [contributorsResponse, setContributorsResponse] = useState<ContributorSearch>();
  const [contributorSelected, setContributorSelected] = useState<number | undefined>();
  const [receiptTypes, setReceiptTypes] = useState<ReceiptTypeSearch[]>([]);
  const [receiptTypeSelected, setReceiptTypeSelected] = useState<ReceiptTypeSearch | undefined>();
  const [loadingContributors, setLoadingContributors] = useState<boolean>(false);
  const [loadingReceiptTypes, setLoadingReceiptTypes] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search');
  const { pathname } = useLocation();
  const searchObject = search ? JSON.parse(decodeURIComponent(search)) : null;

  const addQuery = (query: string, field: string) => {
    const result = query.length > 0 ? query.concat(`&${field}`) : query.concat(field);
    return result;
  };

  useEffect(() => {
    if (contributorSelected) {
      setLoadingReceiptTypes(true);
      setReceiptTypes([]);
      setReceiptTypeSelected(undefined);
      const contributor = contributors.find((c) => c.id === contributorSelected);
      if (contributor) {
        DebtBoxService.getReceiptTypesByContributor(contributor.id, contributor.inscriptions_ids)
          .then((res) => setReceiptTypes(res));
      }
    }
  }, [contributorSelected]);

  useEffect(() => {
    let query = '';
    if (searchObject.contributor) {
      query = addQuery(query, `q[contributor_id_eq]=${searchObject.contributor.value}`);
    }
    if (searchObject.processNumber) {
      query = addQuery(query, `q[judicial_process_number_eq]=${searchObject.processNumber}`);
    }
    if (searchObject.inscription && searchObject.inscription.length > 0) {
      const inscriptions = searchObject.inscription.map((i: any) => `q[administrative_inscription_in][]=${i.label}`);
      query = addQuery(query, inscriptions.join('&'));
    }
    if (searchObject.protestProtocol) {
      query = addQuery(query, `q[payment_requests_protest_protocol_eq]=${searchObject.protestProtocol}`);
    }
    if (searchObject.cda) {
      query = addQuery(query, `q[cda_number_eq]=${searchObject.cda}`);
    }
    if (searchObject.installmentNumber) {
      query = addQuery(query, `q[payment_requests_code_eq]=${searchObject.installmentNumber}`);
    }
    if (searchObject.origin) {
      query = addQuery(query, `q[sefin_key_eq]=${searchObject.origin}`);
    }
    if (!searchObject.showExtinctDebits) {
      query = addQuery(query, `q[all_active]=${!searchObject.showExtinctDebits}`);
    }
    setLoadingContributors(true);
    DebtBoxService.getContributors(query).then((res) => {
      setContributorsResponse(res);
      setLoadingContributors(false);
      setContributors(res.contributors);
    });
  }, []);

  const handleResultExtract = (contributorId: number | undefined) => {
    const contributorQuery = searchObject.contributor ? `q[contributor_id_eq]=${searchObject.contributor.value}` : '';
    const processNumberQuery = searchObject.processNumber ? `q[judicial_process_number_eq]=${searchObject.processNumber}` : '';
    const inscriptionsQuery = searchObject.inscription && searchObject.inscription.length > 0
      ? searchObject.inscription.map((i: any) => `q[administrative_inscription_in][]=${i.label}`).join('&')
      : '';
    const protestProtocolQuery = searchObject.protestProtocol ? `q[payment_requests_protest_protocol_eq]=${searchObject.protestProtocol}` : '';
    const cdaQuery = searchObject.cda ? `q[cda_number_eq]=${searchObject.cda}` : '';
    const installmentNumberQuery = searchObject.installmentNumber ? `q[payment_requests_code_eq]=${searchObject.installmentNumber}` : '';
    const originQuery = searchObject.origin ? `q[sefin_key_eq]=${searchObject.origin}` : '';
    const showExtinctDebitsQuery = searchObject.showExtinctDebits === false ? 'q[all_active]=true' : '';

    if (contributorQuery && !processNumberQuery && !inscriptionsQuery && !protestProtocolQuery
      && !cdaQuery && !installmentNumberQuery && !originQuery && !showExtinctDebitsQuery) {
      window.open(`/reports/full_statement/${contributorId}`, '_blank');
    } else {
      window.open(`/reports/custom/${contributorId}?search=${encodeURIComponent(JSON.stringify(searchObject))}`, '_blank');
    }
  };

  const handleFullExtract = (contributorId: number | undefined, detailed?: boolean) => {
    if (contributorId) {
      const url = `/reports/full_statement/${contributorId}${detailed ? `/${detailed}` : ''}`;
      window.open(url, '_blank');
    }
  };

  const openCustomReportModal = (contributorId: number | undefined) => {
    const contributor = contributors.find((c) => c.id === contributorSelected);
    if (contributor) {
      setModal({
        visible: true,
        title: t('debtBox.report.custom.title'),
        handleClose: () => setModal(undefined),
        open: true,
        size: 'medium',
        children: (
          <CustomReport
            contributorId={contributorId || contributors[0]?.id}
            inscriptionsIds={contributor.inscriptions_ids || []}
            closeModal={() => setModal(undefined)}
          />
        ),
      });
    }
  };

  const handleExportInscriptions = async () => {
    const contributor = contributors.find((c) => c.id === contributorSelected);
    if (contributor) {
      const response = await InscriptionService.exportInscriptionsSpreadsheet(contributor.inscriptions_ids || []);
      downloadBlobXls(response, `planilha-cdas-${contributor.document_contributor}.xlsx`);
    }
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        columns={{ xs: 2, sm: 2, md: 12 }}
        spacing={2}
      >
        <Grid item xs={12} className={ styles.title }>
          <Grid item xs={3} sm={6}>
            <Title text={ t('debtBox.title') } />
            <Breadcumb pathnames={pathname.split('/').filter((x) => x)}/>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item xs={12}>
          <SearchHeader search={searchObject} />
          <SearchResults result={contributorsResponse}/>
        </Grid>
        <Grid item xs={12}>
          <ContributorsTabs
            contributors={contributors}
            loading={loadingContributors}
            onChange={(id) => setContributorSelected(id)}
          />
        </Grid>

        <Grid item xs={12}>
          <CdasExtract
            contributor={contributors.find((c) => c.id === contributorSelected)}
            showExtincts={searchObject.showExtinctDebits}
          />
        </Grid>
        <Show if={!!contributorSelected}>
          <Grid item xs={12} className={styles.report}>
            <DropdownButton
              title={ t('debtBox.report.title') }
              enabled
              items={
                [
                  {
                    label: t('debtBox.report.result'),
                    enabled: true,
                    onClick: (e) => {
                      handleResultExtract(contributorSelected);
                      e.stopPropagation();
                    },
                  },
                  {
                    label: t('debtBox.report.full'),
                    enabled: true,
                    onClick: (e) => {
                      handleFullExtract(contributorSelected);
                      e.stopPropagation();
                    },
                  },
                  {
                    label: t('debtBox.report.fullDetailed'),
                    enabled: true,
                    onClick: (e) => {
                      handleFullExtract(contributorSelected, true);
                      e.stopPropagation();
                    },
                  },
                  {
                    label: t('debtBox.report.custom.title'),
                    enabled: true,
                    onClick: (e) => {
                      openCustomReportModal(contributorSelected);
                      e.stopPropagation();
                    },
                  },
                  {
                    label: t('debtBox.report.export'),
                    enabled: true,
                    onClick: (e) => {
                      handleExportInscriptions();
                      e.stopPropagation();
                    },
                  },
                ]}
            />
          </Grid>
        </Show>

        <Grid item xs={12}>
          <ReceiptTypesTabs
            receiptTypes={receiptTypes}
            loading={loadingReceiptTypes}
            contributor={contributorSelected}
            onChange={(receiptType) => setReceiptTypeSelected(receiptType)}
          />
        </Grid>
        <Grid item xs={12}>
          <PaymentGroups
            receiptType={receiptTypeSelected}
            contributor={contributors.find((c) => c.id === contributorSelected)}
          />
        </Grid>
      </div>
    </div>
  );
}
