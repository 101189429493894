import { Trans } from 'react-i18next';
import { t } from 'i18next';
import styles from './styles.module.scss';
import contributorSearch from '../../../assets/contributorField.svg';

export function Instructions() {
  return (
    <div className={ styles.container }>
      <img src={contributorSearch} className={styles.img}/>
      <div className={ styles.info }>
        <h1>
          <Trans
            i18nKey='contributorBox.instructions.title'
            components={{ bold: <strong /> }}
          />
        </h1>
        <p>{t('contributorBox.instructions.line01')}</p>
        <div className={ styles.footer }>
          <p>{t('contributorBox.instructions.line02')}</p>
          <p>
            <Trans
              i18nKey='contributorBox.instructions.link'
              components={{ a: <a /> }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
