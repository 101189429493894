import {
  AttachedDocument, Inscription, InscriptionDocuments, InscriptionProcedures,
  ContributorTotalDebtResponseType,
  DocumentType,
  DissociateObject,
  ProtestInfoType,
  ChangeInscriptionStateDataType,
} from '../@types/activeDebt/inscription';
import Axios from '../config/api';

export default class InscriptionService {
  public static async getInscriptionDetails(id: string) {
    return Axios.get<Inscription>(`api/inscriptions/${id}/details`)
      .then((res) => res.data);
  }

  public static async generateCDA(id: string) {
    return Axios.post(`api/inscriptions/${id}/generate_cda`)
      .then((res) => res.data);
  }

  public static async updateAddress(admInscription: string) {
    return Axios.get(`api/inscriptions/${admInscription}/update_iptu_address`)
      .then((res) => res.data);
  }

  public static async resendSefinSituation(id: number) {
    return Axios.post(`api/inscriptions/${id}/resend_sefin_situation`)
      .then((res) => res.data);
  }

  public static async getInscriptionProcedures(id: number) {
    return Axios.get<InscriptionProcedures[]>(`api/inscriptions/${id}/procedures`)
      .then((res) => res.data);
  }

  public static async getInscriptionsDocuments(id: number) {
    return Axios.get<InscriptionDocuments>(`api/inscriptions/${id}/documents`)
      .then((res) => res.data);
  }

  public static async attachInscriptionDocuments(id: number, attach_documents: AttachedDocument[]) {
    const formData = new FormData();
    attach_documents.map((files: AttachedDocument) => {
      formData.append('attach_documents[][archive]', files.file);
      formData.append('attach_documents[][status]', files.type);
    });
    return Axios.post(`api/inscriptions/${id}/attach_documents`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res);
  }

  public static async getInscriptionsDocumentsType() {
    return Axios.get<DocumentType>('api/inscriptions/attach_documents')
      .then((res) => res.data);
  }

  public static async getPaymentRequestDocumentsType() {
    return Axios.get<DocumentType>('api/payment_requests/attach_documents')
      .then((res) => res.data);
  }

  public static async dissasociateProcedure(params: DissociateObject) {
    return Axios.post('api/inscriptions/dissociate_procedure', params)
      .then((res) => res.data);
  }

  public static async getContributorTotalDebt(data: any) {
    return Axios.post<ContributorTotalDebtResponseType>('api/inscriptions/contributor_debt', data)
      .then((res) => res.data);
  }

  public static async getContributorSummaryData(id: number, query?: string) {
    return Axios.get<any>(`api/inscriptions/resume_contributor_debt/${id}${query || ''}`)
      .then((res) => res.data);
  }

  public static async getSummaryOpenDebts(body: any) {
    return Axios.post<any>('api/inscriptions/resume_by_receipt_actives/', body)
      .then((res) => res.data);
  }

  public static async getSummaryNegotiationDebts(body: any) {
    return Axios.post<any>('api/inscriptions/resume_by_receipt_negotiation/', body)
      .then((res) => res.data);
  }

  public static async getProtestInfo(id: number) {
    return Axios.get<ProtestInfoType>(`/api/protests/${id}/details`)
      .then((res) => res.data);
  }

  public static async getProcedures(number: string) {
    return Axios.get(`api/inscriptions/fiscal_execution_process_number?procedure_number=${number}`)
      .then((res) => res.data);
  }

  public static async associateProcedureToInscription(procedureId: number, inscriptionId: number, procedureNumber: string) {
    const data = { procedure_id: procedureId, inscription_id: inscriptionId, procedure_number: procedureNumber };
    return Axios.put('api/inscriptions/association_procedure', data)
      .then((res) => res.data);
  }

  public static async getUpdateStateData(inscriptionIds: number[]) {
    const data = { inscriptions_ids: inscriptionIds };
    return Axios.post<ChangeInscriptionStateDataType>('api/inscriptions/update_states', data)
      .then((res) => res.data);
  }

  public static async updateInscriptionState(data: any) {
    return Axios.post('api/inscriptions/alter_states', data)
      .then((res) => res.data);
  }

  public static async exportInscriptionsSpreadsheet(inscriptionsIds: number[]) {
    const data = { inscription_ids: inscriptionsIds };
    return Axios.post('api/inscriptions/xlsx_export_cdas', data, { responseType: 'blob' })
      .then((res) => res.data);
  }
}
