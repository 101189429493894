import {
  FaBullhorn,
  FaComment,
  FaQuestion,
  FaFolder,
  FaScroll,
  FaUserCog,
  FaFileSignature,
  FaAddressBook,
  FaGavel,
  FaBalanceScale,
  FaLandmark,
  FaCoins,
  FaCog,
  FaUser,
} from 'react-icons/fa';
import { AGILIS_DIGITAL_PROCESSES } from '../config/constants';

const itemsModulesActiveDebtContributor = [
  {
    text: 'sidebar.modules.active_debt.contributor_box.contributorArea',
    level: 2,
    href: '/contributor_box',
  },
  {
    text: 'sidebar.modules.active_debt.contributor_box.createEditContributor',
    level: 2,
    href: '/active_debt/requiriments/new_contributor',
  },
];

const itemsModulesActiveDebtRequeriment = [
  {
    text: 'sidebar.modules.active_debt.requeriment.inscribe',
    level: 2,
    href: '/active_debt/requiriments/approve',
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.request_analysis',
    level: 2,
    href: '/active_debt/requiriments/request',
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.register',
    level: 2,
    href: '/active_debt/requiriments/new',
  },
];

const itemsModulesActiveDebtInscription = [
  {
    text: 'sidebar.modules.active_debt.inscription.consult_advanced_debit',
    level: 2,
    href: '/active_debt/inscriptions/search_debit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.extract_debit',
    level: 2,
    href: '/active_debt/inscriptions/extract_debit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.statement_responsibility',
    level: 2,
    href: '/active_debt/inscriptions/statement_responsibility',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.extinguish_suspend_credit',
    level: 2,
    href: '/active_debt/inscriptions/extinguish_suspend_credit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.ownership_change',
    level: 2,
    href: '/active_debt/inscriptions/ownership_change',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.suspension_extinction_requests',
    level: 2,
    href: '/active_debt/inscriptions/suspension_extinction_requests',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.migration_registered_debts',
    level: 2,
    href: '/active_debt/inscriptions/migration_registered_debts',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.approve_migration_registered_debts',
    level: 2,
    href: '/active_debt/inscriptions/approve_migration_registered_debts',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.retroactive_calculation',
    level: 2,
    href: '/active_debt/inscriptions/retroactive_calculation',
  },
];

const itemsModulesActiveDebtProtest = [
  {
    text: 'sidebar.modules.active_debt.protest.request_protest',
    level: 2,
    href: '/active_debt/protest/request_protest',
  },
  {
    text: 'sidebar.modules.active_debt.protest.abandonment_cancellation',
    level: 2,
    href: '/active_debt/protest/abandonment_cancellation',
  },
];

const itemsModulesActiveDebtJudgment = [
  {
    text: 'sidebar.modules.active_debt.judgment.request',
    level: 2,
    href: '/active_debt/judgment/request',
  },
  {
    text: 'sidebar.modules.active_debt.judgment.forward',
    level: 2,
    href: '/active_debt/judgment/forward',
  },
];

const itemsModulesActiveDebtManagement = [
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis',
    level: 2,
    href: '/active_debt/management/active_debt_diagnosis',
  },
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis_phase',
    level: 2,
    href: '/active_debt/management/active_debt_diagnosis_phase',
  },
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis_range',
    level: 2,
    href: '/active_debt/management/active_debt_diagnosis_range',
  },
  {
    text: 'sidebar.modules.active_debt.management.geolocation_contributors',
    level: 2,
    href: '/active_debt/management/geolocation_contributors',
  },
  {
    text: 'sidebar.modules.active_debt.management.geolocation_contributors_regions',
    level: 2,
    href: '/active_debt/management/geolocation_contributors_regions',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_municipal_active_debt',
    level: 2,
    href: '/active_debt/management/overview_municipal_active_debt',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_prodat',
    level: 2,
    href: '/active_debt/management/overview_prodat',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_installment_management',
    level: 2,
    href: '/active_debt/management/overview_installment_management',
  },
  {
    text: 'sidebar.modules.active_debt.management.collection_management',
    level: 2,
    href: '/active_debt/management/collection_management',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_judgment',
    level: 2,
    href: '/active_debt/management/overview_judgment',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_protest',
    level: 2,
    href: '/active_debt/management/overview_protest',
  },
  {
    text: 'sidebar.modules.active_debt.management.evolution_debt_stock',
    level: 2,
    href: '/active_debt/management/evolution_debt_stock',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_phases_debt_inscriptions',
    level: 2,
    href: '/active_debt/management/overview_phases_debt_inscriptions',
  },
];

const itemsModulesActiveDebtReports = [
  {
    text: 'sidebar.modules.active_debt.reports.extract_inscriptions',
    level: 2,
    href: '/active_debt/reports/extract_inscriptions',
  },
  {
    text: 'sidebar.modules.active_debt.reports.general_collection',
    level: 2,
    href: '/active_debt/reports/general_collection',
  },
  {
    text: 'sidebar.modules.active_debt.reports.tax_collection',
    level: 2,
    href: '/active_debt/reports/tax_collection',
  },
  {
    text: 'sidebar.modules.active_debt.reports.collection_charges',
    level: 2,
    href: '/active_debt/reports/collection_charges',
  },
  {
    text: 'sidebar.modules.active_debt.reports.management_view_active_debt',
    level: 2,
    href: '/active_debt/reports/management_view_active_debt',
  },
  {
    text: 'sidebar.modules.active_debt.reports.manage_installments_agreements',
    level: 2,
    href: '/active_debt/reports/manage_installments_agreements',
  },
  {
    text: 'sidebar.modules.active_debt.reports.report_requests_API',
    level: 2,
    href: '/active_debt/reports/report_requests_API',
  },
];

const itemsModulesActiveDebtSettings = [
  {
    text: 'sidebar.modules.active_debt.settings.inform_tax',
    level: 2,
    href: '/active_debt/settings/inform_tax',
  },
  {
    text: 'sidebar.modules.active_debt.settings.calculation_standards',
    level: 2,
    href: '/active_debt/settings/calculation_standards',
  },
  {
    text: 'sidebar.modules.active_debt.settings.blocked_inscriptions',
    level: 2,
    href: '/active_debt/settings/blocked_inscriptions',
  },
  {
    text: 'sidebar.modules.active_debt.settings.refis',
    level: 2,
    href: '/active_debt/settings/refis',
  },
  {
    text: 'sidebar.modules.active_debt.settings.scheduling',
    level: 2,
    href: '/active_debt/settings/scheduling',
  },
];

export const itemsModulesActiveDebt = [
  {
    text: 'sidebar.modules.active_debt.debt_box',
    icon: <FaFolder/>,
    href: '/',
    level: 1,
  },
  {
    text: 'sidebar.modules.active_debt.contributor_box.contributor',
    icon: <FaUser/>,
    level: 1,
    subItems: itemsModulesActiveDebtContributor,
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.title',
    icon: <FaFileSignature/>,
    level: 1,
    subItems: itemsModulesActiveDebtRequeriment,
  },
  {
    text: 'sidebar.modules.active_debt.inscription.title',
    icon: <FaAddressBook/>,
    level: 1,
    subItems: itemsModulesActiveDebtInscription,
  },
  {
    text: 'sidebar.modules.active_debt.protest.title',
    icon: <FaGavel/>,
    level: 1,
    subItems: itemsModulesActiveDebtProtest,
  },
  {
    text: 'sidebar.modules.active_debt.judgment.title',
    icon: <FaBalanceScale/>,
    level: 1,
    subItems: itemsModulesActiveDebtJudgment,
  },
  {
    text: 'sidebar.modules.active_debt.management.title',
    icon: <FaLandmark/>,
    level: 1,
    subItems: itemsModulesActiveDebtManagement,
  },
  {
    text: 'sidebar.modules.active_debt.reports.title',
    icon: <FaScroll/>,
    level: 1,
    subItems: itemsModulesActiveDebtReports,
  },
  {
    text: 'sidebar.modules.active_debt.financial.title',
    icon: <FaCoins/>,
    level: 1,
    href: '/active_debt/financial',
  },
  {
    text: 'sidebar.modules.active_debt.settings.title',
    icon: <FaCog/>,
    level: 1,
    subItems: itemsModulesActiveDebtSettings,
  },
];

export const digitalProcessesLogo = [
  {
    text: 'sidebar.modules.active_debt.settings.title',
    href: AGILIS_DIGITAL_PROCESSES,
    logo: true,
  },
];

export const itemsSupport = [
  {
    text: 'sidebar.modules.support.suggestion_box',
    icon: <FaBullhorn/>,
  },
  {
    text: 'sidebar.modules.support.contact_us',
    icon: <FaComment/>,
  },
  {
    text: 'sidebar.modules.support.faq',
    icon: <FaQuestion/>,
  },
];

export const itemsSettings = [
  {
    text: 'sidebar.modules.settings.user_management',
    icon: <FaUserCog/>,
    href: '/settings/user_management',
  },
];
