import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { INFOS, Info } from './infos';
import styles from './styles.module.scss';
import { ToastInfo } from './ToastInfo';
import InscriptionService from '../../services/inscriptionService';
import { HomeContext } from '../../context/homeContext';

type PillInfoProps = {
  id: string;
  label: string;
  title: string;
  text: string;
  other_description?: string;
  textLink?: string;
  updateAddress?: boolean;
  adminInscription?: string
  onClick?: () => void;
};

export function PillInfo(props: PillInfoProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { addToasts } = useContext(HomeContext);
  const pillProperties: Info = INFOS.find((info) => info.id === props.id) || {
    id: 'default',
    color: '#10949E',
    hoverColor: '#01757D',
    textColor: '#FFFFFF',
  };

  const handleOpened = (e: any) => {
    e.stopPropagation();
    if (props.text && props.text) {
      setOpen(!open);
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosed = (e: any) => {
    setOpen(false);
    setAnchorEl(null);
    e.stopPropagation();
  };

  const fetchAddress = async () => {
    if (props.adminInscription) {
      const result = await InscriptionService.updateAddress(props.adminInscription);
      if (result.title) {
        addToasts({
          type: 'success',
          text: t('inscription.data.updateAddress.success'),
          widthFull: true,
        });
      }
      return result;
    }
    return '';
  };

  const {
    data: addressData, refetch,
  } = useQuery({
    queryKey: [`fetchAddress${props.adminInscription}`],
    queryFn: fetchAddress,
    enabled: false,
  });

  const handleActionText = (e: any) => {
    if (props.onClick) {
      props.onClick();
      handleClosed(e);
    } else if (props.updateAddress) {
      refetch();
    }
  };

  return (
    <a
      data-cy='data-cy-phases-pill'
      onClick={(e) => handleOpened(e)}
      className={`${styles.container}`}
      style={{
        backgroundColor: pillProperties.color,
        borderColor: pillProperties.borderColor || pillProperties.color,
      }}
    >
      <span
        className={styles.text}
        style={{ color: pillProperties.textColor, userSelect: 'none' }}
      >
        {props.label}
      </span>
      <ToastInfo
        anchorEl={anchorEl}
        handleClosed={handleClosed}
        id={'info-content'}
      >
        <div className={styles.popup}>
          <div className={styles.popupHeader}>
            <span className={styles.title}>{props.title}</span>
          </div>
          <div className={styles.popupContent}>
            <span
              className={styles.popupBody}
              dangerouslySetInnerHTML={{
                __html: `${addressData ? addressData.title : props.text.replaceAll('\\n', '<br>')}${props.other_description ? `<br><strong>${props.other_description.replaceAll('\\n', '<br>')}</strong>` : ''}`,
              }}
            />
            <a
              className={styles.popupFooter}
              onClick={handleActionText}
            >
              {props.textLink}
            </a>
          </div>
        </div>
      </ToastInfo>
    </a>
  );
}
