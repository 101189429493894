import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@mui/material';
import { Subtitle } from '../../Subtitle';
import { Table } from '../../Table';
import DebtBoxService from '../../../services/debtBoxService';
import Show from '../../Show';

type CalculationDefaultInfoProps = {
  debtId: number;
};

export function CalculationDefaultInfo(props: CalculationDefaultInfoProps) {
  const i18nKey = 'debtBox.results.inscriptions.debts.calculationDefaultInfo';

  const loadData = async () => {
    if (props.debtId) {
      const result = await DebtBoxService.getCalculationPattners(props.debtId);
      return result;
    }
    return null;
  };

  const {
    data, isFetching,
  } = useQuery({
    queryKey: [`calculationPatterns-${props.debtId}`],
    queryFn: loadData,
    enabled: true,
  });

  const renderApplicableDataOn = () => {
    const applicableOn = data?.applicable_on;
    return (
      applicableOn?.map((values) => (`${values !== applicableOn[applicableOn.length - 1] ? `${values}, ` : `${values}`}`))
    );
  };

  return (
    <div>
      <Subtitle text={t(`${i18nKey}.title`)} />
      <Show if={!data && isFetching}>
        <Skeleton variant='rectangular' height={100}/>
      </Show>
      <Show if={(!!data) && !isFetching}>
        <Table>
          <thead>
            <tr>
              <th>{t(`${i18nKey}.table.name`)}</th>
              <th>{t(`${i18nKey}.table.code`)}</th>
              <th>{t(`${i18nKey}.table.correction`)}</th>
              <th>{t(`${i18nKey}.table.fines`)}</th>
              <th>{t(`${i18nKey}.table.moratoriumFine`)}</th>
              <th>{t(`${i18nKey}.table.applicatedTo`)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.name}</td>
              <td>{data?.code}</td>
              <td>{data?.correction}</td>
              <td>{data?.interest}</td>
              <td>{data?.moratorium_fines}</td>
              <td>{renderApplicableDataOn()}</td>
            </tr>
          </tbody>
        </Table>
      </Show>
    </div>
  );
}
