import { FaCheckCircle } from 'react-icons/fa';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import styles from './styles.module.scss';
import { TableData, TableItem } from '../../../../components/TableData';
import { Table } from '../../../../components/Table';
import { PaymentRequest } from '../../../../@types/activeDebt/paymentRequest';
import { Button } from '../../../../components/Button';
import { Link } from '../../../../components/Link';
import Show from '../../../../components/Show';
import Format from '../../../../helpers/format';

type NegotiationProps = {
  negotiation?: PaymentRequest;
  onSubmit: () => void;
};

export function Negotiation(props: NegotiationProps) {
  const [showAllCdas, setShowAllCdas] = useState(false);
  const renderCdas = (cda_list: any) => {
    return (
      <div>
        {
          cda_list.map((cda: any, index: number) => (
            <Show if={showAllCdas || index < 3}>
              <Link
                href={`/inscription/${cda.id}`}
                target='_blank'
              >
                {cda.cda_number}
              </Link>
              {(index < cda_list.length - 1 || showAllCdas) && ', '}
            </Show>
          ))
        }
        <Show if={cda_list.length > 3}>
          <Link
            onClick={() => setShowAllCdas(!showAllCdas)}
          >
            {showAllCdas ? t('debtBox.newNegotiation.negotiation.showLess') : t('debtBox.newNegotiation.negotiation.showMore')}
          </Link>
        </Show>
      </div>
    );
  };

  const renderTableData = () => {
    if (props.negotiation) {
      const items: TableItem[] = [
        {
          label: t('debtBox.newNegotiation.negotiation.cda'),
          value: renderCdas(props.negotiation?.cda_list),
        },
        {
          label: t('debtBox.newNegotiation.negotiation.status'),
          value: props.negotiation.status_i18n,
        },
        {
          label: t('debtBox.newNegotiation.negotiation.requestedAt'),
          value: Format.formatDateWithHours(props.negotiation.requested_at),
        },
        {
          label: t('debtBox.newNegotiation.negotiation.installments'),
          value: props.negotiation.number_installments,
        },
        {
          label: t('debtBox.newNegotiation.negotiation.balance'),
          value: Format.currency(props.negotiation.balance),
        },
      ];
      return (
        <TableData items={items}/>
      );
    }
    return <></>;
  };

  return (
    <div className={ styles.container }>
      <div className={styles.success}>
        <FaCheckCircle />
        <span>{t('debtBox.newNegotiation.negotiation.success')}</span>
      </div>

      <div className={styles.number}>
        <span>{t('debtBox.newNegotiation.negotiation.number')}</span>
        <span className={styles.code}>{props.negotiation?.code}</span>
      </div>

      <Grid
        columns={{ xs: 2, sm: 2, md: 12 }}
        container
      >
        <Grid
          xs={2}
          sm={8}
          item
        >
          <div className={styles.tableData}>{renderTableData()}</div>
        </Grid>

        <Grid
          xs={2}
          sm={12}
          item
        >
          <div className={styles.table}>
            <span className={styles.tableTitle}>{t('debtBox.newNegotiation.negotiation.installments')}</span>
            <Table>
              <thead>
                <th>{t('paymentRequest.unpaidBillets.number')}</th>
                <th>{t('paymentRequest.unpaidBillets.mainValue')}</th>
                <th>{t('paymentRequest.unpaidBillets.fine')}</th>
                <th>{t('paymentRequest.unpaidBillets.monetaryCorrection')}</th>
                <th>{t('paymentRequest.unpaidBillets.interestValue')}</th>
                <th>{t('paymentRequest.unpaidBillets.moratoriumFines')}</th>
                <th>{t('paymentRequest.unpaidBillets.moratoriumFinesInstallment')}</th>
                <th>{t('paymentRequest.unpaidBillets.fee')}</th>
                <th>{t('paymentRequest.unpaidBillets.total')}</th>
                <th>{t('paymentRequest.unpaidBillets.dueDate')}</th>
              </thead>
              <tbody>
                  {
                    props.negotiation?.unpaid_installments.map((billet) => (
                      <tr>
                        <td>{billet.installment_number}</td>
                        <td>{Format.currency(billet.main_value_installment)}</td>
                        <td>{Format.currency(billet.fines_installment)}</td>
                        <td>{Format.currency(billet.total_monetary_correction_installment)}</td>
                        <td>{Format.currency(billet.total_interest_value_installment)}</td>
                        <td>{Format.currency(billet.value_moratorium_fines_installment)}</td>
                        <td>{Format.currency(billet.moratorium_fines_installment)}</td>
                        <td>{Format.currency(billet.fee_value_installment)}</td>
                        <td>{Format.currency(billet.total_value_installment)}</td>
                        <td className={styles.due}>
                          {Format.formatDate(billet.due_date_installment)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
            </Table>
          </div>
        </Grid>
      </Grid>
      <div className={styles.submit}>
        <Button
          title={t('debtBox.newNegotiation.nextStep')}
          round
          buttonType='primary'
          onClick={props.onSubmit}
        />
      </div>
    </div>
  );
}
