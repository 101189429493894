import { ReactNode } from 'react';
import { Menu } from '@mui/material';

type ToastInfoProps = {
  children?: ReactNode;
  anchorEl: any;
  id: string;
  handleClosed: (event: any) => void;
};

export function ToastInfo(props: ToastInfoProps) {
  return (
    <>
      <Menu
        id={props.id}
        open={!!props.anchorEl}
        onClose={(e) => props.handleClosed(e)}
        anchorEl={props.anchorEl}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: 1,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#E8E8E8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        MenuListProps={{ sx: { py: 0 } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        { props.children }
      </Menu>
    </>
  );
}
