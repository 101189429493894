import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import InscriptionService from '../../../services/inscriptionService';
import styles from './styles.module.scss';
import { OptionType } from '../../../@types/config';
import { Button } from '../../../components/Button';
import Show from '../../../components/Show';
import Select from '../../../components/Select';

type AssociateProcedureProps = {
  id: number;
  onSuccess: () => void;
  onFailed: () => void;
};

export function AssociateProcedure(props: AssociateProcedureProps) {
  const [procedure, setProcedure] = useState<OptionType | null>();

  const loadData = async () => {
    if (procedure) {
      const procedureId = procedure.value;
      const procedureNumber = procedure.label;
      InscriptionService.associateProcedureToInscription(procedureId, props.id, procedureNumber)
        .then(() => props.onSuccess())
        .catch(() => props.onFailed());
    }
  };

  const {
    refetch, isFetching, isRefetching,
  } = useQuery({
    queryKey: [`associateProcedure-${props.id}-${procedure?.value}`],
    queryFn: loadData,
    enabled: false,
  });

  const handleAssociateProcedures = () => {
    refetch();
  };

  return (
    <div className={ styles.container }>
      <Select
        options={[]}
        label={t('inscription.linkedProcedures.modal.procedureNumber')}
        onChange={(val) => setProcedure(val)}
        urlToUpdate={InscriptionService.getProcedures}
      />
      <Show if={!!procedure}>
        <div className={styles.submit}>
          <Button
            buttonType='primary'
            title={t('inscription.linkedProcedures.modal.submit')}
            onClick={handleAssociateProcedures}
            disabled={isFetching || isRefetching}
            round
          />
        </div>
      </Show>
    </div>
  );
}
