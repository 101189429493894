import { t } from 'i18next';
import {
  FaRegUser, FaCheck, FaFilter, FaCheckCircle, FaTimesCircle,
} from 'react-icons/fa';
import { Trans } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { FaCircleExclamation } from 'react-icons/fa6';
import { Card } from '../../../../components/Reports/Card';
import { InformationBubble } from '../../../../components/Reports/InformationBubble';
import styles from './styles.module.scss';
import { ContributorBaseData, ReceiptTypeSummary } from '../../../../@types/activeDebt/reports';
import reportStyles from '../../reports.module.scss';

type GeneralInfoProps = {
  data: ContributorBaseData;
  fromModal: boolean;
  filter?: any;
  searchItems?: string[];
};

export function GeneralInfo(props: GeneralInfoProps) {
  document.body.classList.remove('darkModeTheme');

  const getDescription = (data: ReceiptTypeSummary[]) => {
    const descriptions = new Set<string>();

    data.forEach((item) => {
      descriptions.add(item.description);
    });
    return Array.from(descriptions).join(', ');
  };

  const getResumeDetails = () => {
    if (props.fromModal) {
      return (
        <Card
          iconTitle={<FaFilter />}
          largeClass={reportStyles.col12}
          title={t('reports.custom.modal.title')}
          custom
        >
          <div className={styles.cardContent}>
            <div className={styles.receiptPills}>
              <span>{t('reports.custom.modal.message')}</span>
              <span>{t('reports.custom.modal.criteria')}</span>
              <div className={styles.pillsContainer}>
                {props.searchItems?.map((rt: any) => {
                  return (
                    <span className={styles.types}>{rt}</span>
                  );
                })}
              </div>
              <div data-cy="filter-info" className={styles.filterContainer}>
                {props.filter.map((rt: any) => {
                  return (
                    <div className={styles.filterItem} key={rt.key}>
                      {rt.enabled ? (
                        <FaCheckCircle className={styles.enabledIcon} />
                      ) : (
                        <FaTimesCircle className={styles.disabledIcon} />
                      )}
                      <span>
                        {
                          <Trans
                            i18nKey={rt.key}
                            components={{ bold: <strong /> }}
                          />
                        }
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      );
    }
    return (
      <Card
        iconTitle={<FaCheck />}
        largeClass={reportStyles.col12}
        title={t('reports.custom.resume.title')}
        custom
      >
        <div className={styles.cardContent}>
          <div className={styles.receiptPills}>
            <span>{t('reports.custom.resume.message')}</span>
            <span>
              <Trans
                i18nKey={'reports.custom.resume.total'}
                components={{ bold: <b /> }}
                values={{
                  total: props.data.inscriptions_count,
                  description: getDescription(props.data.receipt_types),
                }}
              />
            </span>
            <span className={styles.footer}>{t('reports.custom.resume.observation')}</span>
          </div>
        </div>
      </Card>
    );
  };

  return (
    props.data === undefined ? <Skeleton height={400}/>
      : (
        <div className={styles.container}>
          <InformationBubble
            contributorName={props.data.name}
            totalBalance={props.data.total_balance}
            custom
          />
          <div className={styles.cards}>
            <Card
              iconTitle={<FaRegUser />}
              largeClass={reportStyles.col6}
              title={t('reports.fullStatement.contributorData.title')}
              custom
            >
              <div className={styles.cardContent}>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.name'
                    components={{ b: <b /> }}
                    values={{
                      contributorName: props.data.name,
                    }}
                  />
                </span>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.document'
                    components={{ b: <b /> }}
                    values={{
                      document: props.data.document,
                    }}
                  />
                </span>
                <span className={styles.contributorData}>
                  <Trans
                    i18nKey='reports.fullStatement.contributorData.address'
                    components={{ b: <b /> }}
                    values={{
                      address: props.data.address,
                    }}
                  />
                </span>
              </div>
            </Card>
            <Card
              iconTitle={undefined}
              largeClass={reportStyles.col6}
              title={''}
              info
            >
              <div data-cy={'report-waning'} className={styles.infoContent}>
                <div className={styles.header}>
                  <FaCircleExclamation />
                  <span>{t('reports.custom.alert.title')}</span>
                </div>
                <span>
                  {t('reports.custom.alert.subtitle')}
                </span>
                <span className={styles.subContent}>
                  <Trans
                    i18nKey='reports.custom.alert.message'
                    components={{ b: <b />, i: <i /> }}
                  />
                </span>
              </div>
            </Card>
          </div>
          <div className={styles.mainCard}>{getResumeDetails()}</div>
        </div>
      )
  );
}
