import { t } from 'i18next';
import { Grid } from '@mui/material';
import { Day } from './Day';
import { Timeline as TimelineType } from '../../../@types/activeDebt/inscription';
import styles from './styles.module.scss';
import { Subtitle } from '../../../components/Subtitle';

type TimelineProps = {
  timeline: TimelineType[];
};

export function Timeline(props: TimelineProps) {
  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} sm={12} md={12}>
        <Subtitle text={t('inscription.timeline.title')} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} className={styles.timeline}>
        <div className={styles.content}>
          {
            props.timeline.map((timelineData: TimelineType, index: number) => (
              <Day
                key={timelineData.date}
                day={timelineData}
                last={index === props.timeline.length - 1}
                first={index === 0}
              />
            ))
          }
        </div>
      </Grid>
    </Grid>
  );
}
