import { t } from 'i18next';
import { FaGavel } from 'react-icons/fa';
import { PaymentRequestDetailsResponse } from '../../../@types/activeDebt/debtBox';
import { TableData } from '../../../components/TableData';
import styles from './styles.module.scss';

type DataProps = {
  data: PaymentRequestDetailsResponse;
  refetchData: () => void;
};

export function Data(props: DataProps) {
  const data = [
    {
      label: t('paymentRequest.data.number'),
      value: (
        <span>
          {props.data.code}
          <span className={styles.type}><FaGavel/> {props.data.type}</span>
        </span>
      ),
    },
    {
      label: t('paymentRequest.data.contributor'),
      value: props.data.contributor.name,
    },
    {
      label: t('paymentRequest.data.cdas'),
      value: (
        <>
          <div className={styles.cdaContainer}>
            {props.data.display_inscriptions.map((cda: any, index: number) => (
              <a
                key={index}
                className={`${styles.cda} ${cda.extinct ? styles.extinct : styles.active}`}
                href={`/inscription/${cda.id}`}
                target='_blank'
              >
                {cda.cda_number}
              </a>
            ))}
          </div>
          <div className={styles.tags}>
            <div className={styles.active}>{t('paymentRequest.data.tag.active')}</div>
            <div className={styles.extinct}>{t('paymentRequest.data.tag.extinct')}</div>
          </div>
        </>
      ),
    },
    {
      label: t('paymentRequest.data.date'),
      value: props.data.date,
    },
    {
      label: t('paymentRequest.data.status'),
      value: props.data.status,
    },
    {
      label: t('paymentRequest.data.balance'),
      value: props.data.balance_description,
    },
    {
      label: t('paymentRequest.data.paid'),
      value: props.data.amount_paid_main,
    },
    {
      label: t('paymentRequest.data.installments'),
      value: `${props.data.number_installments_paid} de ${props.data.number_installments}`,
    },
  ];
  return (
    <div className={ styles.container }>
      <TableData items={data} />
    </div>
  );
}
