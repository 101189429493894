import styles from './styles.module.scss';

type TitleProps = {
  text: string;
};

export function Title(props: TitleProps) {
  return (
    <div className={ styles.container }>
      <span className={ styles.title }>
        {props.text}
      </span>
    </div>
  );
}
