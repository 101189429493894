import { t } from 'i18next';
import { useQuery } from '@tanstack/react-query';
import { PaymentRequest } from '../../../../@types/activeDebt/paymentRequest';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';

import icon from './icon.svg';
import { downloadFile } from '../../../../helpers/downloadPDF';
import DebtBoxService from '../../../../services/debtBoxService';

type BilletsProps = {
  id?: number;
  negotiation?: PaymentRequest;
  closeModal: (err?: boolean) => void;
};

export function Billets(props: BilletsProps) {
  const loadData = async () => {
    if (props.negotiation) {
      await DebtBoxService.getBilletByPaymentRequest(props.negotiation.id, [1])
        .then((res) => {
          downloadFile(res, 'boleto-gerado');
          props.closeModal();
        })
        .catch(() => {
          props.closeModal(true);
        });
    }
  };

  const {
    refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`generateBillet-${props.negotiation?.code}`],
    queryFn: loadData,
    enabled: false,
  });

  const handleGenerateBillet = async () => {
    refetch();
  };

  return (
    <div className={ styles.container }>
      <div className={styles.number}>
        <span>{t('debtBox.newNegotiation.negotiation.number')}</span>
        <span className={styles.code}>{props.negotiation?.code}</span>
      </div>
      <span className={styles.text}>{t('debtBox.newNegotiation.instructions.title')}</span>

      <div className={styles.alert}>
        <div className={styles.content}>
          <img src={icon} className={styles.icon}/>
          <div className={styles.instructions}>
            <span className={styles.title}>{t('debtBox.newNegotiation.instructions.instruction1')}</span>
            <span className={styles.instruction}>{t('debtBox.newNegotiation.instructions.instruction2')}</span>
            <span className={styles.instruction}>{t('debtBox.newNegotiation.instructions.instruction3')}</span>
            <span className={styles.instruction}>{t('debtBox.newNegotiation.instructions.instruction4')}</span>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          title={t('debtBox.newNegotiation.generateBillet')}
          textCenter
          round
          buttonType='primary'
          onClick={handleGenerateBillet}
          disabled={isLoading || isRefetching}
        />
      </div>
    </div>
  );
}
