import { Trans } from 'react-i18next';
import { t } from 'i18next';
import styles from './styles.module.scss';
import Format from '../../../helpers/format';

type InformationBubbleProps = {
  statement?: boolean;
  custom?: boolean;
  contributorName: string;
  totalBalance: number;
};

export function InformationBubble(props: InformationBubbleProps) {
  const date = new Date();
  const currentTime = date.toLocaleString('en-CA', { hour12: false }).replace(',', '');

  const getTranslateKey = () => {
    if (props.custom) {
      return 'reports.custom.resumeInfomation';
    }
    if (props.statement) {
      return 'reports.fullStatement.resumeInfomation';
    }
    return 'reports.paymentRequestSimulate.infoDescription';
  };

  return (
    <div className={`${styles.mes} ${props.custom ? styles.custom : ''}`} data-cy="bubble-information">
      <div className={`${styles.triangle} ${props.custom ? styles.custom : ''}`}></div>
      { props.contributorName && props.totalBalance !== undefined && (
        <>
          <p data-cy="contributor-name">
            {t('reports.paymentRequestSimulate.infoMessage')} <b>{props.contributorName},</b>
          </p><br/>
          <p>
            <Trans
              i18nKey={getTranslateKey()}
              components={{ bold: <b />, highlight: <b className={styles.redText} /> }}
              values={{
                balance: Format.currency(props.totalBalance),
                current_date: Format.formatDate(currentTime),
                current_month: Format.formatMonthYear(date),
              }}
            />
          </p>
        </>
      )}
    </div>
  );
}
