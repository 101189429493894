import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Tooltip } from '@mui/material';
import { FaExclamationTriangle } from 'react-icons/fa';
import { CollapseHeader } from '../../../components/CollapseHeader';
import styles from './styles.module.scss';
import DebtBoxService from '../../../services/debtBoxService';
import { Table } from '../../../components/Table';
import { CheckBox } from '../../../components/CheckBox';
import { Link } from '../../../components/Link';
import { GenerateBilletButton } from './GenerateBilletButton';

type UnpaidBilletsProps = {
  id: number;
  count: string;
  onGenerate: () => void;
  reloadId?: number;
};

export function UnpaidBillets(props: UnpaidBilletsProps) {
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState<number[]>([]);

  const loadData = async () => {
    if (props.id) {
      const result = await DebtBoxService.getPaymentRequestUnpaidBillets(props.id);
      return result;
    }
    return null;
  };

  const {
    data, refetch, isLoading, isRefetching,
  } = useQuery({
    queryKey: [`paymentRequestUnpaidBillets-${props.reloadId}`],
    queryFn: loadData,
  });

  useEffect(() => {
    if (open && !data) {
      refetch();
    }
  }, [open]);

  const handleSelect = (installmentNumber: number) => {
    setSelected([installmentNumber]);
    const index = selected.indexOf(installmentNumber);
    if (index === -1) {
      setSelected([...selected, installmentNumber]);
    } else {
      const newInstallments = [...selected];
      newInstallments.splice(index, 1);
      setSelected(newInstallments);
    }
  };

  const checkSelectableInstallment = (installment: any) => {
    if (installment.installment_number === 1) return !!installment.display_generate_dam_of_first_installment;

    return !!installment.display_checkbox;
  };

  const installmentsToSelect = () => {
    if (data) {
      const installments = data.result.filter((i) => checkSelectableInstallment(i));
      return installments.map((i) => i.installment_number);
    }
    return [];
  };

  const handleSelectAll = () => {
    if (data) {
      const allInstallments = installmentsToSelect();
      const allSelected = !allInstallments.some((i) => !selected.includes(i));
      if (allSelected) {
        setSelected([]);
      } else {
        setSelected(allInstallments);
      }
    }
  };

  const handleGenerateBillet = () => {
    props.onGenerate();
    setSelected([]);
  };

  return (
    <div className={ styles.container }>
      <CollapseHeader
        open={open}
        onClick={() => setOpen(!open)}
        title={t('paymentRequest.unpaidBillets.title')}
        count={props.count}
      />
      {open && (
        <>
          {(!data && (isLoading || isRefetching)) && (
            <Skeleton height={80} variant='rectangular' />
          )}
          {data && (
            <div className={styles.table}>
              <div className={styles.header}>
                <div className={styles.selectAll}>
                  <CheckBox
                    value={selected.length > 0}
                    partially={selected.length > 0 && selected.length < installmentsToSelect().length}
                    color='var(--primary4)'
                    onClick={() => handleSelectAll()}
                  />
                  <Link
                    onClick={() => handleSelectAll()}
                  >
                    {
                      selected.length === installmentsToSelect().length && installmentsToSelect().length > 0
                        ? t('paymentRequest.unpaidBillets.unselectAll') : t('paymentRequest.unpaidBillets.selectAll')
                    }
                  </Link>
                </div>
                <GenerateBilletButton
                  id={props.id}
                  installmentNumbers={selected}
                  onGenerate={handleGenerateBillet}
                />
              </div>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('paymentRequest.unpaidBillets.number')}</th>
                    <th>{t('paymentRequest.unpaidBillets.mainValue')}</th>
                    <th>{t('paymentRequest.unpaidBillets.fine')}</th>
                    <th>{t('paymentRequest.unpaidBillets.monetaryCorrection')}</th>
                    <th>{t('paymentRequest.unpaidBillets.interestValue')}</th>
                    <th>{t('paymentRequest.unpaidBillets.moratoriumFines')}</th>
                    <th>{t('paymentRequest.unpaidBillets.moratoriumFinesInstallment')}</th>
                    <th>{t('paymentRequest.unpaidBillets.fee')}</th>
                    <th>{t('paymentRequest.unpaidBillets.discount')}</th>
                    <th>{t('paymentRequest.unpaidBillets.total')}</th>
                    <th>{t('paymentRequest.unpaidBillets.dueDate')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.result.map((billet) => (
                      <tr>
                        <td>
                          <CheckBox
                            value={selected.includes(billet.installment_number)}
                            onClick={() => handleSelect(billet.installment_number)}
                            color='var(--primary4)'
                            disabled={!checkSelectableInstallment(billet)}
                          />
                        </td>
                        <td>{billet.installment_number}</td>
                        <td>{billet.main_value_installment}</td>
                        <td>{billet.fines_installment}</td>
                        <td>{billet.total_monetary_correction_installment}</td>
                        <td>{billet.total_interest_value_installment}</td>
                        <td>{billet.value_moratorium_fines_installment}</td>
                        <td>{billet.moratorium_fines_installment}</td>
                        <td>{billet.fee_value_installment}</td>
                        <td>-</td>
                        <td>{billet.total_value_installment}</td>
                        <td className={styles.due}>
                          {billet.due_date_installment}
                          {billet.overdue_installment_alert && (
                            <Tooltip title={billet.overdue_installment_alert.title}>
                              <div className={styles.overdue}>
                                <FaExclamationTriangle />
                              </div>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
}
