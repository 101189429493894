import { t } from 'i18next';
import { Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { InputText } from '../../../../components/InputText';
import { Subtitle } from '../../../../components/Subtitle';
import Show from '../../../../components/Show';
import styles from './styles.module.scss';

export type ContactDataType = {
  contact_type: string;
  telephone?: string;
  cell_phone?: string;
  email?: string;
  isWhatsapp?: boolean;
};

type ContactDataProps = {
  hideSubtitle?: boolean;
};

export function ContactData(props: ContactDataProps) {
  const { control } = useFormContext();
  return (
    <>
      <Show if={!props.hideSubtitle}>
        <Grid item xs={12}>
          <Subtitle text={t('contributorBox.form.contact.label')} />
          <p className={styles.label}>{t('contributorBox.form.contact.text')}</p>
        </Grid>
      </Show>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name={'cell_phone'}
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-contributor-cell-phone-input-text'}
              label={t('contributorBox.form.contact.type.cellPhone')}
              placeholder={t('contributorBox.form.contact.type.cellPhonePlaceholder')}
              mask={'(99) 99999-9999'}
              required
              labelBold
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name={'email'}
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-contributor-email-input-text'}
              label={t('contributorBox.form.contact.type.email')}
              placeholder={t('contributorBox.form.contact.type.emailPlaceholder')}
              labelBold
              required
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
      >
        <Controller
          control={control}
          name={'telephone'}
          render={({ field }) => (
            <InputText
              {...field}
              dataCy={'cypress-contributor-telephone-input-text'}
              label={t('contributorBox.form.contact.type.telephone')}
              placeholder={t('contributorBox.form.contact.type.telephonePlaceholder')}
              mask={'(99) 99999-9999'}
              labelBold
            />
          )}
        />
      </Grid>
    </>
  );
}
