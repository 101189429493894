import { useQuery } from '@tanstack/react-query';

import { t } from 'i18next';

import Format from '../../../../helpers/format';

import DebtBoxService from '../../../../services/debtBoxService';
import { Simulation } from '../../../../@types/activeDebt/paymentRequest';

type DataProps = {
  paymentRequestSimulationParams: any;
};

export function TableSimulation(props: DataProps) {
  const loadData = async (): Promise<Simulation> => {
    const result = await DebtBoxService.getPaymentRequestSimulation(props.paymentRequestSimulationParams);
    return result;
  };

  const { data } = useQuery<Simulation>({
    queryKey: ['simulationTableKey'],
    queryFn: loadData,
  });

  const displayOthersInstallments = (installment: any, index: number) => {
    if (index > 0) {
      return (
        `+ ${index}x ${Format.currency(installment?.others_installment)}`
      );
    }
    return null;
  };

  return (
    <table cellSpacing='10'>
      <tr>
        <th>{t('reports.paymentRequestSimulate.table.numberKey')}</th>
        <th>{t('reports.paymentRequestSimulate.table.fistInstallmentKey')}</th>
        <th>{t('reports.paymentRequestSimulate.table.othersInstallmentsKey')}</th>
      </tr>
      {data?.installment_simulations?.map((installment, index) => (
        <tr key={index}>
          <td align='left'>{installment?.num_installments}x</td>
          <td>{Format.currency(installment?.first_installment)}</td>
          <td>{displayOthersInstallments(installment, index)}</td>
        </tr>
      ))}
    </table>
  );
}
